import React, { useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Notificationdata } from "../../store/action/UserFetchData";
import { connect } from "react-redux";
import Loader from "../../common/Loader";
import Title from "../../common/Title";
import { useTranslation } from "react-i18next";
import { HiOutlineArrowSmLeft } from "react-icons/hi";
const Notification = ({ dispatch, res }) => {
  const id = useParams();
  const location = useLocation();
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(Notificationdata(id?.id));
  }, [dispatch, id?.id]);

  var data = res?.data;

  return (
    <>
      <Title props={t("notification")} />
      <div className="bm-mdl-header pb-2">
        <Link
          to={`/bmuser/play/challenges/${id.id}`}
          state={{
            game_image: location?.state?.game_image,
            game_name: location?.state?.game_name,
            game_package: location?.state?.package_name,
            game_rules: location?.state?.game_rules,
          }}
          className="text-white"
        >
          <HiOutlineArrowSmLeft className="fa fa-2x fa-long-arrow-left mb-2" />
        </Link>
        <h4 className="m-0 d-inline mx-3 fw-bold">{t("notification")}</h4>
      </div>
      {res?.loading ? (
        <Loader />
      ) : (
        <div className="bm-mdl-center bm-full-height">
          <div className="bm-content-listing">
            {data?.notifications?.length ? (
              data?.notifications?.map((data, index) => {
                return (
                  <div key={index} className="card br-5 hide-over mb-2 ">
                    <div className="card-body text-dark">
                      <div className="row">
                        <div className="col-sm-12">
                          <h6 className="fw-bold">{data?.heading} </h6>
                          <p className="">{data?.content}</p>

                          <p className="text-muted">{data?.date_created}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p className="text-black text-center">{t("no_data")}</p>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  res: state.Notificaton_Reducer,
});

export default connect(mapStateToProps)(Notification);
