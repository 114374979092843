import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import MainSidebar from "../../common/MainSidebar";
import { connect } from "react-redux";
import {
  All_game,
  Announcement_data,
  Dashboard,
  Slider,
} from "../../store/action/UserFetchData";
import AllGames from "./AllGames";
import Loader from "../../common/Loader";
import coin from "../../assets/image/coin.png";
import SliderBanner from "./SliderBanner";
import { useTranslation } from "react-i18next";
import Title from "../../common/Title";
import { Link } from "react-router-dom";
const Play = ({ dispatch, res, DashboardAmount, res_games, res_announce }) => {
  const { t } = useTranslation();
  const [display, setDisplay] = useState(false);
  const member_id = localStorage.getItem("member_id");
  useEffect(() => {
    res.data = {};
    res.loading = true;
    res_games.data = {};
    res_games.loading = true;
    res_announce.data = {};
    res_announce.loading = true;
    dispatch(Dashboard());
    dispatch(All_game());
    dispatch(Slider());
    dispatch(Announcement_data());
  }, [dispatch]);
  var amount =
    DashboardAmount?.data?.member?.wallet_balance +
    DashboardAmount?.data?.member?.join_money;
  var data = res_announce?.data?.announcement;

  return (
    <>
      <Title props={t("all_games")} />
      <div
        className="sidebar-overlay"
        style={{ display: display ? "block" : "none" }}
      ></div>
      <div className="bm-mdl-header">
        <div id="content">
          <span id="sidebarCollapse" style={{ right: "20px" }}>
            <FaBars className="fs-3" onClick={() => setDisplay(true)} />
          </span>
        </div>
        <h4 className="m-0 d-inline fw-bold">{t("all_game")}</h4>
        {member_id && (
          <p className="badge bg-light text-dark float-right f-18 d-inline">
            <span>
              <img src={coin} alt="img-coin" className="coin" />
            </span>
            {amount === "" || amount === null || isNaN(amount)
              ? "0.00"
              : Number(amount).toFixed(2)}
          </p>
        )}
      </div>
      {data?.length && (
        <Link
          to="/bmuser/announcement"
          className="btn-lightpink m-2 br-5 p-1 d-block text-decoration-none"
        >
          <b className="fw-bold">{t("announcement")} :</b>
          {data?.map((data) => {
            return (
              <div className="fw-normal" key={data}>
                {data?.announcement_desc}
              </div>
            );
          })}
        </Link>
      )}
      {!res.loading && !res_games.loading && !res_announce.loading ? (
        <div className="bm-content-listing bm-full-height">
          <SliderBanner res={res} />
          <AllGames res={res_games} />
        </div>
      ) : (
        <Loader />
      )}
      <MainSidebar display={display} setDisplay={setDisplay} />
    </>
  );
};

const mapStateToProps = (state) => ({
  DashboardAmount: state.DashboardReducer,
  res: state.Slider_Reducer,
  res_games: state.AllgameReducer,
  res_announce: state.AnnouncementReducer,
});

export default connect(mapStateToProps)(Play);
