import Account from "../Main/account/Account";
import Profile from "../Main/account/Profile";
import Announcement from "../Main/other/Announcement";
import AppTutorial from "../Main/other/AppTutorial";
import LeaderBoard from "../Main/other/LeaderBoard";
import Referrals from "../Main/other/Referrals";
import Friends from "../Main/play/Friends";
import LudoLeaderBoard from "../Main/play/LudoLeaderBoard";
import Challenges from "../Main/play/match_component/Challenges";
import Notification from "../Main/play/Notification";
import ChallengeDetails from "../Main/play_details/ChallengeDetails";
import Chat from "../Main/play_details/chat/Chat";
import AddWallet from "../Main/wallet/AddWallet";
import Wallet from "../Main/wallet/Wallet";
import WithdrawWallet from "../Main/wallet/WithdrawWallet";


const homeRoute = [
    { path: "/bmuser/play/challenges/:id", component: Challenges },
    { path: "/bmuser/play/notificationList/:id", component: Notification },
    { path: "/bmuser/play/budyList/:id", component: Friends },
    { path: "/bmuser/play/challengeLeaderboard/:id", component: LudoLeaderBoard },
    { path: "/bmuser/play/challenge_detail/:id", component: ChallengeDetails },
    { path: "/bmuser/play/challengeChat/:id", component: Chat },
    { path: "/bmuser/account", component: Account },
    { path: "/bmuser/profile", component: Profile },
    { path: "/bmuser/wallet", component: Wallet },
    { path: "/bmuser/wallet/addMoney", component: AddWallet },
    { path: "/bmuser/wallet/withdraw", component: WithdrawWallet },
    { path: "/bmuser/referrals", component: Referrals },
    { path: "/bmuser/announcement", component: Announcement },
    { path: "/bmuser/leaderbord", component: LeaderBoard },
    { path: "/bmuser/apptutorial", component: AppTutorial },
]
export default homeRoute;