import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";
import MainSidebar from "../../common/MainSidebar";
import { connect } from "react-redux";
import {  Referral_data } from "../../store/action/UserFetchData";
import Loader from "../../common/Loader";
import ReferralCode from "./ReferralCode";
import { useTranslation } from "react-i18next";
import Title from "../../common/Title";
import { HiOutlineArrowSmLeft } from "react-icons/hi";
const Referrals = ({ dispatch, res }) => {
  const [display, setDisplay] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(Referral_data());
  }, [dispatch]);
  var data = res?.data;
  
  return (
    <>
    <Title props={t("my_referrals")}/>
      <div
        className="sidebar-overlay"
        style={{ display: display ? "block" : "none" }}
      ></div>
      <div className="bm-mdl-header">
        <div id="content">
          <span id="sidebarCollapse" style={{ right: "20px" }}>
            <FaBars className="fs-3" onClick={() => setDisplay(true)} />
          </span>
        </div>
        <Link to="/bmuser/account" className="text-white">
            <HiOutlineArrowSmLeft className="fa fa-2x fa-long-arrow-left mb-2"/>
        </Link>
        <h4 className="m-0 d-inline mx-3 fw-bold">{t("my_referrals")}</h4>
      </div>
      {res?.loading ? (
        <Loader />
      ) : (
        <div className="bm-mdl-center bm-full-height pb-6">
          <div className="bm-content-listing">
            <div className="sumery bg-white rounded-bottam  shadow-sm ">
              <span
                className="btn-green rounded-top p-10 text-center f-18 text-uppercase"
                style={{
                  cursor: "auto",
                  display: "block",
                  width: "inherit",
                }}
              >
                {t("my_referral_summary")}
              </span>
              <div className="container row  text-black p-3  text-center">
                <div className="col-6">
                  {t("referrals")} <br />
                  &nbsp;{`${data?.tot_referrals?.total_ref}`}
                </div>
                <div className="col-6">
                  {t("earnings")} <br />
                  {`${
                    data?.tot_earnings?.tot_earnings == null
                      ? "0.00"
                      : data?.tot_earnings?.tot_earnings
                  }`}
                </div>
              </div>
            </div>
            <ReferralCode />
            <div className="sumery bg-white rounded-bottam  shadow-sm mt-3">
              <span
                className="btn-green rounded-top p-10 text-center f-18  text-uppercase"
                style={{
                  cursor: "auto",
                  display: "block",
                  width: "inherit",
                }}
              >
                {t("my_referral_list")}
              </span>
              <table className="table table-responsive text-center">
                <thead>
                  <tr>
                    <th scope="col" className="thead-dark">
                      {t("date")}
                    </th>
                    <th scope="col" className="thead-dark">
                      {t("player_name")}
                    </th>
                    <th scope="col" className="thead-dark">
                      {t("status")}
                    </th>
                  </tr>
                </thead>
                <tbody className="text-black">
                  {data?.my_referrals?.length ? (
                    data?.my_referrals?.map((data) => {
                      return (
                        <tr className="text-center">
                          <td>{data?.date}</td>
                          <td>{data?.user_name}</td>
                          <td>{data?.status}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="text-center">
                      <td colSpan="3" style={{ padding: "0.75rem" }}>
                        {t("no_referral_found")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      <MainSidebar display={display} setDisplay={setDisplay} />
    </>
  );
};

const mapStateToProps = (state) => ({
  res: state.ReferralReducer,
});

export default connect(mapStateToProps)(Referrals);