import React, { useEffect, useState } from "react";
import { HiOutlineArrowSmLeft } from "react-icons/hi";

import { Link, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ResetPasswordModel from "../auth/model/ResetPasswordModel";
import GoogleLogin from "react-google-login";
import { gapi } from "gapi-script";
import SetMobileNumberModel from "../auth/model/SetMobileNumberModel";
import {
  LoginData,
  Registergoogle,
  VersionEAndroid,
} from "../../store/action/UserFetchData";
import { connect } from "react-redux";
import Title from "../../common/Title";
import { useTranslation } from "react-i18next";

const Login = ({ dispatch, res, login_data, res_register_data }) => {
  const { t } = useTranslation();
  const clientId = process.env.REACT_APP_CLIENT_ID;
  const token = localStorage.getItem("token");
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [reset, setReset] = useState(false);
  const [getModel, setGetModel] = useState(false);
  const [getData, setGetData] = useState({});
  const [disabledButton, setDisabledButton] = useState(false);
  const [login, setLogin] = useState({
    password: location?.state?.password,
    player_id: token,
    submit: "login",
    user_name: location?.state?.user_name,
  });

  const [state, setState] = useState({ user_name: false, password: false });

  useEffect(() => {
    dispatch(VersionEAndroid());
  }, [dispatch]);
  var response = res?.data;

  let name, value;
  const handlechange = (e) => {
    name = e.target.name;
    value = e.target.value;
    if (name === "user_name") {
      setState({ ...state, user_name: false });
      setLogin({ ...login, user_name: value });
    } else if (name === "password") {
      setState({ ...state, password: false });
      setLogin({ ...login, password: value });
    } else {
      setLogin({ ...login, [name]: value });
    }
  };

  useEffect(() => {
    const start = () => {
      gapi.client.init({
        clientId: clientId,
        scope: "",
      });
    };
    gapi.load("client:auth2", start);
  });

  const postData = async () => {
    if (login?.user_name === "" && login?.password === "") {
      setState({ user_name: true, password: true });
    } else if (login?.user_name === "" && login?.password !== "") {
      setState({ ...state, user_name: true });
    } else if (login?.user_name !== "" && login?.password === "") {
      setState({ ...state, password: true });
    } else {
      const submitData = {
        password: login?.password,
        submit: login?.submit,
        user_name: login?.user_name,
        player_id: token,
      };
   
      dispatch(LoginData(submitData, setLoading));
    }
  };
  useEffect(() => {
    if (login_data?.data?.status === true) {
      const notify = () =>
        toast.success(login_data?.data?.title, { autoClose: 1500 });
      notify();
      setDisabledButton(true);
      localStorage.setItem("member_id", login_data?.data?.message?.member_id);
      localStorage.setItem("api_token", login_data?.data?.message?.api_token);
      localStorage.setItem("user_name", login_data?.data?.message?.user_name);
      setTimeout(() => {
        login_data.data = {};
        login_data.loading = true;
        window.location.reload();
      }, 1500);
    } else if (login_data?.data?.status === false) {
      const notify = () =>
        toast.error(login_data?.data?.message, { autoClose: 1500 });
      notify();
      setTimeout(() => {
        login_data.data = {};
        login_data.loading = true;
      }, [1500]);
    }
  }, [login_data]);
  const onKeyPressHandler = (e) => {
    if (e.keyCode === 13) {
      postData(e);
    } else {
    }
  };

  const onSuccess = async (response) => {
    const resData = {
      email_id: response?.profileObj?.email,
      player_id: token,
      g_id: response?.tokenId,
      submit: "google_login",
      user_name: response?.profileObj?.name.replace(/\s+/g, ""),
      mobile_no: "",
      last_name: response?.profileObj?.familyName,
      first_name: response?.profileObj?.givenName,
    };

    dispatch(Registergoogle(resData));
  };
  useEffect(() => {
    if (res_register_data?.data?.status === true) {
      if (res_register_data?.data?.message?.mobile_no !== "") {
        localStorage.setItem(
          "member_id",
          res_register_data?.data?.message?.member_id
        );
        localStorage.setItem(
          "api_token",
          res_register_data?.data?.message?.api_token
        );
        localStorage.setItem(
          "user_name",
          res_register_data?.data?.message?.user_name
        );
        setTimeout(() => {
          res_register_data.data = {};
          res_register_data.loading = true;
          window.location.reload();
        }, 1500);
      } else {
        setGetModel(true);
        setGetData(res_register_data?.data);
      }
    }
  }, [res_register_data]);
  const onFailure = (res) => {};

  return (
    <>
      <Title props={t("login")} />
      <ToastContainer />
      <div className="bm-mdl-header">
        <Link to="/" className="text-white">
          <HiOutlineArrowSmLeft className="fs-3 me-2" />
        </Link>
        <h4 className="m-0 d-inline align-bottom fw-bold">{t("login")}</h4>
      </div>
      <div
        className="bm-content-listing text-black"
        onKeyDown={onKeyPressHandler}
      >
        <div className="card">
          <div className="card-body">
            <form>
              <div className="form-group">
                <label htmlFor="user_name" className="form-label">
                  {t("user_name")} <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  name="user_name"
                  value={login?.user_name ? login?.user_name : ""}
                  onChange={handlechange}
                />
                <div
                  className="text-danger"
                  style={{
                    marginTop: "5px",
                    display: state?.user_name === true ? "block" : "none",
                  }}
                >
                  {t("translation1:err_user_name")}
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1" className="form-label">
                  {t("password")} <span className="text-danger">*</span>
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="exampleInputPassword1"
                  name="password"
                  value={login?.password ? login?.password : ""}
                  onChange={handlechange}
                />
                <div
                  className="text-danger"
                  style={{
                    marginTop: "5px",
                    display: state?.password === true ? "block" : "none",
                  }}
                >
                  {t("translation1:err_password")}
                </div>
              </div>
              <div className="form-group mb-3">
                {t("forgot_password_?")}{" "}
                <span
                  id="forgot-modal"
                  className="text-primary "
                  style={{ cursor: "pointer" }}
                  onClick={() => setReset(true)}
                >
                  {t("resetnow")}
                </span>
              </div>
              {loading ? (
                <input
                  type="button"
                  className="btn btn-submit btn-block btn-lg btn-lightpink mb-2 w-100"
                  name="login"
                  disabled
                  value={t("loading")}
                />
              ) : (
                <input
                  type="button"
                  className="btn btn-submit btn-block btn-lg btn-lightpink mb-2 w-100"
                  name="login"
                  disabled={disabledButton}
                  onClick={postData}
                  value="Submit"
                />
              )}
              <div className="form-group text-center mb-3">
                Don't have an Account ?{" "}
                <Link
                  to="/register"
                  className="text-primary text-decoration-none"
                >
                  Sign Up
                </Link>
              </div>
              <GoogleLogin
                clientId={clientId}
                onSuccess={onSuccess}
                onFailure={onFailure}
                buttonText="Login with google"
                cookiePolicy={"single_host_origin"}
                isSignedIn={false}
                className="google-login"
              />
            </form>
          </div>
        </div>
        <ResetPasswordModel setReset={setReset} reset={reset} />
        <SetMobileNumberModel
          setGetModel={setGetModel}
          getModel={getModel}
          getData={getData}
          setVerifyData={response}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  res: state.AndroidVersion_Reducer,
  res_register_data: state.register_google_Reducer,
  login_data: state.LoginDataReducer,
});

export default connect(mapStateToProps)(Login);
