import React from "react";
import Title from "../../common/Title";
import { useTranslation } from "react-i18next";

const Matrix = ({ data }) => {
  const { t } = useTranslation();

  return (
    <>
      <Title props={t("dashboard")} />
      <div className="bm-content-listing border border-rounded rounded-2 shadow-sm  rounded">
        <h5
          className="alert text-light text-center bm-user-info rounded-top fw-bold mb-0"
          style={{ padding: "13px 8px" }}
        >
          {t("matrix")}
        </h5>
        <form className="container profile-form p-10">
          <div className="card-body">
            <div className="g-0 gx-2 mb-2 row">
              <div className="col">
                <div className="d-flex flex-column align-items-stretch justify-content-start h-100 w-100 card">
                  <div className="text-capitalize text-start px-2 card-header alert alert-secondary py-2 mb-0">
                    <div className="hstack gap-1 fw-bold d-flex ">
                      <span> {t("game_played")}</span>
                    </div>
                  </div>
                  <div className="fs-6 text-start py-1 px-2 card-body">
                    {data?.total_challenge?.total_challenge}
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="d-flex flex-column align-items-stretch justify-content-start h-100 w-100 card">
                  <div className="text-capitalize text-start px-2 card-header alert alert-secondary py-2 mb-0">
                    <div className="hstack gap-1 fw-bold">
                      <span>{t("win_money")}</span>
                    </div>
                  </div>
                  <div className="fs-6 text-start py-1 px-2 card-body">
                    {data?.tot_win_money?.tot_win_money === null
                      ? 0
                      : data?.tot_win_money?.tot_win_money}
                  </div>
                </div>
              </div>
            </div>
            <div className="g-0 gx-2 row">
              <div className="col">
                <div className="d-flex flex-column align-items-stretch justify-content-start h-100 w-100 card">
                  <div className="text-capitalize text-start px-2 card-header alert alert-secondary py-2 mb-0">
                    <div className="hstack gap-1 fw-bold">
                      <span>{t("referral_earning")}</span>
                    </div>
                  </div>
                  <div className="fs-6 text-start py-1 px-2 card-body">
                    {data?.tot_referral_earnings?.total_earning === null
                      ? "0"
                      : data?.tot_referral_earnings?.total_earning}
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="d-flex flex-column align-items-stretch justify-content-start h-100 w-100 card">
                  <div className="text-capitalize text-start px-2 card-header alert alert-secondary py-2 mb-0">
                    <div className="hstack gap-1 fw-bold">
                      <span>{t("penalty")}</span>
                    </div>
                  </div>
                  <div className="fs-6 text-start py-1 px-2 card-body">
                    {data?.tot_panelty_charge?.tot_panelty_charge === null
                      ? 0
                      : data?.tot_panelty_charge?.tot_panelty_charge}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Matrix;
