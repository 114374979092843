import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";
import MainSidebar from "../../common/MainSidebar";
import { connect } from "react-redux";
import {MyLeaderBoardData } from "../../store/action/UserFetchData";
import Loader from "../../common/Loader";
import Title from "../../common/Title";
import { useTranslation } from "react-i18next";
import { HiOutlineArrowSmLeft } from "react-icons/hi";
const LeaderBoard = ({ dispatch, res }) => {
  const { t } = useTranslation();
  const [display, setDisplay] = useState(false);
  useEffect(() => {
    dispatch(MyLeaderBoardData());
  }, [dispatch]);

  var data = res?.data?.leader_board;
  
  return (
    <>
    <Title props={t("leader_board")}/>
      <div
        className="sidebar-overlay"
        style={{ display: display ? "block" : "none" }}
      ></div>
      <div className="bm-mdl-header">
        <div id="content">
          <span id="sidebarCollapse" style={{ right: "20px" }}>
            <FaBars className="fs-3" onClick={() => setDisplay(true)} />
          </span>
        </div>
        <Link to="/bmuser/account" className="text-white">
            <HiOutlineArrowSmLeft className="fa fa-2x fa-long-arrow-left mb-2"/>
        </Link>
        <h4 className="m-0 d-inline mx-3 fw-bold">{t("leader_board")}</h4>
      </div>
      {res?.loading ? (
        <Loader />
      ) : (
      <div className="bm-mdl-center bm-full-height pb-6">
        <div className="static-table">
          <table className="table table-responsive bg-white text-center">
            <thead className="btn-green">
              <tr>
                <th
                  style={{
                    width: "50%",
                    backgroundColor: "#425179",
                    color: "white",
                    padding: "0.75rem",
                    borderTop: " 1px solid #dee2e6",
                    borderRight: " 1px solid #dee2e6",
                  }}
                >
                  {t("user_name")}
                </th>
                <th
                  style={{
                    width: "50%",
                    backgroundColor: "#425179",
                    color: "white",
                    padding: "0.75rem",
                    borderTop: " 1px solid #dee2e6",
                  }}
                >
                  {t("total_refferral")}
                </th>
              </tr>
            </thead>
            <tbody className="text-dark">
              {data?.map((data) => (
                <tr key={data?.referral_id}>
                  <td style={{ width: "2%", padding: "0.75rem" }}>
                    {data?.user_name} 
                  </td>
                  <td style={{ width: "50%", padding: "0.75rem" }}>
                    {data?.tot_referral}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      )}
      <MainSidebar display={display} setDisplay={setDisplay} />
    </>
  );
};

const mapStateToProps = (state) => ({
  res: state.MyLeaderBoardReducer,
});

export default connect(mapStateToProps)(LeaderBoard);