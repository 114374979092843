import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";
import MainSidebar from "../../common/MainSidebar";
import { Dashboard, Transaction_data } from "../../store/action/UserFetchData";
import { connect } from "react-redux";
import Loader from "../../common/Loader";
import Title from "../../common/Title";
import { useTranslation } from "react-i18next";
import { HiOutlineArrowSmLeft } from "react-icons/hi";
import coin from "../../assets/image/coin.png";
const Wallet = ({ dispatch, res, res_transaction }) => {
  const [display, setDisplay] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(Transaction_data());
    dispatch(Dashboard());
  }, [dispatch]);
  var data = res.data;
  const transaction = res_transaction?.data?.transaction;

  return (
    <>
      <Title props={t("my_wallet")} />
      <div
        className="sidebar-overlay"
        style={{ display: display ? "block" : "none" }}
      ></div>
      <div className="bm-mdl-header">
        <div id="content">
          <span id="sidebarCollapse" style={{ right: "20px" }}>
            <FaBars className="fs-3" onClick={() => setDisplay(true)} />
          </span>
        </div>
        <Link to="/bmuser/account" className="text-white">
          <HiOutlineArrowSmLeft className="fa fa-2x fa-long-arrow-left mb-2" />
        </Link>
        <h4 className="m-0 d-inline mx-3 fw-bold">{t("my_wallet")}</h4>
      </div>
      {res?.loading || res_transaction?.loading ? (
        <Loader />
      ) : (
        <div className="bm-mdl-center bm-full-height pb-6">
          <div className="bm-content-listing">
            <span className="d-block btn-green text-center rounded-top p-1 text-uppercase border-bottom fw-bold">
              {t("total_balance")}
            </span>
            <div className="container wallet">
              <div className="row btn-lightgreen rounded-bottam p-3 text-light">
                <div className="col-7">
                  <h6>
                    <span>
                      <img src={coin} alt="img-coin" className="coin" />
                      &nbsp;
                      {Number(
                        parseInt(data?.member?.join_money) +
                          parseInt(data?.member?.wallet_balance)
                      ).toFixed(2)}
                    </span>
                  </h6>
                  <p>
                    <span className="fw-bold">{t("win_money")}</span> :&nbsp;
                    <span>
                      <img src={coin} alt="img-coin" className="coin" />
                      {Number(data?.member?.wallet_balance).toFixed(2)}
                    </span>
                  </p>
                  <p className="mt-1">
                    <span className="fw-bold">{t("join_money")}</span> :&nbsp;
                    <span>
                      <img src={coin} alt="img-coin" className="coin" />
                      {Number(data.member.join_money).toFixed(2)}
                    </span>
                  </p>
                </div>
                <div className="col-5 text-end">
                  <Link
                    to="/bmuser/wallet/addMoney"
                    className="btn btn-md f-14 btn-lightpink text-uppercase mb-1"
                  >
                    {t("add")}
                  </Link>
                  <Link
                    to="/bmuser/wallet/withdraw"
                    className="btn btn-md f-14 btn-lightpink text-uppercase"
                  >
                    {t("withdraw")}
                  </Link>
                </div>
              </div>
              <div className="row mt-2 text-center">
                <div className="col-12 p-0">
                  <div className="w-100 btn-green text-center  p-1 text-uppercase fw-bold border-bottom">
                    {t("payout")}{" "}
                  </div>
                  <div className="btn-lightgreen w-100 rounded-bottom p-3 text-light">
                    <img src={coin} alt="img-coin" className="coin" />
                    {Number(data?.tot_withdraw?.tot_withdraw).toFixed(2)}
                  </div>
                </div>
              </div>
            </div>
            <h6 className="text-black text-center f-20 my-2 fw-bold text-uppercase bm_text_lightgreen">
              {t("wallet_history")}
            </h6>
            {transaction.map((data, index) => (
              <div key={data?.transaction_id}>
                {data?.withdraw > 0 ? (
                  <div
                    className="row my-2 mx-1 rounded bg-lightgray box-shadow p-2 d-flex"
                    key={index}
                  >
                    <div className="col-2 m-auto">
                      <span className="text-danger text-uppercase">
                        {t("debit")}
                      </span>
                    </div>
                    <div className="col-6 text-dark text-center">
                      <p>
                        {data?.note} - #{data?.transaction_id}{" "}
                      </p>
                      <p className="mt-1">{data?.date}</p>
                      {(data?.note_id === "9" ||
                        data?.note_id === "19" ||
                        data?.note_id === "21") && (
                        <p className="mt-1 text-muted">Pending</p>
                      )}
                    </div>
                    <div className="col-4 text-end">
                      <span className="text-danger">
                        {"-"}
                        <span>
                          <img src={coin} alt="img-coin" className="coin" />
                          {data?.withdraw}
                        </span>
                      </span>
                      <p className="text-primary my-1">
                        {" "}
                        <span>
                          {" "}
                          <img src={coin} alt="img-coin" className="coin" />
                          {Number(data?.join_money).toFixed(2)}
                        </span>
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="row my-2 mx-1 rounded bg-lightgray box-shadow p-2 d-flex">
                    <div className="col-2 m-auto">
                      <span className="bm_text_lightgreen text-uppercase">
                        {t("credit")}
                      </span>
                    </div>
                    <div className="col-6 text-dark text-center">
                      <p>
                        {data?.note} - #{data?.transaction_id}&nbsp;
                      </p>

                      <p className="mt-1">{data?.date}</p>
                    </div>
                    <div className="col-4 text-end">
                      <span className="bm_text_lightgreen">
                        {"+"}
                        <img src={coin} alt="img-coin" className="coin" />{" "}
                        {data?.deposit}
                      </span>
                      <p className="text-primary my-1">
                        &nbsp;
                        <img src={coin} alt="img-coin" className="coin" />
                        {Number(data?.join_money).toFixed(2)}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
      <MainSidebar display={display} setDisplay={setDisplay} />
    </>
  );
};

const mapStateToProps = (state) => ({
  res: state.DashboardReducer,
  res_transaction: state.TransactionReducer,
});

export default connect(mapStateToProps)(Wallet);
