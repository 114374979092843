import { toast } from "react-toastify";
export function RegisterationAccount(data, setLoading) {
  setLoading(true);
  return async (dispatch) => {
    try {
      var response = await fetch("/api/registrationAcc", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      var res_data = await response.json();
      setLoading(false);
      var return_response = {
        type: "REGISTER_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      setLoading(false);
      if (
        error.message.includes("HTTP error! Status: 404") ||
        error.message.includes("HTTP error! Status: 400")
      ) {
        try {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message;
          toast.error(errorMessage, { autoClose: 1500 });
        } catch (jsonError) {
          console.error("Error parsing JSON from API response:", jsonError);
          toast.error("Something went wrong! Please try again later.", {
            autoClose: 1500,
          });
        }
      } else if (error.message.includes("HTTP error! Status: 401")) {
        localStorage.removeItem("member_id");
        localStorage.removeItem("api_token");
        localStorage.removeItem("user_name");
        window.location.reload();
        toast.error("Unauthorized access! Please log in again.", {
          autoClose: 1500,
        });
      } else {
        
      }
    }
  };
}
export function LoginData(data, setLoading) {
  setLoading(true);

  return async (dispatch) => {
    try {
      var response = await fetch("/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      var res_data = await response.json();
      setLoading(false);
      var return_response = {
        type: "LOGIN_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      setLoading(false);
      if (
        error.message.includes("HTTP error! Status: 404") ||
        error.message.includes("HTTP error! Status: 400")
      ) {
        try {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message;
          toast.error(errorMessage, { autoClose: 1500 });
        } catch (jsonError) {
          console.error("Error parsing JSON from API response:", jsonError);
          toast.error("Something went wrong! Please try again later.", {
            autoClose: 1500,
          });
        }
      } else if (error.message.includes("HTTP error! Status: 401")) {
        localStorage.removeItem("member_id");
        localStorage.removeItem("api_token");
        localStorage.removeItem("user_name");
        window.location.reload();
        toast.error("Unauthorized access! Please log in again.", {
          autoClose: 1500,
        });
      } else {
        
      }
    }
  };
}
export function VerifyOtpData(data) {
  return async (dispatch) => {
    try {
      var response = await fetch("/api/verifyOTP", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      var res_data = await response.json();

      var return_response = {
        type: "VERIFY_OTP_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      if (
        error.message.includes("HTTP error! Status: 404") ||
        error.message.includes("HTTP error! Status: 400")
      ) {
        try {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message;
          toast.error(errorMessage, { autoClose: 1500 });
        } catch (jsonError) {
          console.error("Error parsing JSON from API response:", jsonError);
          toast.error("Something went wrong! Please try again later.", {
            autoClose: 1500,
          });
        }
      } else if (error.message.includes("HTTP error! Status: 401")) {
        localStorage.removeItem("member_id");
        localStorage.removeItem("api_token");
        localStorage.removeItem("user_name");
        window.location.reload();
        toast.error("Unauthorized access! Please log in again.", {
          autoClose: 1500,
        });
      } else {
        
      }
    }
  };
}
export function ForgotPasswordData(data) {
  return async (dispatch) => {
    try {
      var response = await fetch("/api/forgotpassword", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      var res_data = await response.json();

      var return_response = {
        type: "FORGOT_PASSWORD_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      if (
        error.message.includes("HTTP error! Status: 404") ||
        error.message.includes("HTTP error! Status: 400")
      ) {
        try {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message;
          toast.error(errorMessage, { autoClose: 1500 });
        } catch (jsonError) {
          console.error("Error parsing JSON from API response:", jsonError);
          toast.error("Something went wrong! Please try again later.", {
            autoClose: 1500,
          });
        }
      } else if (error.message.includes("HTTP error! Status: 401")) {
        localStorage.removeItem("member_id");
        localStorage.removeItem("api_token");
        localStorage.removeItem("user_name");
        window.location.reload();
        toast.error("Unauthorized access! Please log in again.", {
          autoClose: 1500,
        });
      } else {
        
      }
    }
  };
}
export function UpdateMobileNumberData(data) {
  return async (dispatch) => {
    try {
      var response = await fetch("/api/update_mobile_no", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      var res_data = await response.json();

      var return_response = {
        type: "UPDATE_MOBILE_NUMBER_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      if (
        error.message.includes("HTTP error! Status: 404") ||
        error.message.includes("HTTP error! Status: 400")
      ) {
        try {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message;
          toast.error(errorMessage, { autoClose: 1500 });
        } catch (jsonError) {
          console.error("Error parsing JSON from API response:", jsonError);
          toast.error("Something went wrong! Please try again later.", {
            autoClose: 1500,
          });
        }
      } else if (error.message.includes("HTTP error! Status: 401")) {
        localStorage.removeItem("member_id");
        localStorage.removeItem("api_token");
        localStorage.removeItem("user_name");
        window.location.reload();
        toast.error("Unauthorized access! Please log in again.", {
          autoClose: 1500,
        });
      } else {
        
      }
    }
  };
}
export function CheckMobileNumberData(data) {
  return async (dispatch) => {
    try {
      var response = await fetch(
        `/api/checkMobileNumber?mobile_no=${data}&promo_code=`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      var res_data = await response.json();

      var return_response = {
        type: "CHECK_MOBILE_NUMBER_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      if (
        error.message.includes("HTTP error! Status: 404") ||
        error.message.includes("HTTP error! Status: 400")
      ) {
        try {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message;
          toast.error(errorMessage, { autoClose: 1500 });
        } catch (jsonError) {
          console.error("Error parsing JSON from API response:", jsonError);
          toast.error("Something went wrong! Please try again later.", {
            autoClose: 1500,
          });
        }
      } else if (error.message.includes("HTTP error! Status: 401")) {
        localStorage.removeItem("member_id");
        localStorage.removeItem("api_token");
        localStorage.removeItem("user_name");
        window.location.reload();
        toast.error("Unauthorized access! Please log in again.", {
          autoClose: 1500,
        });
      } else {
        
      }
    }
  };
}
export function CheckMemberData(data, setLoading) {
  setLoading(true);
  return async (dispatch) => {
    try {
      var response = await fetch("/api/checkMember", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      var res_data = await response.json();
      setLoading(false);
      var return_response = {
        type: "CHECK_MEMBER_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      setLoading(false);
      if (
        error.message.includes("HTTP error! Status: 404") ||
        error.message.includes("HTTP error! Status: 400")
      ) {
        try {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message;
          toast.error(errorMessage, { autoClose: 1500 });
        } catch (jsonError) {
          console.error("Error parsing JSON from API response:", jsonError);
          toast.error("Something went wrong! Please try again later.", {
            autoClose: 1500,
          });
        }
      } else if (error.message.includes("HTTP error! Status: 401")) {
        localStorage.removeItem("member_id");
        localStorage.removeItem("api_token");
        localStorage.removeItem("user_name");
        window.location.reload();
        toast.error("Unauthorized access! Please log in again.", {
          autoClose: 1500,
        });
      } else {
        
      }
    }
  };
}

export function Registergoogle(params) {
  const api_token = localStorage.getItem("api_token");
  return async (dispatch) => {
    try {
      var response = await fetch(`/api/registerGoogle`, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + api_token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      var res_data = await response.json();
      var return_response = {
        type: "REGISTER_GOOGLE",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      if (
        error.message.includes("HTTP error! Status: 404") ||
        error.message.includes("HTTP error! Status: 400")
      ) {
        try {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message;
          toast.error(errorMessage, { autoClose: 1500 });
        } catch (jsonError) {
          console.error("Error parsing JSON from API response:", jsonError);
          toast.error("Something went wrong! Please try again later.", {
            autoClose: 1500,
          });
        }
      } else if (error.message.includes("HTTP error! Status: 401")) {
        localStorage.removeItem("member_id");
        localStorage.removeItem("api_token");
        localStorage.removeItem("user_name");
        window.location.reload();
        toast.error("Unauthorized access! Please log in again.", {
          autoClose: 1500,
        });
      } else {
        
      }
    }
  };
}

export function GetConfigData() {
  return async (dispatch) => {
    try {
      var response = await fetch("/api/company_name");
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      var res_data = await response.json();
      var return_response = {
        type: "CONFIG_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      if (
        error.message.includes("HTTP error! Status: 404") ||
        error.message.includes("HTTP error! Status: 400")
      ) {
        try {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message;
          toast.error(errorMessage, { autoClose: 1500 });
        } catch (jsonError) {
          console.error("Error parsing JSON from API response:", jsonError);
          toast.error("Something went wrong! Please try again later.", {
            autoClose: 1500,
          });
        }
      } else if (error.message.includes("HTTP error! Status: 401")) {
        localStorage.removeItem("member_id");
        localStorage.removeItem("api_token");
        localStorage.removeItem("user_name");
        window.location.reload();
        toast.error("Unauthorized access! Please log in again.", {
          autoClose: 1500,
        });
      } else {
        
      }
    }
  };
}
export function AboutUsData() {
  return async (dispatch) => {
    try {
      const api_token = localStorage.getItem("api_token");
      var response = await fetch("/api/about_us", {
        headers: {
          Authorization: `Bearer ${api_token}`,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      var res_data = await response.json();
      var return_response = {
        type: "ABOUT_US_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      if (
        error.message.includes("HTTP error! Status: 404") ||
        error.message.includes("HTTP error! Status: 400")
      ) {
        try {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message;
          toast.error(errorMessage, { autoClose: 1500 });
        } catch (jsonError) {
          console.error("Error parsing JSON from API response:", jsonError);
          toast.error("Something went wrong! Please try again later.", {
            autoClose: 1500,
          });
        }
      } else if (error.message.includes("HTTP error! Status: 401")) {
        localStorage.removeItem("member_id");
        localStorage.removeItem("api_token");
        localStorage.removeItem("user_name");
        window.location.reload();
        toast.error("Unauthorized access! Please log in again.", {
          autoClose: 1500,
        });
      } else {
        
      }
    }
  };
}

export function TermsData() {
  return async (dispatch) => {
    try {
      const api_token = localStorage.getItem("api_token");
      var response = await fetch("/api/terms_conditions", {
        headers: {
          Authorization: `Bearer ${api_token}`,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      var res_data = await response.json();
      var return_response = {
        type: "TERMS_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      if (
        error.message.includes("HTTP error! Status: 404") ||
        error.message.includes("HTTP error! Status: 400")
      ) {
        try {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message;
          toast.error(errorMessage, { autoClose: 1500 });
        } catch (jsonError) {
          console.error("Error parsing JSON from API response:", jsonError);
          toast.error("Something went wrong! Please try again later.", {
            autoClose: 1500,
          });
        }
      } else if (error.message.includes("HTTP error! Status: 401")) {
        localStorage.removeItem("member_id");
        localStorage.removeItem("api_token");
        localStorage.removeItem("user_name");
        window.location.reload();
        toast.error("Unauthorized access! Please log in again.", {
          autoClose: 1500,
        });
      } else {
        
      }
    }
  };
}
export function CustomerSupportData() {
  return async (dispatch) => {
    try {
      const api_token = localStorage.getItem("api_token");
      var response = await fetch("/api/customer_support", {
        headers: {
          Authorization: `Bearer ${api_token}`,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      var res_data = await response.json();
      var return_response = {
        type: "CUSTOMER_SUPPORT_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      if (
        error.message.includes("HTTP error! Status: 404") ||
        error.message.includes("HTTP error! Status: 400")
      ) {
        try {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message;
          toast.error(errorMessage, { autoClose: 1500 });
        } catch (jsonError) {
          console.error("Error parsing JSON from API response:", jsonError);
          toast.error("Something went wrong! Please try again later.", {
            autoClose: 1500,
          });
        }
      } else if (error.message.includes("HTTP error! Status: 401")) {
        localStorage.removeItem("member_id");
        localStorage.removeItem("api_token");
        localStorage.removeItem("user_name");
        window.location.reload();
        toast.error("Unauthorized access! Please log in again.", {
          autoClose: 1500,
        });
      } else {
        
      }
    }
  };
}
export function MyLeaderBoardData() {
  const api_token = localStorage.getItem("api_token");
  return async (dispatch) => {
    try {
      var response = await fetch("/api/leader_board", {
        headers: {
          Authorization: `Bearer ${api_token}`,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      var res_data = await response.json();
      var return_response = {
        type: "MY_LEADER_BOARD_DATA",
        payload: res_data,
      };

      dispatch(return_response);
    } catch (error) {
      if (
        error.message.includes("HTTP error! Status: 404") ||
        error.message.includes("HTTP error! Status: 400")
      ) {
        try {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message;
          toast.error(errorMessage, { autoClose: 1500 });
        } catch (jsonError) {
          console.error("Error parsing JSON from API response:", jsonError);
          toast.error("Something went wrong! Please try again later.", {
            autoClose: 1500,
          });
        }
      } else if (error.message.includes("HTTP error! Status: 401")) {
        localStorage.removeItem("member_id");
        localStorage.removeItem("api_token");
        localStorage.removeItem("user_name");
        window.location.reload();
        toast.error("Unauthorized access! Please log in again.", {
          autoClose: 1500,
        });
      } else {
        
      }
    }
  };
}
export function Announcement_data() {
  const api_token = localStorage.getItem("api_token");
  return async (dispatch) => {
    try {
      var response = await fetch("/api/announcement", {
        headers: {
          Authorization: `Bearer ${api_token}`,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      var res_data = await response.json();
      var return_response = {
        type: "ANNOUNCEMENT_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      if (
        error.message.includes("HTTP error! Status: 404") ||
        error.message.includes("HTTP error! Status: 400")
      ) {
        try {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message;
          toast.error(errorMessage, { autoClose: 1500 });
        } catch (jsonError) {
          console.error("Error parsing JSON from API response:", jsonError);
          toast.error("Something went wrong! Please try again later.", {
            autoClose: 1500,
          });
        }
      } else if (error.message.includes("HTTP error! Status: 401")) {
        localStorage.removeItem("member_id");
        localStorage.removeItem("api_token");
        localStorage.removeItem("user_name");
        window.location.reload();
        toast.error("Unauthorized access! Please log in again.", {
          autoClose: 1500,
        });
      } else {
        
      }
    }
  };
}
export function Referral_data() {
  const api_token = localStorage.getItem("api_token");
  const id = localStorage.getItem("member_id");
  return async (dispatch) => {
    try {
      var response = await fetch(
        `/api/my_refrrrals/${id}`,
        {
          headers: {
            Authorization: `Bearer ${api_token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      var res_data = await response.json();
      var return_response = {
        type: "REFERRAL_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      if (
        error.message.includes("HTTP error! Status: 404") ||
        error.message.includes("HTTP error! Status: 400")
      ) {
        try {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message;
          toast.error(errorMessage, { autoClose: 1500 });
        } catch (jsonError) {
          console.error("Error parsing JSON from API response:", jsonError);
          toast.error("Something went wrong! Please try again later.", {
            autoClose: 1500,
          });
        }
      } else if (error.message.includes("HTTP error! Status: 401")) {
        localStorage.removeItem("member_id");
        localStorage.removeItem("api_token");
        localStorage.removeItem("user_name");
        window.location.reload();
        toast.error("Unauthorized access! Please log in again.", {
          autoClose: 1500,
        });
      } else {
        
      }
    }
  };
}
export function Dashboard() {
  const api_token = localStorage.getItem("api_token");
  const id = localStorage.getItem("member_id");

  if (!id) {
    return (dispatch) => {
      dispatch({
        type: "DASHBOARD_DATA",
        payload: null,
      });
    };
  }

  return async (dispatch) => {
    try {
      var response = await fetch("/api/dashboard/" + id, {
        headers: {
          Authorization: "Bearer " + api_token,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      var res_data = await response.json();
      var return_response = {
        type: "DASHBOARD_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      if (
        error.message.includes("HTTP error! Status: 404") ||
        error.message.includes("HTTP error! Status: 400")
      ) {
        try {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message;
          toast.error(errorMessage, { autoClose: 1500 });
        } catch (jsonError) {
          console.error("Error parsing JSON from API response:", jsonError);
          toast.error("Something went wrong! Please try again later.", {
            autoClose: 1500,
          });
        }
      } else if (error.message.includes("HTTP error! Status: 401")) {
        localStorage.removeItem("member_id");
        localStorage.removeItem("api_token");
        localStorage.removeItem("user_name");
        window.location.reload();
        toast.error("Unauthorized access! Please log in again.", {
          autoClose: 1500,
        });
      } else {
        
      }
    }
  };
}
export function Transaction_data() {
  const api_token = localStorage.getItem("api_token");
  return async (dispatch) => {
    try {
      var response = await fetch(`/api/transaction`, {
        headers: {
          Authorization: "Bearer " + api_token,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();
      var return_response = {
        type: "TRANSACTION_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      if (
        error.message.includes("HTTP error! Status: 404") ||
        error.message.includes("HTTP error! Status: 400")
      ) {
        try {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message;
          toast.error(errorMessage, { autoClose: 1500 });
        } catch (jsonError) {
          console.error("Error parsing JSON from API response:", jsonError);
          toast.error("Something went wrong! Please try again later.", {
            autoClose: 1500,
          });
        }
      } else if (error.message.includes("HTTP error! Status: 401")) {
        localStorage.removeItem("member_id");
        localStorage.removeItem("api_token");
        localStorage.removeItem("user_name");
        window.location.reload();
        toast.error("Unauthorized access! Please log in again.", {
          autoClose: 1500,
        });
      } else {
        
      }
    }
  };
}
export function Payment() {
  const api_token = localStorage.getItem("api_token");
  return async (dispatch) => {
    try {
      var response = await fetch("/api/payment", {
        headers: {
          Authorization: "Bearer " + api_token,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      var res_data = await response.json();
      var return_response = {
        type: "PAYMENT_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      if (
        error.message.includes("HTTP error! Status: 404") ||
        error.message.includes("HTTP error! Status: 400")
      ) {
        try {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message;
          toast.error(errorMessage, { autoClose: 1500 });
        } catch (jsonError) {
          console.error("Error parsing JSON from API response:", jsonError);
          toast.error("Something went wrong! Please try again later.", {
            autoClose: 1500,
          });
        }
      } else if (error.message.includes("HTTP error! Status: 401")) {
        localStorage.removeItem("member_id");
        localStorage.removeItem("api_token");
        localStorage.removeItem("user_name");
        window.location.reload();
        toast.error("Unauthorized access! Please log in again.", {
          autoClose: 1500,
        });
      } else {
        
      }
    }
  };
}
export function Add_money_data(data) {
  const api_token = localStorage.getItem("api_token");
  return async (dispatch) => {
    try {
      var response = await fetch("/api/add_money", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + api_token,
        },
        body: data,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      var res_data = await response.json();
      var return_response = {
        type: "ADD_MONEY_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      if (
        error.message.includes("HTTP error! Status: 404") ||
        error.message.includes("HTTP error! Status: 400")
      ) {
        try {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message;
          toast.error(errorMessage, { autoClose: 1500 });
        } catch (jsonError) {
          console.error("Error parsing JSON from API response:", jsonError);
          toast.error("Something went wrong! Please try again later.", {
            autoClose: 1500,
          });
        }
      } else if (error.message.includes("HTTP error! Status: 401")) {
        localStorage.removeItem("member_id");
        localStorage.removeItem("api_token");
        localStorage.removeItem("user_name");
        window.location.reload();
        toast.error("Unauthorized access! Please log in again.", {
          autoClose: 1500,
        });
      } else {
        
      }
    }
  };
}
export function GetCountry() {
  return async (dispatch) => {
    try {
      var response = await fetch("/api/all_country");
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      var res_data = await response.json();
      var return_response = {
        type: "GET_COUNTRY_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      if (
        error.message.includes("HTTP error! Status: 404") ||
        error.message.includes("HTTP error! Status: 400")
      ) {
        try {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message;
          toast.error(errorMessage, { autoClose: 1500 });
        } catch (jsonError) {
          console.error("Error parsing JSON from API response:", jsonError);
          toast.error("Something went wrong! Please try again later.", {
            autoClose: 1500,
          });
        }
      } else if (error.message.includes("HTTP error! Status: 401")) {
        localStorage.removeItem("member_id");
        localStorage.removeItem("api_token");
        localStorage.removeItem("user_name");
        window.location.reload();
        toast.error("Unauthorized access! Please log in again.", {
          autoClose: 1500,
        });
      } else {
        
      }
    }
  };
}
export function MyProfileData() {
  const id = localStorage.getItem("member_id");
  const api_token = localStorage.getItem("api_token");
  return async (dispatch) => {
    try {
      var response = await fetch("/api/my_profile/" + id, {
        headers: {
          Authorization: "Bearer " + api_token,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      var res_data = await response.json();
      var return_response = {
        type: "MY_PROFILE_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      if (
        error.message.includes("HTTP error! Status: 404") ||
        error.message.includes("HTTP error! Status: 400")
      ) {
        try {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message;
          toast.error(errorMessage, { autoClose: 1500 });
        } catch (jsonError) {
          console.error("Error parsing JSON from API response:", jsonError);
          toast.error("Something went wrong! Please try again later.", {
            autoClose: 1500,
          });
        }
      } else if (error.message.includes("HTTP error! Status: 401")) {
        localStorage.removeItem("member_id");
        localStorage.removeItem("api_token");
        localStorage.removeItem("user_name");
        window.location.reload();
        toast.error("Unauthorized access! Please log in again.", {
          autoClose: 1500,
        });
      } else {
        
      }
    }
  };
}

export function UpdateMyProfile(data) {
  const api_token = localStorage.getItem("api_token");
  return async (dispatch) => {
    try {
      var response = await fetch("/api/update_myprofile", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + api_token,
        },
        body: data,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      var res_data = await response.json();
      var return_response = {
        type: "UPDATE_MY_PROFILE_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      if (
        error.message.includes("HTTP error! Status: 404") ||
        error.message.includes("HTTP error! Status: 400")
      ) {
        try {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message;
          toast.error(errorMessage, { autoClose: 1500 });
        } catch (jsonError) {
          console.error("Error parsing JSON from API response:", jsonError);
          toast.error("Something went wrong! Please try again later.", {
            autoClose: 1500,
          });
        }
      } else if (error.message.includes("HTTP error! Status: 401")) {
        localStorage.removeItem("member_id");
        localStorage.removeItem("api_token");
        localStorage.removeItem("user_name");
        window.location.reload();
        toast.error("Unauthorized access! Please log in again.", {
          autoClose: 1500,
        });
      } else {
        
      }
    }
  };
}

export function ChangePasswordHangle(data) {
  const api_token = localStorage.getItem("api_token");

  return async (dispatch) => {
    try {
      var response = await fetch("/api/update_myprofile", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + api_token,
        },
        body: data,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      var res_data = await response.json();
      var return_response = {
        type: "CHANGE_PASSWORD_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      if (
        error.message.includes("HTTP error! Status: 404") ||
        error.message.includes("HTTP error! Status: 400")
      ) {
        try {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message;
          toast.error(errorMessage, { autoClose: 1500 });
        } catch (jsonError) {
          console.error("Error parsing JSON from API response:", jsonError);
          toast.error("Something went wrong! Please try again later.", {
            autoClose: 1500,
          });
        }
      } else if (error.message.includes("HTTP error! Status: 401")) {
        localStorage.removeItem("member_id");
        localStorage.removeItem("api_token");
        localStorage.removeItem("user_name");
        window.location.reload();
        toast.error("Unauthorized access! Please log in again.", {
          autoClose: 1500,
        });
      } else {
        
      }
    }
  };
}

export function All_game() {
  const api_token = localStorage.getItem("api_token");

  return async (dispatch) => {
    try {
      var response = await fetch("/api/all_game", {
        headers: {
          Authorization: "Bearer " + api_token,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      var res_data = await response.json();
      var return_response = {
        type: "ALL_GAME_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      if (
        error.message.includes("HTTP error! Status: 404") ||
        error.message.includes("HTTP error! Status: 400")
      ) {
        try {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message;
          toast.error(errorMessage, { autoClose: 1500 });
        } catch (jsonError) {
          console.error("Error parsing JSON from API response:", jsonError);
          toast.error("Something went wrong! Please try again later.", {
            autoClose: 1500,
          });
        }
      } else if (error.message.includes("HTTP error! Status: 401")) {
        localStorage.removeItem("member_id");
        localStorage.removeItem("api_token");
        localStorage.removeItem("user_name");
        window.location.reload();
        toast.error("Unauthorized access! Please log in again.", {
          autoClose: 1500,
        });
      } else {
        
      }
    }
  };
}

export function All_ongoing_match(id) {
  const api_token = localStorage.getItem("api_token");
  return async (dispatch) => {
    try {
      var response = await fetch(
        `/api/live_challenge_list/${id}`,
        {
          headers: {
            Authorization: "Bearer " + api_token,
          },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      var res_data = await response.json();
      var return_response = {
        type: "ALL_ONGOING_MATCH_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      if (
        error.message.includes("HTTP error! Status: 404") ||
        error.message.includes("HTTP error! Status: 400")
      ) {
        try {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message;
          toast.error(errorMessage, { autoClose: 1500 });
        } catch (jsonError) {
          console.error("Error parsing JSON from API response:", jsonError);
          toast.error("Something went wrong! Please try again later.", {
            autoClose: 1500,
          });
        }
      } else if (error.message.includes("HTTP error! Status: 401")) {
        localStorage.removeItem("member_id");
        localStorage.removeItem("api_token");
        localStorage.removeItem("user_name");
        window.location.reload();
        toast.error("Unauthorized access! Please log in again.", {
          autoClose: 1500,
        });
      } else {
        
      }
    }
  };
}

export function All_contest_match(id) {
  const api_token = localStorage.getItem("api_token");
  return async (dispatch) => {
    try {
      var response = await fetch(
        `/api/my_challenge_list/${id}`,
        {
          headers: {
            Authorization: "Bearer " + api_token,
          },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      var res_data = await response.json();
      var return_response = {
        type: "ALL_CONTEST_MATCH_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      if (
        error.message.includes("HTTP error! Status: 404") ||
        error.message.includes("HTTP error! Status: 400")
      ) {
        try {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message;
          toast.error(errorMessage, { autoClose: 1500 });
        } catch (jsonError) {
          console.error("Error parsing JSON from API response:", jsonError);
          toast.error("Something went wrong! Please try again later.", {
            autoClose: 1500,
          });
        }
      } else if (error.message.includes("HTTP error! Status: 401")) {
        localStorage.removeItem("member_id");
        localStorage.removeItem("api_token");
        localStorage.removeItem("user_name");
        window.location.reload();
        toast.error("Unauthorized access! Please log in again.", {
          autoClose: 1500,
        });
      } else {
        
      }
    }
  };
}

export function All_result_match(id) {
  const api_token = localStorage.getItem("api_token");
  return async (dispatch) => {
    try {
      var response = await fetch(
        `/api/challenge_result_list/${id}`,
        {
          headers: {
            Authorization: "Bearer " + api_token,
          },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      var res_data = await response.json();
      var return_response = {
        type: "ALL_RESULT_MATCH_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      if (
        error.message.includes("HTTP error! Status: 404") ||
        error.message.includes("HTTP error! Status: 400")
      ) {
        try {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message;
          toast.error(errorMessage, { autoClose: 1500 });
        } catch (jsonError) {
          console.error("Error parsing JSON from API response:", jsonError);
          toast.error("Something went wrong! Please try again later.", {
            autoClose: 1500,
          });
        }
      } else if (error.message.includes("HTTP error! Status: 401")) {
        localStorage.removeItem("member_id");
        localStorage.removeItem("api_token");
        localStorage.removeItem("user_name");
        window.location.reload();
        toast.error("Unauthorized access! Please log in again.", {
          autoClose: 1500,
        });
      } else {
        
      }
    }
  };
}

export function Notificationdata(id) {
  const api_token = localStorage.getItem("api_token");
  return async (dispatch) => {
    try {
      var response = await fetch(
        `/api/notification_list/${id}`,
        {
          headers: {
            Authorization: "Bearer " + api_token,
          },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      var res_data = await response.json();
      var return_response = {
        type: "NOTIFICATION_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      if (
        error.message.includes("HTTP error! Status: 404") ||
        error.message.includes("HTTP error! Status: 400")
      ) {
        try {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message;
          toast.error(errorMessage, { autoClose: 1500 });
        } catch (jsonError) {
          console.error("Error parsing JSON from API response:", jsonError);
          toast.error("Something went wrong! Please try again later.", {
            autoClose: 1500,
          });
        }
      } else if (error.message.includes("HTTP error! Status: 401")) {
        localStorage.removeItem("member_id");
        localStorage.removeItem("api_token");
        localStorage.removeItem("user_name");
        window.location.reload();
        toast.error("Unauthorized access! Please log in again.", {
          autoClose: 1500,
        });
      } else {
        
      }
    }
  };
}

export function LudoLeaderBoarddata(id) {
  const api_token = localStorage.getItem("api_token");
  return async (dispatch) => {
    try {
      var response = await fetch(
        `/api/ludo_leader_board/${id}`,
        {
          headers: {
            Authorization: "Bearer " + api_token,
          },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      var res_data = await response.json();
      var return_response = {
        type: "LUDO_LEADER_BOARD_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      if (
        error.message.includes("HTTP error! Status: 404") ||
        error.message.includes("HTTP error! Status: 400")
      ) {
        try {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message;
          toast.error(errorMessage, { autoClose: 1500 });
        } catch (jsonError) {
          console.error("Error parsing JSON from API response:", jsonError);
          toast.error("Something went wrong! Please try again later.", {
            autoClose: 1500,
          });
        }
      } else if (error.message.includes("HTTP error! Status: 401")) {
        localStorage.removeItem("member_id");
        localStorage.removeItem("api_token");
        localStorage.removeItem("user_name");
        window.location.reload();
        toast.error("Unauthorized access! Please log in again.", {
          autoClose: 1500,
        });
      } else {
        
      }
    }
  };
}

export function Friendsdata(id) {
  const api_token = localStorage.getItem("api_token");
  return async (dispatch) => {
    try {
      var response = await fetch(`/api/budy_list/${id}`, {
        headers: {
          Authorization: "Bearer " + api_token,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      var res_data = await response.json();
      var return_response = {
        type: "FRIENDS_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      if (
        error.message.includes("HTTP error! Status: 404") ||
        error.message.includes("HTTP error! Status: 400")
      ) {
        try {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message;
          toast.error(errorMessage, { autoClose: 1500 });
        } catch (jsonError) {
          console.error("Error parsing JSON from API response:", jsonError);
          toast.error("Something went wrong! Please try again later.", {
            autoClose: 1500,
          });
        }
      } else if (error.message.includes("HTTP error! Status: 401")) {
        localStorage.removeItem("member_id");
        localStorage.removeItem("api_token");
        localStorage.removeItem("user_name");
        window.location.reload();
        toast.error("Unauthorized access! Please log in again.", {
          autoClose: 1500,
        });
      } else {
        
      }
    }
  };
}

export function Friend_request_data(value) {
  const api_token = localStorage.getItem("api_token");
  return async (dispatch) => {
    try {
      var response = await fetch(
        `/api/budy_play_request/${value?.member_id}/${value?.game_id}`,
        {
          headers: {
            Authorization: "Bearer " + api_token,
          },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      var res_data = await response.json();
      var return_response = {
        type: "FRIENDS_REQUEST_PLAY_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      if (
        error.message.includes("HTTP error! Status: 404") ||
        error.message.includes("HTTP error! Status: 400")
      ) {
        try {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message;
          toast.error(errorMessage, { autoClose: 1500 });
        } catch (jsonError) {
          console.error("Error parsing JSON from API response:", jsonError);
          toast.error("Something went wrong! Please try again later.", {
            autoClose: 1500,
          });
        }
      } else if (error.message.includes("HTTP error! Status: 401")) {
        localStorage.removeItem("member_id");
        localStorage.removeItem("api_token");
        localStorage.removeItem("user_name");
        window.location.reload();
        toast.error("Unauthorized access! Please log in again.", {
          autoClose: 1500,
        });
      } else {
        
      }
    }
  };
}

export function Get_follow_status(id) {
  const member_id = localStorage.getItem("member_id");
  const api_token = localStorage.getItem("api_token");
  return async (dispatch) => {
    try {
      var response = await fetch(
        `/api/get_game_follow_status/${id.id}/${member_id}`,
        {
          headers: {
            Authorization: "Bearer " + api_token,
          },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      var res_data = await response.json();
      var return_response = {
        type: "GET_FOLLOW_STATUS_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      if (
        error.message.includes("HTTP error! Status: 404") ||
        error.message.includes("HTTP error! Status: 400")
      ) {
        try {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message;
          toast.error(errorMessage, { autoClose: 1500 });
        } catch (jsonError) {
          console.error("Error parsing JSON from API response:", jsonError);
          toast.error("Something went wrong! Please try again later.", {
            autoClose: 1500,
          });
        }
      } else if (error.message.includes("HTTP error! Status: 401")) {
        localStorage.removeItem("member_id");
        localStorage.removeItem("api_token");
        localStorage.removeItem("user_name");
        window.location.reload();
        toast.error("Unauthorized access! Please log in again.", {
          autoClose: 1500,
        });
      } else {
        
      }
    }
  };
}

export function Post_follow_status_data(params) {
  const api_token = localStorage.getItem("api_token");
  return async (dispatch) => {
    try {
      var response = await fetch(
        `/api/follow_unfollow_game`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + api_token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(params),
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      var res_data = await response.json();
      var return_response = {
        type: "POST_FOLLOW_STATUS_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      if (
        error.message.includes("HTTP error! Status: 404") ||
        error.message.includes("HTTP error! Status: 400")
      ) {
        try {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message;
          toast.error(errorMessage, { autoClose: 1500 });
        } catch (jsonError) {
          console.error("Error parsing JSON from API response:", jsonError);
          toast.error("Something went wrong! Please try again later.", {
            autoClose: 1500,
          });
        }
      } else if (error.message.includes("HTTP error! Status: 401")) {
        localStorage.removeItem("member_id");
        localStorage.removeItem("api_token");
        localStorage.removeItem("user_name");
        window.location.reload();
        toast.error("Unauthorized access! Please log in again.", {
          autoClose: 1500,
        });
      } else {
        
      }
    }
  };
}

export function withdraw_method_data() {
  const api_token = localStorage.getItem("api_token");
  return async (dispatch) => {
    try {
      var response = await fetch("/api/withdraw_method", {
        headers: {
          Authorization: "Bearer " + api_token,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      var res_data = await response.json();
      var return_response = {
        type: "WITHDRAW_METHOD_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      if (
        error.message.includes("HTTP error! Status: 404") ||
        error.message.includes("HTTP error! Status: 400")
      ) {
        try {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message;
          toast.error(errorMessage, { autoClose: 1500 });
        } catch (jsonError) {
          console.error("Error parsing JSON from API response:", jsonError);
          toast.error("Something went wrong! Please try again later.", {
            autoClose: 1500,
          });
        }
      } else if (error.message.includes("HTTP error! Status: 401")) {
        localStorage.removeItem("member_id");
        localStorage.removeItem("api_token");
        localStorage.removeItem("user_name");
        window.location.reload();
        toast.error("Unauthorized access! Please log in again.", {
          autoClose: 1500,
        });
      } else {
        
      }
    }
  };
}

export function Challenge_result_upload_data(params) {
  const api_token = localStorage.getItem("api_token");
  return async (dispatch) => {
    try {
      var response = await fetch(
        `/api/challenge_result_upload`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + api_token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(params),
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      var res_data = await response.json();
      var return_response = {
        type: "CHALLENGE_RESULT_UPLOAD_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      if (
        error.message.includes("HTTP error! Status: 404") ||
        error.message.includes("HTTP error! Status: 400")
      ) {
        try {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message;
          toast.error(errorMessage, { autoClose: 1500 });
        } catch (jsonError) {
          console.error("Error parsing JSON from API response:", jsonError);
          toast.error("Something went wrong! Please try again later.", {
            autoClose: 1500,
          });
        }
      } else if (error.message.includes("HTTP error! Status: 401")) {
        localStorage.removeItem("member_id");
        localStorage.removeItem("api_token");
        localStorage.removeItem("user_name");
        window.location.reload();
        toast.error("Unauthorized access! Please log in again.", {
          autoClose: 1500,
        });
      } else {
        
      }
    }
  };
}

export function Accept_challenge_data(params) {
  const api_token = localStorage.getItem("api_token");
  return async (dispatch) => {
    try {
      var response = await fetch(`/api/accept_challenge`, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + api_token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      var res_data = await response.json();
      var return_response = {
        type: "ACCEPT_CHALLENGE_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      if (
        error.message.includes("HTTP error! Status: 404") ||
        error.message.includes("HTTP error! Status: 400")
      ) {
        try {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message;
          toast.error(errorMessage, { autoClose: 1500 });
        } catch (jsonError) {
          console.error("Error parsing JSON from API response:", jsonError);
          toast.error("Something went wrong! Please try again later.", {
            autoClose: 1500,
          });
        }
      } else if (error.message.includes("HTTP error! Status: 401")) {
        localStorage.removeItem("member_id");
        localStorage.removeItem("api_token");
        localStorage.removeItem("user_name");
        window.location.reload();
        toast.error("Unauthorized access! Please log in again.", {
          autoClose: 1500,
        });
      } else {
        
      }
    }
  };
}

export function Add_challenge_data(params) {
  const api_token = localStorage.getItem("api_token");
  return async (dispatch) => {
    try {
      var response = await fetch(`/api/add_challenge`, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + api_token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      var res_data = await response.json();
      var return_response = {
        type: "ADD_CHALLENGE_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      if (
        error.message.includes("HTTP error! Status: 404") ||
        error.message.includes("HTTP error! Status: 400")
      ) {
        try {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message;
          toast.error(errorMessage, { autoClose: 1500 });
        } catch (jsonError) {
          console.error("Error parsing JSON from API response:", jsonError);
          toast.error("Something went wrong! Please try again later.", {
            autoClose: 1500,
          });
        }
      } else if (error.message.includes("HTTP error! Status: 401")) {
        localStorage.removeItem("member_id");
        localStorage.removeItem("api_token");
        localStorage.removeItem("user_name");
        window.location.reload();
        toast.error("Unauthorized access! Please log in again.", {
          autoClose: 1500,
        });
      } else {
        
      }
    }
  };
}

export function Update_roomcode_data(params) {
  const api_token = localStorage.getItem("api_token");
  return async (dispatch) => {
    try {
      var response = await fetch(
        `/api/update_challenge_room`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + api_token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(params),
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();
      var return_response = {
        type: "UPDATE_ROOMCODE_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      if (
        error.message.includes("HTTP error! Status: 404") ||
        error.message.includes("HTTP error! Status: 400")
      ) {
        try {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message;
          toast.error(errorMessage, { autoClose: 1500 });
        } catch (jsonError) {
          console.error("Error parsing JSON from API response:", jsonError);
          toast.error("Something went wrong! Please try again later.", {
            autoClose: 1500,
          });
        }
      } else if (error.message.includes("HTTP error! Status: 401")) {
        localStorage.removeItem("member_id");
        localStorage.removeItem("api_token");
        localStorage.removeItem("user_name");
        window.location.reload();
        toast.error("Unauthorized access! Please log in again.", {
          autoClose: 1500,
        });
      } else {
        
      }
    }
  };
}

export function Cancel_challenge_data(params) {
  const api_token = localStorage.getItem("api_token");
  return async (dispatch) => {
    try {
      var response = await fetch(`/api/cancel_challenge`, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + api_token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      var res_data = await response.json();
      var return_response = {
        type: "CENCEL_CHALLENGE_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      if (
        error.message.includes("HTTP error! Status: 404") ||
        error.message.includes("HTTP error! Status: 400")
      ) {
        try {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message;
          toast.error(errorMessage, { autoClose: 1500 });
        } catch (jsonError) {
          console.error("Error parsing JSON from API response:", jsonError);
          toast.error("Something went wrong! Please try again later.", {
            autoClose: 1500,
          });
        }
      } else if (error.message.includes("HTTP error! Status: 401")) {
        localStorage.removeItem("member_id");
        localStorage.removeItem("api_token");
        localStorage.removeItem("user_name");
        window.location.reload();
        toast.error("Unauthorized access! Please log in again.", {
          autoClose: 1500,
        });
      } else {
        
      }
    }
  };
}
export function Youtube_data(params) {
  const api_token = localStorage.getItem("api_token");
  return async (dispatch) => {
    try {
      var response = await fetch(`/api/youtube_link`, {
        headers: {
          Authorization: "Bearer " + api_token,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      var res_data = await response.json();
      var return_response = {
        type: "YOUTUBE_LINK_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      if (
        error.message.includes("HTTP error! Status: 404") ||
        error.message.includes("HTTP error! Status: 400")
      ) {
        try {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message;
          toast.error(errorMessage, { autoClose: 1500 });
        } catch (jsonError) {
          console.error("Error parsing JSON from API response:", jsonError);
          toast.error("Something went wrong! Please try again later.", {
            autoClose: 1500,
          });
        }
      } else if (error.message.includes("HTTP error! Status: 401")) {
        localStorage.removeItem("member_id");
        localStorage.removeItem("api_token");
        localStorage.removeItem("user_name");
        window.location.reload();
        toast.error("Unauthorized access! Please log in again.", {
          autoClose: 1500,
        });
      } else {
        
      }
    }
  };
}
export function ResetPassword(params) {
  return async (dispatch) => {
    try {
      var response = await fetch(
        `/api/sendOTP?email_mobile=${params}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      var res_data = await response.json();
      var return_response = {
        type: "RESET_PASSWORD_MAIL",
        payload: res_data,
      };
      localStorage.setItem("countdown", 60);
      dispatch(return_response);
    } catch (error) {
      if (
        error.message.includes("HTTP error! Status: 404") ||
        error.message.includes("HTTP error! Status: 400")
      ) {
        try {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message;
          toast.error(errorMessage, { autoClose: 1500 });
        } catch (jsonError) {
          console.error("Error parsing JSON from API response:", jsonError);
          toast.error("Something went wrong! Please try again later.", {
            autoClose: 1500,
          });
        }
      } else if (error.message.includes("HTTP error! Status: 401")) {
        localStorage.removeItem("member_id");
        localStorage.removeItem("api_token");
        localStorage.removeItem("user_name");
        window.location.reload();
        toast.error("Unauthorized access! Please log in again.", {
          autoClose: 1500,
        });
      } else {
        
      }
    }
  };
}
export function WithdrawWalletData(params) {
  const api_token = localStorage.getItem("api_token");
  return async (dispatch) => {
    try {
      var response = await fetch(`/api/withdraw`, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + api_token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      var res_data = await response.json();
      var return_response = {
        type: "WITHDRAW_WALLET",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      if (
        error.message.includes("HTTP error! Status: 404") ||
        error.message.includes("HTTP error! Status: 400")
      ) {
        try {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message;
          toast.error(errorMessage, { autoClose: 1500 });
        } catch (jsonError) {
          console.error("Error parsing JSON from API response:", jsonError);
          toast.error("Something went wrong! Please try again later.", {
            autoClose: 1500,
          });
        }
      } else if (error.message.includes("HTTP error! Status: 401")) {
        localStorage.removeItem("member_id");
        localStorage.removeItem("api_token");
        localStorage.removeItem("user_name");
        window.location.reload();
        toast.error("Unauthorized access! Please log in again.", {
          autoClose: 1500,
        });
      } else {
        
      }
    }
  };
}

export function Slider() {
  const api_token = localStorage.getItem("api_token");
  return async (dispatch) => {
    try {
      var response = await fetch("/api/slider", {
        headers: {
          Authorization: "Bearer " + api_token,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      var res_data = await response.json();
      var return_response = {
        type: "SLIDER_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      if (
        error.message.includes("HTTP error! Status: 404") ||
        error.message.includes("HTTP error! Status: 400")
      ) {
        try {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message;
          toast.error(errorMessage, { autoClose: 1500 });
        } catch (jsonError) {
          console.error("Error parsing JSON from API response:", jsonError);
          toast.error("Something went wrong! Please try again later.", {
            autoClose: 1500,
          });
        }
      } else if (error.message.includes("HTTP error! Status: 401")) {
        localStorage.removeItem("member_id");
        localStorage.removeItem("api_token");
        localStorage.removeItem("user_name");
        window.location.reload();
        toast.error("Unauthorized access! Please log in again.", {
          autoClose: 1500,
        });
      } else {
        
      }
    }
  };
}
export function VersionEAndroid() {
  return async (dispatch) => {
    try {
      var response = await fetch("/api/version/android", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      var res_data = await response.json();
      var return_response = {
        type: "ANDROID_VERSION_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      if (
        error.message.includes("HTTP error! Status: 404") ||
        error.message.includes("HTTP error! Status: 400")
      ) {
        try {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message;
          toast.error(errorMessage, { autoClose: 1500 });
        } catch (jsonError) {
          console.error("Error parsing JSON from API response:", jsonError);
          toast.error("Something went wrong! Please try again later.", {
            autoClose: 1500,
          });
        }
      } else if (error.message.includes("HTTP error! Status: 401")) {
        localStorage.removeItem("member_id");
        localStorage.removeItem("api_token");
        localStorage.removeItem("user_name");
        window.location.reload();
        toast.error("Unauthorized access! Please log in again.", {
          autoClose: 1500,
        });
      } else {
        
      }
    }
  };
}
export function SendNotification(senddata) {
  return async (dispatch) => {
    try {
      var response = await fetch("https://fcm.googleapis.com/fcm/send", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "key=AAAAXtNGjcQ:APA91bF5nV1ZqGLark1B_M54tGrYh86iLs7Q_lBrLPIa2cc0b-417w3oGcrwkVsx_1BM8N6vyCxJeODHTInF6ixKGfKv4gThdBhRulHiG29Q_a9jvQrCdfWek0ZVeBF5UpEUaEpqTkZ_",
        },
        body: JSON.stringify(senddata),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      var res_data = await response.json();
      var return_response = {
        type: "SEND_NOTIFICATION",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      toast.error("Something went wrong! Please try again later.", {
        autoClose: 1500,
      });
    }
  };
}

export function SendChatNotificationData(formdata) {
  const api_token = localStorage.getItem("api_token");
  return async (dispatch) => {
    try {
      var response = await fetch(`/api/chat_noti`, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + api_token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formdata),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      var res_data = await response.json();
      var return_response = {
        type: "SEND_CHAT_NOTIFICATION",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      if (
        error.message.includes("HTTP error! Status: 404") ||
        error.message.includes("HTTP error! Status: 400")
      ) {
        try {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message;
          toast.error(errorMessage, { autoClose: 1500 });
        } catch (jsonError) {
          console.error("Error parsing JSON from API response:", jsonError);
          toast.error("Something went wrong! Please try again later.", {
            autoClose: 1500,
          });
        }
      } else if (error.message.includes("HTTP error! Status: 401")) {
        localStorage.removeItem("member_id");
        localStorage.removeItem("api_token");
        localStorage.removeItem("user_name");
        window.location.reload();
        toast.error("Unauthorized access! Please log in again.", {
          autoClose: 1500,
        });
      } else {
        
      }
    }
  };
}


export function GetPaymentDetailsData(params) {
  return async (dispatch) => {
    try {
      var response = await fetch(
        `/api/getPaymentDetails/${params}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      var res_data = await response.json();
      var return_response = {
        type: "GET_PAYMENT_DETAILS",
        payload: res_data,
      };
      localStorage.setItem("countdown", 60);
      dispatch(return_response);
    } catch (error) {
      if (
        error.message.includes("HTTP error! Status: 404") ||
        error.message.includes("HTTP error! Status: 400")
      ) {
        try {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message;
          toast.error(errorMessage, { autoClose: 1500 });
        } catch (jsonError) {
          console.error("Error parsing JSON from API response:", jsonError);
          toast.error("Something went wrong! Please try again later.", {
            autoClose: 1500,
          });
        }
      } else if (error.message.includes("HTTP error! Status: 401")) {
        localStorage.removeItem("member_id");
        localStorage.removeItem("api_token");
        localStorage.removeItem("user_name");
        window.location.reload();
        toast.error("Unauthorized access! Please log in again.", {
          autoClose: 1500,
        });
      } else {
        
      }
    }
  };
}