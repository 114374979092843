import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { db } from "../../firebase/firebase";
import {
  addDoc,
  collection,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  where,
} from "firebase/firestore";
import {
  SendChatNotificationData,
  SendNotification,
} from "../../../store/action/UserFetchData";
import { useTranslation } from "react-i18next";
const ChatInput = ({ dispatch, username, data, setChats, token_id, res ,id}) => {
  const [message, setMessage] = useState("");
  const { t } = useTranslation();
  const messageRef = collection(db, "ChatMessage");
  useEffect(() => {
    const queryMessages = query(
      messageRef,
      where("room", "==", data?.auto_id),
      orderBy("createdAt")
    );
    const unSubscribe = onSnapshot(queryMessages, (snapshot) => {
      let messages = [];
      snapshot.forEach((doc) => {
        messages.push({ ...doc.data(), id: doc.id });
      });
      setChats(messages);
    });
    return () => unSubscribe();
  }, []);

  const sendMessage = async (e) => {
    e.preventDefault();
    if (message === "") return;
    setMessage("");
    const senddata = {
      title:`${data?.auto_id} - ${username}`,
      message: message,
      player_id: token_id,
      member_id: id,
      game_id: data?.game_id,
    };
    if (message !== "") {
      dispatch(SendChatNotificationData(senddata));
    }
    try {
      addDoc(messageRef, {
        text: message,
        createdAt: serverTimestamp(),
        user: username,
        room: data?.auto_id,
      });
      setMessage("");
    } catch (error) {
      console.error("Firebase Error:", error);
    }
  };

  const onKeyPressHandler = (e) => {
    if (e.keyCode === 13) {
      sendMessage(e);
    } else {
    }
  };

  return (
    <>
      <div className="d-flex chat-feild" onKeyDown={onKeyPressHandler}>
        <input
          type="text"
          className="form-control rounded-end-0 border-end-0 focus-out"
          style={{ padding: "9px" }}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <button
          className="btn btn-submit btn-lightpink rounded-0"
          type="button"
          onClick={sendMessage}
        >
          {t("send")}
        </button>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  res: state.Send_chat_Notification_data_Reducer,
});

export default connect(mapStateToProps)(ChatInput);
