export const initialState = {
  data: {},
  loading: true,
};
export const initialState1 = {
  isRunning: false,
  duration: 0,
};
export const startTimer = (duration) => ({
  type: 'START_TIMER',
  duration,
});
export function Otp_reducer(state = initialState1, action) {
  switch (action.type) {
    case 'START_TIMER':
      return {
        isRunning: true,
        duration: action.duration,
      };
    default:
      return state;
  }
}
export function Verify_otp_data_reducer(state = initialState, action) {
  switch (action.type) {
    case 'VERIFY_OTP_DATA':
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function register_google_Reducer(state = initialState, action) {
  switch (action.type) {
    case "REGISTER_GOOGLE":
      return { data: action.payload, loading: false };
    default:
      return state;
  } 
}

export function Forgot_password_data_reducer(state = initialState, action) {
  switch (action.type) {
    case 'FORGOT_PASSWORD_DATA':
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Update_mobile_number_data_reducer(state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_MOBILE_NUMBER_DATA':
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Check_mobile_number_data_reducer(state = initialState, action) {
  switch (action.type) {
    case 'CHECK_MOBILE_NUMBER_DATA':
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function RegisterationReducer(state = initialState, action) {
  switch (action.type) {
    case "REGISTER_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function LoginDataReducer(state = initialState, action) {
  switch (action.type) {
    case "LOGIN_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Check_member_data_Reducer(state = initialState, action) {
  switch (action.type) {
    case "CHECK_MEMBER_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function ConfigReducer(state = initialState, action) {
  
  switch (action.type) {
    case "CONFIG_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function AboutUsReducer(state = initialState, action) {
  switch (action.type) {
    case "ABOUT_US_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}


export function TermsReducer(state = initialState, action) {
  switch (action.type) {
    case "TERMS_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function CustomerSupportReducer(state = initialState, action) {
  switch (action.type) {
    case "CUSTOMER_SUPPORT_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function MyLeaderBoardReducer(state = initialState, action) {
  switch (action.type) {
    case "MY_LEADER_BOARD_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function AnnouncementReducer(state = initialState, action) {
  switch (action.type) {
    case "ANNOUNCEMENT_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function ReferralReducer(state = initialState, action) {
  switch (action.type) {
    case "REFERRAL_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function DashboardReducer(state = initialState, action) {
  switch (action.type) {
    case "DASHBOARD_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function TransactionReducer(state = initialState, action) {
  switch (action.type) {
    case "TRANSACTION_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function Payment_Reducer(state = initialState, action) {
  switch (action.type) {
    case "PAYMENT_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function Add_money_Reducer(state = initialState, action) {
  switch (action.type) {
    case "ADD_MONEY_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function GetCountryDataReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_COUNTRY_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function MyProfileDataReducer(state = initialState, action) {
  switch (action.type) {
    case "MY_PROFILE_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function UpdateMyProfileDataReducer(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_MY_PROFILE_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function ChangePasswordReducer(state = initialState, action) {
  switch (action.type) {
    case "CHANGE_PASSWORD_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function AllgameReducer(state = initialState, action) {
  switch (action.type) {
    case "ALL_GAME_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function AllongoingmatchReducer(state = initialState, action) {
  switch (action.type) {
    case "ALL_ONGOING_MATCH_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function AllcontestmatchReducer(state = initialState, action) {
  switch (action.type) {
    case "ALL_CONTEST_MATCH_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function AllresultmatchReducer(state = initialState, action) {
  switch (action.type) {
    case "ALL_RESULT_MATCH_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function GetFollowStatusReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_FOLLOW_STATUS_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function PostFollowStatusReducer(state = initialState, action) {
  switch (action.type) {
    case "POST_FOLLOW_STATUS_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function Withdraw_method_Reducer(state = initialState, action) {
  switch (action.type) {
    case "WITHDRAW_METHOD_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Notificaton_Reducer(state = initialState, action) {
  switch (action.type) {
    case "NOTIFICATION_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Ludoleaderboard_Reducer(state = initialState, action) {
  switch (action.type) {
    case "LUDO_LEADER_BOARD_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Friends_Reducer(state = initialState, action) {
  switch (action.type) {
    case "FRIENDS_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Friendsrequest_Reducer(state = initialState, action) {
  switch (action.type) {
    case "FRIENDS_REQUEST_PLAY_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Challengeresultupload_Reducer(state = initialState, action) {
  switch (action.type) {
    case "CHALLENGE_RESULT_UPLOAD_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Addchallenge_Reducer(state = initialState, action) {
  switch (action.type) {
    case "ADD_CHALLENGE_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Acceptchallenge_Reducer(state = initialState, action) {
  switch (action.type) {
    case "ACCEPT_CHALLENGE_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Updateroomcode_Reducer(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_ROOMCODE_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Cencelchallenge_Reducer(state = initialState, action) {
  switch (action.type) {
    case "CENCEL_CHALLENGE_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function YoutubeLink_Reducer(state = initialState, action) {
  switch (action.type) {
    case "YOUTUBE_LINK_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function ResetPassword_Reducer(state = initialState, action) {
  switch (action.type) {
    case "RESET_PASSWORD_MAIL":
      return { data: action.payload, loading: false };
    default:
      return state;
  } 
}
export function WithdrawWallet_Reducer(state = initialState, action) {
  switch (action.type) {
    case "WITHDRAW_WALLET":
      return { data: action.payload, loading: false };
    default:
      return state;
  } 
}
export function Slider_Reducer(state = initialState, action) {
  switch (action.type) {
    case "SLIDER_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  } 
}
export function AndroidVersion_Reducer(state = initialState, action) {
  switch (action.type) {
    case "ANDROID_VERSION_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  } 
}
export function Send_Notification_Reducer(state = initialState, action) {
  switch (action.type) {
    case "SEND_NOTIFICATION":
      return { data: action.payload, loading: false };
    default:
      return state;
  } 
}
export function Send_chat_Notification_data_Reducer(state = initialState, action) {
  switch (action.type) {
    case "SEND_CHAT_NOTIFICATION":
      return { data: action.payload, loading: false };
    default:
      return state;
  } 
}
export function Get_payment_details_data_Reducer(state = initialState, action) {
  switch (action.type) {
    case "GET_PAYMENT_DETAILS":
      return { data: action.payload, loading: false };
    default:
      return state;
  } 
}


