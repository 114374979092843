import React, { useState } from "react";
import { HiOutlineArrowSmLeft } from "react-icons/hi";
import { Link, useLocation, useParams } from "react-router-dom";
import ChatList from "./ChatList";
import ChatInput from "./ChatInput";
import Title from "../../../common/Title";
import { useTranslation } from "react-i18next";

const Chat = () => {
  const id = useParams();
  const { t } = useTranslation();
  const location = useLocation();
  const member_id = localStorage.getItem("member_id");
  const [chats, setChats] = useState([]);

  return (
    <>
      <Title props={t("chat")} />
      <div className="bm-mdl-header">
        <Link
          to={`/bmuser/play/challenge_detail/${id.id}`}
          state={{
            game_name: location?.state?.value?.game_name,
            game_image: location?.state?.value?.game_image,
            game_package: location?.state?.value?.game_package,
            game_rules: location?.state?.value?.game_rules,
            game_id: location?.state?.value?.game_id,
            member_id: location?.state?.value?.member_id,
            id: location?.state?.value?.id,
          }}
          className="text-white "
        >
          <HiOutlineArrowSmLeft className="fs-2 me-3" />
        </Link>
        <h5 className="m-0 d-inline align-bottom  fw-bold">
          <img
            src={
              location?.state?.data?.member_id === parseInt(member_id)
                ? location?.state?.data?.profile_image
                  ? location?.state?.data?.profile_image
                  : location?.state?.value?.game_image
                : location?.state?.data?.accepted_profile_image
                ? location?.state?.data?.accepted_profile_image
                : location?.state?.value?.game_image
            }
            alt=""
            width={35}
            className="rounded-circle me-1"
          />{" "}
          {location?.state?.data?.member_id === parseInt(member_id)
            ? location?.state?.data?.ludo_king_username
            : location?.state?.data?.accepted_ludo_king_username}
        </h5>
      </div>{" "}
      <ChatList
        chats={chats}
        username={
          location?.state?.data?.member_id === Number(member_id)
            ? location?.state?.data?.ludo_king_username
            : location?.state?.data?.accepted_ludo_king_username
        }
      />
      <ChatInput
        username={
          location?.state?.data?.member_id === Number(member_id)
            ? location?.state?.data?.ludo_king_username
            : location?.state?.data?.accepted_ludo_king_username
        }
        token_id={
          location?.state?.data?.member_id === Number(member_id)
            ? location?.state?.data?.accepted_player_id
            : location?.state?.data?.player_id
        }
        id={location?.state?.data?.member_id === Number(member_id)
          ? location?.state?.data?.accepted_member_id
          : location?.state?.data?.member_id}

        setChats={setChats}
        data={location?.state?.data}
      />
    </>
  );
};

export default Chat;
