import Login from "../Main/auth/Login";
import SentOtp from "../Main/auth/model/SentOtp";
import Register from "../Main/auth/Register";
import NewGeneratePassword from '../Main/auth/NewGeneratePassword'
const authRoute = [
    { path: "/login", component: Login },
    { path: "/register", component: Register},
    { path: "/verify", component: SentOtp },
    { path: "/newpassword", component: NewGeneratePassword },
]
export default authRoute;