import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import { HiOutlineArrowSmLeft } from "react-icons/hi";
import MainSidebar from "../../common/MainSidebar";
import EditProfile from "../account/EditProfile";
import ChangePassword from "../account/ChangePassword";
import { Link } from "react-router-dom";
import Loader from "../../common/Loader";
import { connect } from "react-redux";
import {
  Dashboard,
  GetCountry,
  MyProfileData,
} from "../../store/action/UserFetchData";
import { useTranslation } from "react-i18next";
import Title from "../../common/Title";

const Profile = ({ dispatch, res, all_country_data }) => {
  const [display, setDisplay] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    res.data = {};
    res.loading = true;
    all_country_data.data = {};
    all_country_data.loading = true;
    dispatch(MyProfileData());
    dispatch(GetCountry());
  }, [dispatch]);

  return (
    <>
      <Title props={t("my_profile")} />
      <div
        className="sidebar-overlay"
        style={{ display: display ? "block" : "none" }}
      ></div>
      <div className="bm-mdl-header">
        <div id="content">
          <span id="sidebarCollapse" style={{ right: "20px" }}>
            <FaBars className="fs-3" onClick={() => setDisplay(true)} />
          </span>
        </div>
        <Link to="/bmuser/account" className="text-white">
          <HiOutlineArrowSmLeft className="fa fa-2x fa-long-arrow-left mb-2" />
        </Link>
        <h4 className="m-0 d-inline mx-3 fw-bold">{t("my_profile")}</h4>
      </div>
      {res?.loading && all_country_data?.loading ? (
        <Loader />
      ) : (
        <div className="bm-mdl-center bm-full-height pb-6">
          <div className="bm-content-listing">
            <div className="profile-content text-black">
              <EditProfile all_country_data={all_country_data} res={res} />
              <ChangePassword />
            </div>
          </div>
        </div>
      )}
      <MainSidebar display={display} setDisplay={setDisplay} />
    </>
  );
};

const mapStateToProps = (state) => ({
  res: state?.MyProfileDataReducer,
  all_country_data: state?.GetCountryDataReducer,
});

export default connect(mapStateToProps)(Profile);
