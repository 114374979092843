import React, { useEffect, useState } from "react";
import { FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";
import MainSidebar from "../../common/MainSidebar";
import customer from "../../assets/image/Contact us-rafiki.png";
import Title from "../../common/Title";
import { useTranslation } from "react-i18next";
import { HiOutlineArrowSmLeft } from "react-icons/hi";
import coin from "../../assets/image/coin.png";
import { connect } from "react-redux";
import { CustomerSupportData } from "../../store/action/UserFetchData";
const CustomerSupport = ({ dispatch, res }) => {
  const { t } = useTranslation();
  const [display, setDisplay] = useState(false);
  useEffect(() => {
    dispatch(CustomerSupportData());
  }, [dispatch]);
  const data = res?.data?.customer_support;
  return (
    <>
      <Title props={t("customer_support")} />
      <div
        className="sidebar-overlay"
        style={{ display: display ? "block" : "none" }}
      ></div>
      <div className="bm-mdl-header">
        <div id="content">
          <span id="sidebarCollapse" style={{ right: "20px" }}>
            <FaBars className="fs-3" onClick={() => setDisplay(true)} />
          </span>
        </div>
        <Link to="/bmuser/account" className="text-white">
          <HiOutlineArrowSmLeft className="fa fa-2x fa-long-arrow-left mb-2" />
        </Link>
        <h4 className="m-0 d-inline mx-3 fw-bold">{t("customer_support")}</h4>
      </div>

      <div className="bm-mdl-center bm-full-height pb-6">
        <div className="bm-content-listing">
          <div className="container support">
            <div className="row">
              <div className="col-12 text-dark">
                <img src={customer} alt="---" width="100%" className="" />
                <div className="d-flex flex-column align-items-stretch">
                  <a
                    className="text-decoration-none text-white w-100"
                    onClick={() => window.open(data?.wp_link)}
                  >
                    <button
                      type="button"
                      className="btn btn-lg mb-3 d-flex align-items-center text-white justify-content-center w-100 "
                      style={{ background: "#29AD17" }}
                    >
                      <span>
                        {" "}
                        <i
                          className="fa fa-whatsapp fs-lg me-2 pt-1"
                          aria-hidden="true"
                        ></i>
                        {t("message_on_whatsapp")}
                      </span>
                    </button>
                  </a>
                  <a
                    className="text-decoration-none text-white w-100"
                    onClick={() => window.open(data?.insta_link)}
                  >
                    <button
                      type="button"
                      className="btn btn-lg text-white d-flex align-items-center justify-content-center w-100"
                      style={{
                        background:
                          "linear-gradient(45deg, rgb(240, 148, 51) 0%, rgb(230, 104, 60) 25%, rgb(220, 39, 67) 50%, rgb(204, 35, 102) 75%, rgb(188, 24, 136) 100%)",
                      }}
                    >
                      <span>
                        {" "}
                        <i
                          className="fa fa-instagram fs-lg me-2"
                          aria-hidden="true"
                        ></i>
                        {t("connect_on_instagram")}
                      </span>
                    </button>
                  </a>
                  <a
                    className="text-decoration-none text-white w-100 mt-3"
                    onClick={() => window.open(`mailto:${data?.company_email}`)}
                  >
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-lg d-flex align-items-center justify-content-center w-100 "
                    >
                      <span>
                        {" "}
                        <img src={coin} alt="img-coin" className="coin" />
                        {t("Reach_us_via_email")}
                      </span>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <MainSidebar display={display} setDisplay={setDisplay} />
    </>
  );
};

const mapStateToProps = (state) => ({
  res: state.CustomerSupportReducer,
});

export default connect(mapStateToProps)(CustomerSupport);
