import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { connect } from "react-redux";
import { ResetPassword } from "../../../store/action/UserFetchData";
import { startTimer } from "../../../store/reducer/FetchReducer";
import { useTranslation } from "react-i18next";

const ResetPasswordModel = ({ setReset, reset, dispatch, res }) => {
  const [resetPass, setResetPass] = useState("");
  const [resetPassErr, setResetPassErr] = useState(false);
  const [statusData, setStatusData] = useState("");
  const startCountdown = () => {
    dispatch(startTimer(60));
  };
  const [loading, setLoading] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleChange = (e) => {
    let value = e.target.value;
    setResetPass(value);
    setResetPassErr(false);
  };

  const postData = async () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    const isValid = emailRegex.test(resetPass);
    if (resetPass === "") {
      setResetPassErr(true);
      setStatusData(t("translation1:err_email"));
    } else if (isValid === false) {
      setResetPassErr(true);
      setStatusData(t("translation1:err_valid_email"));
    } else {
      setLoading(true);
      await dispatch(ResetPassword(resetPass));
    }
  };

  useEffect(() => {
    if (res?.loading === false) {
      if (res?.data?.status === false) {
        setResetPassErr(true);
        setLoading(false);
        setStatusData(res?.data?.message);
      } else if (res?.data?.status === true) {
        setReset(false);
        setLoading(false);
        setDisabledButton(true);
        const notify = () =>
          toast.success(res?.data?.message, { autoClose: 1500 });
        notify();
        startCountdown();
        localStorage.setItem("countdown", 60);
        navigate("/verify", {
          state: {
            email: resetPass,
            member_id: res?.data?.member_id,
            second: 60,
          },
        });
        setTimeout(() => {
          res.loading = true;
          res.data = {};
        }, 1500);
      }
    }
  }, [res, navigate]);

  return (
    <>
      <ToastContainer />
      <div
        className={`modal  ${reset ? "show d-block" : "d-none"}`}
        id="addContestModel"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addContestModel"
        aria-modal="true"
        style={{
          background: reset ? "rgba(0, 0, 0, 0.6)" : "",
          position: reset ? "absolute" : "",
          overflowY: reset ? "auto" : "",
        }}
      >
        <div className="modal-dialog">
          <form>
            <div className="modal-content">
              <div className="modal-header btn-green ">
                <h5 className="modal-title">{t("reset_password")}</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={() => setReset(false)}
                >
                  ×
                </button>
              </div>
              <div className="modal-body">
                <label className="mb-1">{t("email_address")}</label>

                <input
                  type="text"
                  id="email_mobile"
                  name="email_mobile"
                  className="form-control"
                  onChange={handleChange}
                />
                <div
                  className="text-danger"
                  style={{
                    marginTop: "5px",
                    display: resetPassErr ? "block" : "none",
                  }}
                >
                  {statusData}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-lightpink"
                  data-dismiss="modal"
                  onClick={() => setReset(false)}
                >
                  {t("close")}
                </button>
                {loading ? (
                  <button
                    type="button"
                    name="forgot"
                    disabled
                    className="btn btn-submit btn-lightpink"
                  >
                    {t("loading")}
                  </button>
                ) : (
                  <button
                    type="button"
                    name="forgot"
                    disabled={disabledButton}
                    className="btn btn-submit btn-lightpink"
                    onClick={postData}
                  >
                    {t("send_otp")}
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  res: state.ResetPassword_Reducer,
});

export default connect(mapStateToProps)(ResetPasswordModel);
