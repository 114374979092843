import React, { useEffect, useState } from "react";
import Loader from "../../../../common/Loader";
import { Link } from "react-router-dom";
import UpdateRoomCode from "../../../play_details/model/UpdateRoomCode";
import { useTranslation } from "react-i18next";
import { FaLock } from "react-icons/fa6";
const MyContestMatch = ({
  res,
  game_image,
  game_name,
  game_id,
  game_package,
  game_rules,
  setCancelDataErr,
  setCancelData,
  setToken_id,
}) => {
  const [change, setChange] = useState();
  const [roomCode, setRoomCode] = useState(false);
  const [tokenId, setTokenId] = useState("");
  const [challengeID, setChallengeID] = useState("");
  const { t } = useTranslation();

  const member_id = localStorage.getItem("member_id");

  return (
    <>
      {res?.loading ? (
        <Loader />
      ) : (
        <div>
          {Array.isArray(res) && res.length > 0? (
            res?.map((data, index) => {
              const { line1, line2 } = t("challenge_result", {
                coin: data?.coin,
                winning_price: data?.winning_price,
              });
              if (
                (data?.added_result === "0" && data?.accepted_result === "0") ||
                (data?.added_result === "0" && data?.accepted_result === "2") ||
                (data?.added_result === "2" && data?.accepted_result === "0") ||
                (data?.added_result === "2" && data?.accepted_result === "2")
              ) {
                return (
                  <div
                    key={data?.ludo_challenge_id}
                    className="bm-content-listing challenge_lists"
                  >
                    <div id="ongoinglist"></div>
                    <div
                      className="card text-center br-5 hide-over mb-3 ludo_challenge_id"
                      value="13"
                    >
                      <Link
                        to={`/bmuser/play/challenge_detail/${data?.ludo_challenge_id}`}
                        state={{
                          game_name,
                          game_image,
                          game_id,
                          game_package,
                          game_rules,
                          member_id: data?.member_id,
                          id: Number(member_id),
                        }}
                        className="text-decoration-none"
                      >
                        <div className="card-body">
                          <div className="row text-center text-dark">
                            <div className="col-3">
                              <img
                                src={
                                  data?.profile_image === null ||
                                  data?.profile_image === ""
                                    ? game_image
                                    : data?.profile_image
                                }
                                className="rounded-circle img-fluid mCS_img_loaded"
                                alt="---"
                              />
                              <br />
                              <span className="bm_text_blue">
                                {data?.ludo_king_username}
                              </span>
                            </div>
                            <div className="col-6 mt-3">
                            {data?.with_password === "1" && (
                                    <h6 className="bm_text_blue fw-bold">
                                      <FaLock  className="fs-"/>
                                    </h6>
                                  )}
                              <h6 className="bm_text_blue fw-bold">
                                {data?.auto_id}
                              </h6>
                              <span className="bm_text_blue">{line1}</span>
                              <br />
                              <span className="bm_text_lightpink">{line2}</span>
                            </div>
                            <div className="col-3">
                              <img
                                src={
                                  data?.accepted_profile_image === null ||
                                  data?.accepted_profile_image === ""
                                    ? game_image
                                    : data?.accepted_profile_image
                                }
                                className="rounded-circle img-fluid mCS_img_loaded"
                                alt="---"
                              />
                              <br />
                              <span className="bm_text_blue">
                                {data?.accepted_ludo_king_username
                                  ? data?.accepted_ludo_king_username
                                  : t("waiting")}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Link>
                      <div className="challenge_detail_footer card-footer px-0 py-0">
                        <div className="row">
                          <div className="col-sm-12 py-1 btn-lightpink ">
                            <a
                              className="acceptContestModel text-white text-decoration-none"
                              style={{ cursor: "pointer" }}
                            >
                              {t("review_by_team")}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              } else {
                if (data?.accept_status === "1") {
                  if (data?.member_id === Number(member_id)) {
                    return (
                      <div
                        key={data?.ludo_challenge_id}
                        className="bm-content-listing challenge_lists"
                      >
                        <div id="ongoinglist"></div>
                        <div
                          className="card text-center br-5 hide-over mb-3 ludo_challenge_id"
                          value="13"
                        >
                          <Link
                            to={`/bmuser/play/challenge_detail/${data?.ludo_challenge_id}`}
                            state={{
                              game_name,
                              game_image,
                              game_id,
                              game_package,
                              game_rules,
                              member_id: data?.member_id,
                              id: Number(member_id),
                            }}
                            className="text-decoration-none"
                          >
                            <div className="card-body">
                              <div className="row text-center text-dark">
                                <div className="col-3">
                                  <img
                                    src={
                                      data?.profile_image === null ||
                                      data?.profile_image === ""
                                        ? game_image
                                        : data?.profile_image
                                    }
                                    className="rounded-circle img-fluid mCS_img_loaded"
                                    alt="---"
                                  />
                                  <br />
                                  <span className="bm_text_blue">
                                    {data?.ludo_king_username}
                                  </span>
                                </div>
                                <div className="col-6 mt-3">
                                  {data?.with_password === "1" && (
                                    <h6 className="bm_text_blue fw-bold">
                                      <FaLock  className="fs-"/>
                                    </h6>
                                  )}
                                  <h6 className="bm_text_blue fw-bold">
                                    {data?.auto_id}
                                  </h6>
                                  <span className="bm_text_blue">{line1}</span>
                                  <br />
                                  <span className="bm_text_lightpink">
                                    {line2}
                                  </span>
                                </div>
                                <div className="col-3">
                                  <img
                                    src={
                                      data?.accepted_profile_image === null ||
                                      data?.accepted_profile_image === ""
                                        ? game_image
                                        : data?.accepted_profile_image
                                    }
                                    alt="---"
                                    className="rounded-circle img-fluid mCS_img_loaded"
                                  />
                                  <br />
                                  <span className="bm_text_blue">
                                    {data?.accepted_ludo_king_username
                                      ? data?.accepted_ludo_king_username
                                      : t("waiting")}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </Link>
                          <div className="challenge_detail_footer card-footer px-0 py-0">
                            <div className="row">
                              {data?.room_code === "" ? (
                                <div
                                  className="col-sm-12 py-1 btn-lightgreen text-white"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setChallengeID(data?.ludo_challenge_id);
                                    setRoomCode(true);
                                    setTokenId(data?.accepted_member_id);
                                  }}
                                >
                                  {t("add_for_roomcode")}
                                </div>
                              ) : (
                                <div className="col-sm-12 py-1 btn-lightgreen text-white">
                                  {t("roomcode")} : {data?.room_code}
                                </div>
                              )}
                              <div
                                className="col-sm-12 py-1 btn-lightpink "
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setCancelDataErr(true);
                                  var postdata = {
                                    member_id:
                                      Number(member_id) === data?.member_id
                                        ? data?.member_id
                                        : data?.accepted_member_id,
                                    ludo_challenge_id: data?.ludo_challenge_id,
                                    canceled_by_flag:
                                      Number(member_id) === data?.member_id ? "0" : "1",
                                  };
                                  setToken_id(
                                    Number(member_id) === data?.member_id
                                      ? data?.accepted_player_id
                                      : data?.player_id
                                  );
                                  setCancelData(postdata);
                                }}
                              >
                                <a className="acceptContestModel text-white text-decoration-none">
                                  {t("cancel")}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        key={data?.ludo_challenge_id}
                        className="bm-content-listing challenge_lists"
                      >
                        <div id="ongoinglist"></div>
                        <div
                          className="card text-center br-5 hide-over mb-3 ludo_challenge_id"
                          value="13"
                        >
                          <Link
                            to={`/bmuser/play/challenge_detail/${data?.ludo_challenge_id}`}
                            state={{
                              game_name,
                              game_image,
                              game_id,
                              game_package,
                              game_rules,
                              member_id: data?.member_id,
                              id: Number(member_id),
                            }}
                            className="text-decoration-none"
                          >
                            <div className="card-body">
                              <div className="row text-center text-dark">
                                <div className="col-3">
                                  <img
                                    src={
                                      data?.profile_image === null ||
                                      data?.profile_image === ""
                                        ? game_image
                                        : data?.profile_image
                                    }
                                    alt="---"
                                    className="rounded-circle img-fluid mCS_img_loaded"
                                  />
                                  <br />
                                  <span className="bm_text_blue">
                                    {data?.ludo_king_username}
                                  </span>
                                </div>
                                <div className="col-6 mt-3">
                                {data?.with_password === "1" && (
                                    <h6 className="bm_text_blue fw-bold">
                                      <FaLock  className="fs-"/>
                                    </h6>
                                  )}
                                  <h6 className="bm_text_blue fw-bold">
                                    {data?.auto_id}
                                  </h6>
                                  <span className="bm_text_blue">{line1}</span>
                                  <br />
                                  <span className="bm_text_lightpink">
                                    {line2}
                                  </span>
                                </div>
                                <div className="col-3">
                                  <img
                                    src={
                                      data?.accepted_profile_image === null ||
                                      data?.accepted_profile_image === ""
                                        ? game_image
                                        : data?.accepted_profile_image
                                    }
                                    alt="---"
                                    className="rounded-circle img-fluid mCS_img_loaded"
                                  />
                                  <br />
                                  <span className="bm_text_blue">
                                    {data?.accepted_ludo_king_username
                                      ? data?.accepted_ludo_king_username
                                      : t("waiting")}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </Link>
                          <div className="challenge_detail_footer card-footer px-0 py-0">
                            <div className="row">
                              <Link
                                to={`/bmuser/play/challenge_detail/${data?.ludo_challenge_id}`}
                                state={{
                                  game_name,
                                  game_image,
                                  game_id,
                                  game_package,
                                  game_rules,
                                  member_id: data?.member_id,
                                  id: Number(member_id),
                                }}
                                className="text-decoration-none"
                              >
                                {data?.room_code === "" ? (
                                  <div
                                    className="col-sm-12 py-1 btn-lightgreen text-white fw-bold
                                  "
                                  >
                                    {t("wait_for_roomcode")}
                                  </div>
                                ) : (
                                  <div className="col-sm-12 py-1 btn-lightgreen text-white">
                                    {t("roomcode")} : {data?.room_code}
                                  </div>
                                )}
                              </Link>
                              <div
                                className="col-sm-12 py-1 btn-lightpink "
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setCancelDataErr(true);
                                  var postdata = {
                                    member_id:
                                      Number(member_id) === data?.member_id
                                        ? data?.member_id
                                        : data?.accepted_member_id,
                                    ludo_challenge_id: data?.ludo_challenge_id,
                                    canceled_by_flag:
                                      Number(member_id) === data?.member_id ? "0" : "1",
                                  };
                                  setToken_id(
                                    Number(member_id) === data?.member_id
                                      ? data?.accepted_player_id
                                      : data?.player_id
                                  );
                                  setCancelData(postdata);
                                }}
                              >
                                <a className="acceptContestModel text-white text-decoration-none">
                                  {t("cancel")}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                } else {
                  return (
                    <div
                      key={index}
                      className="bm-content-listing challenge_lists"
                    >
                      <div id="ongoinglist"></div>
                      <div
                        className="card text-center br-5 hide-over mb-3 ludo_challenge_id"
                        value="13"
                      >
                        <div className="card-body">
                          <div className="row text-center text-dark">
                            <div className="col-3">
                              <img
                                src={
                                  data?.profile_image === null ||
                                  data?.profile_image === ""
                                    ? game_image
                                    : data?.profile_image
                                }
                                alt="---"
                                className="rounded-circle img-fluid mCS_img_loaded"
                              />
                              <br />
                              <span className="bm_text_blue">
                                {data?.ludo_king_username}
                              </span>
                            </div>
                            <div className="col-6 mt-3">
                            {data?.with_password === "1" && (
                                    <h6 className="bm_text_blue fw-bold">
                                      <FaLock  className="fs-"/>
                                    </h6>
                                  )}
                              <h6 className="bm_text_blue fw-bold">
                                {data?.auto_id}
                              </h6>
                              <span className="bm_text_blue">{line1}</span>
                              <br />
                              <span className="bm_text_lightpink">{line2}</span>
                            </div>
                            <div className="col-3">
                              <img
                                src={
                                  data?.accepted_profile_image === null ||
                                  data?.accepted_profile_image === ""
                                    ? game_image
                                    : data?.accepted_profile_image
                                }
                                alt="---"
                                className="rounded-circle img-fluid mCS_img_loaded"
                              />
                              <br />
                              <span className="bm_text_blue">
                                {data?.accepted_ludo_king_username
                                  ? data?.accepted_ludo_king_username
                                  : t("waiting")}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="challenge_detail_footer card-footer px-0 py-0">
                          <div className="row">
                            <div
                              className="col-sm-12 py-1 btn-lightpink "
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setCancelDataErr(true);
                                var postdata = {
                                  member_id:
                                    Number(member_id) === data?.member_id
                                      ? data?.member_id
                                      : data?.accepted_member_id,
                                  ludo_challenge_id: data?.ludo_challenge_id,
                                  canceled_by_flag:
                                    Number(member_id) === data?.member_id ? "0" : "1",
                                };
                                setCancelData(postdata);
                              }}
                            >
                              <a className="acceptContestModel text-white text-decoration-none">
                                {t("cancel")}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              }
            })
          ) : (
            <div className="bm-content-listing challenge_lists">
              <div className="col-md-12 text-center text-dark">
                <strong>{t("no_challenge_found")}</strong>
              </div>
            </div>
          )}
        </div>
      )}
      {roomCode &&
      <UpdateRoomCode
      setRoomCode={setRoomCode}
      roomCode={roomCode}
      setChange={setChange}
      change={change}
      ludo_challenge_id={challengeID}
      token_id={tokenId}
      />
    }
    </>
  );
};

export default MyContestMatch
