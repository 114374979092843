import React from "react";
import { useTranslation } from "react-i18next";
import OtpInput from "react-otp-input";
import { Link } from "react-router-dom";
const RegisterOtp = ({
  openModel,
  setOpenModel,
  sentOtp,
  otpErr,
  otpErrMess,
  loading,
  onOTPVerify,
  handleOtpInputChange,
  SubmitOtp,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div
        className="modal show d-block"
        id="addContestModel"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addContestModel"
        aria-modal="true"
        style={{
          background: openModel ? "rgba(0, 0, 0, 0.6)" : "",
          position: openModel ? "absolute" : "",
          overflowY: openModel ? "auto" : "",
        }}
      >
        <div className="modal-dialog">
          <form>
            <div className="modal-content">
              <div className="modal-header btn-green ">
                <h5 className="modal-title">{t("otp")}</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={() => {
                    setOpenModel(false);
                  }}
                >
                  ×
                </button>
              </div>
              <div className="modal-body pt-2">
                <label
                  className="text-secondary form-label"
                  style={{ fontSize: "12px" }}
                >
                  {t("send_mobile_number_otp")}
                </label>
                <div className="otp-field">
                  <OtpInput
                    value={sentOtp}
                    onChange={handleOtpInputChange}
                    numInputs={6}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                  />
                </div>
                <div
                  className="text-danger"
                  style={{
                    marginTop: "5px",
                    display: otpErr ? "block" : "none",
                  }}
                >
                  {otpErrMess}
                </div>
              </div>
              <div className="modal-footer justify-content-center">
                {loading ? (
                  <button
                    type="button"
                    name="forgot"
                    className="btn btn-submit btn-lightpink"
                    disabled
                  >
                    {t("loading")}
                  </button>
                ) : (
                  <>
                    <div className="text-center">
                      <p className="text-secondary form-label text-center">
                        {t("did_not_recive_otp")}{" "}
                        <Link onClick={() => SubmitOtp()}>{t("resend")}</Link>
                      </p>
                    </div>
                    <button
                      type="button"
                      name="forgot"
                      className="btn btn-submit btn-lightpink w-100"
                      onClick={onOTPVerify}
                    >
                      {t("verify")}
                    </button>
                  </>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default RegisterOtp;
