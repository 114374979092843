import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";
import MainSidebar from "../../common/MainSidebar";
import parse from "html-react-parser";
import { TermsData } from "../../store/action/UserFetchData";
import { connect } from "react-redux";
import Loader from "../../common/Loader";
import { useTranslation } from "react-i18next";
import Title from "../../common/Title";
import { HiOutlineArrowSmLeft } from "react-icons/hi";
const Terms = ({ dispatch, res }) => {
  const [display, setDisplay] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(TermsData());
  }, [dispatch]);
  var data = res?.data?.terms_conditions?.terms_conditions;
  
  return (
    <>
    <Title props={t("terms_condition")}/>
      <div
        className="sidebar-overlay"
        style={{ display: display ? "block" : "none" }}
      ></div>
      <div className="bm-mdl-header">
        <div id="content">
          <span id="sidebarCollapse" style={{ right: "20px" }}>
            <FaBars className="fs-3" onClick={() => setDisplay(true)} />
          </span>
        </div>
        <Link to="/terms" className="text-white">
            <HiOutlineArrowSmLeft className="fa fa-2x fa-long-arrow-left mb-2"/>
        </Link>
        <h4 className="m-0 d-inline mx-3 fw-bold">{t("terms_condition")}</h4>
      </div>
      {res?.loading ? (
        <Loader />
      ) : (
        <div className="bm-mdl-center bm-full-height pb-6">
          <div className="text-dark p-4">
            {parse(`${data}`)}
          </div>
        </div>
      )}
      <MainSidebar display={display} setDisplay={setDisplay} />
    </>
  );
};

const mapStateToProps = (state) => ({
  res: state.TermsReducer,
});

export default connect(mapStateToProps)(Terms);