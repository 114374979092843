import React, { useEffect, useState } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import Loader from "../../../../common/Loader";
import { useTranslation } from "react-i18next";

const OngoingChallange = ({
  res,
  setState,
  game_image,
  game_id,
  setSubmitData,
}) => {

  const { t } = useTranslation();

  return (
    <>
      {res?.loading ? (
        <Loader />
      ) : (
        <div>
          {Array.isArray(res) && res.length > 0 ? (
            res?.map((data) => {
              const { line1, line2 } = t("challenge_result", {
                coin: data?.coin,
                winning_price: data?.winning_price,
              });
              return (
                <div
                  key={data?.ludo_challenge_id}
                  className="bm-content-listing challenge_lists"
                >
                  <div id="ongoinglist"></div>
                  <div
                    className="card text-center br-5 hide-over mb-3 ludo_challenge_id"
                    value="13"
                  >
                    <div className="card-body">
                      <div className="row text-center text-dark">
                        <div className="col-3">
                          <img
                            src={
                              data?.profile_image === null ||
                              data?.profile_image === ""
                                ? game_image
                                : data?.profile_image
                            }
                            alt="---"
                            className="rounded-circle img-fluid mCS_img_loaded"
                          />
                          <br />
                          <span className="bm_text_blue">
                            {data?.ludo_king_username}
                          </span>
                        </div>
                        <div className="col-6 mt-3">
                          <h6 className="bm_text_blue fw-bold">
                            {data?.auto_id}
                          </h6>
                          <span className="bm_text_blue">{line1}</span>
                          <br />
                          <span className="bm_text_lightpink">{line2}</span>
                        </div>
                        <div className="col-3">
                          <img
                            src={
                              data?.accepted_profile_image === null ||
                              data?.accepted_profile_image === ""
                                ? game_image
                                : data?.accepted_profile_image
                            }
                            alt="---"
                            className="rounded-circle img-fluid mCS_img_loaded"
                          />
                          <br />
                          <span className="bm_text_blue">
                            {data?.accepted_ludo_king_username === ""
                              ? t("waiting")
                              : data?.accepted_ludo_king_username}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="challenge_detail_footer card-footer px-0 py-0">
                      <div className="row">
                        <div className="col-sm-12 py-1 btn-lightgreen text-white fw-bold">
                          {t("wait_for_roomcode")}
                        </div>
                        <div
                          className="col-sm-12 py-1 btn-lightpink"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setState(true);
                            const postData = {
                              ludo_challenge_id: data?.ludo_challenge_id,
                              game_id: game_id,
                              with_password_id: data?.with_password,
                              coin: data?.coin,
                              challenge_password: data?.challenge_password,
                            };
                            setSubmitData(postData);
                          }}
                        >
                          <a className="acceptContestModel text-white text-decoration-none">
                            {t("accept_contest")}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="bm-content-listing challenge_lists">
              <div className="col-md-12 text-center text-dark">
                <strong>{t("no_live_challenge_found")}</strong>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default OngoingChallange;
