import React from "react";
import { useNavigate } from "react-router-dom";

const SliderBanner = ({ res }) => {
  var data = res?.data;
  const navigate = useNavigate();
  const handleOpenURL = async (slider_link, slider_link_type) => {

    if (slider_link_type === "app") {
      if (slider_link === "My Profile") {
        navigate("/bmuser/profile");
      } else if (slider_link === "My Wallet") {
        navigate("/bmuser/wallet");
      } else if (slider_link === "Announcement") {
        navigate("/bmuser/announcement");
      } else if (slider_link === "Leaderboard") {
        navigate("/bmuser/leaderbord");
      } else if (slider_link === "About us") {
        navigate("/bmuser/aboutus");
      } else if (slider_link === "Customer Support") {
        navigate("/bmuser/support");
      } else if (slider_link === "Terms and Condition") {
        navigate("/bmuser/terms");
      }
    } else if (slider_link_type === "web") {
      window.open(slider_link, "_self");
    }
  };
  return (
    <div>
      <div className="col-md-12 mb-2">
        <div
          id="carousel1_indicator"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carousel1_indicator"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carousel1_indicator"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
          </div>
          <div className="carousel-inner  br-5">
            {data?.slider?.length &&
              data?.slider?.map((e, index) => {
                return (
                  <div
                    key={index}
                    className={`carousel-item ${index === 0 ? "active" : ""}`}
                  >
                    <a
                      onClick={() =>
                        handleOpenURL(e?.slider_link, e?.slider_link_type)
                      }
                      className="link-button"
                    >
                      <img
                        src={e?.slider_image}
                        className="d-block w-100 slide-img"
                        alt="banner-img"
                      />
                    </a>
                  </div>
                );
              })}
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carousel1_indicator"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">{"text_previous"}</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carousel1_indicator"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">{"text_next"}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SliderBanner;
