import React, { useState, useEffect } from "react";
import { Challenge_result_upload_data } from "../../../store/action/UserFetchData";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const LostModel = ({
  dispatch,
  res,
  setLostUser,
  LostUser,
  member_id,
  ludo_challenge_id,
  accepted_member_id,
  state,
  setChange,
  change,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const id = localStorage.getItem("member_id");
  const navigate = useNavigate();
  const postLostData = async () => {
    const sendData = {
      member_id: member_id === id ? member_id : accepted_member_id,
      result_uploded_by_flag: member_id === id ? "0" : "1",
      submit: "uploadResult",
      result_status: "1",
      ludo_challenge_id: ludo_challenge_id,
    };
    dispatch(Challenge_result_upload_data(sendData));
  };
  useEffect(() => {
    if (res?.data?.status === true) {
      setLoading(false);
      setDisabledButton(true);

      setTimeout(() => {
        res.loading = true;
        res.data = {};
        navigate(`/bmuser/play/challenges/${state?.game_id}`, {
          state: {
            game_image: state?.game_image,
            game_name: state?.game_name,
            game_id: state?.game_id,
          },
        });

        setLostUser(false);
        setChange(change);
      }, 1000);
    }
  }, [res]);
  return (
    <>
      <div
        className={`modal ${LostUser ? "show d-block" : "d-none"}`}
        id="addContestModel"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addContestModel"
        aria-modal="true"
        style={{
          background: LostUser ? "rgba(0, 0, 0, 0.6)" : "",
          position: LostUser ? "absolute" : "",
          overflowY: LostUser ? "auto" : "",
        }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header btn-green">
              <h5 className="modal-title text-uppercase">
                {t("upload_result")}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => setLostUser(false)}
              >
                ×
              </button>
            </div>
            <div className="modal-body text-black p-2">
              <form className="container profile-form my-2">
                <div className="col-12 mb-3">
                  <label>{t("upload_correct_result")}</label>
                </div>
                <div className="col-12">
                  {loading ? (
                    <button
                      type="button"
                      disabled
                      className="btn btn-submit btn-lightpink"
                    >
                      {t("loading")}
                    </button>
                  ) : (
                    <button
                      type="button"
                      name="submit"
                      disabled={disabledButton}
                      onClick={postLostData}
                      className="btn btn-submit btn-lightpink"
                    >
                      {t("ok")}
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  res: state.Challengeresultupload_Reducer,
});

export default connect(mapStateToProps)(LostModel);
