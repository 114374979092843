import React, { useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  Friend_request_data,
  Friendsdata,
} from "../../store/action/UserFetchData";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../common/Loader";
import Title from "../../common/Title";
import { useTranslation } from "react-i18next";
import { HiOutlineArrowSmLeft } from "react-icons/hi";
const Friends = ({ dispatch, res, response }) => {
  const location = useLocation();
  const id = useParams();
  const { t } = useTranslation();
  useEffect(() => {
    res.data = {};
    res.loading = true;
    dispatch(Friendsdata(id?.id));
  }, [dispatch, id?.id]);

  var data = res?.data;

  const postdata = (member) => {
    const value = {
      member_id: member,
      game_id: Number(id?.id),
    };
    dispatch(Friend_request_data(value));
  };
  useEffect(() => {
    if (response?.data?.status === true) {
      const notify = () =>
        toast.success(response?.data?.message, { autoClose: 1500 });
      notify();
      setTimeout(() => {
        response.data = {};
        response.loading = true;
      }, 1500);
    }
  }, [response]);
  return (
    <>
      <Title props={t("friends")} />
      <ToastContainer />
      <div className="bm-mdl-header pb-2">
        <Link
          to={`/bmuser/play/challenges/${id.id}`}
          state={{
            game_image: location?.state?.game_image,
            game_name: location?.state?.game_name,
            game_package: location?.state?.package_name,
            game_rules: location?.state?.game_rules,
          }}
          className="text-white"
        >
          <HiOutlineArrowSmLeft className="fa fa-2x fa-long-arrow-left mb-2" />
        </Link>
        <h4 className="m-0 d-inline mx-3 fw-bold">{t("friends")}</h4>
      </div>
      {res?.loading ? (
        <Loader />
      ) : (
        <div className="bm-mdl-center bm-full-height">
          <div className="bm-content-listing">
            {data?.member_list?.length ? (
              data?.member_list?.map((data, index) => {
                return (
                  <div key={index} className="card br-5 hide-over mb-3 ">
                    <div className="card-body text-dark">
                      <div className="row justify-content-center d-flex text-center">
                        <div className="col-sm-7 col-12 mt-auto mt-sm-1 d-block d-sm-flex justify-content-center justify-content-sm-start">
                          <img
                            src={
                              data?.profile_image
                                ? data?.profile_image
                                : location?.state?.game_image
                            }
                            alt="---"
                            className="rounded-circle img-fluid mCS_img_loaded frd-img"
                          />

                          <p className="frd-p"> {data?.user_name}</p>
                        </div>

                        <div className="col-sm-5 col-12 mt-2 mt-sm-1">
                          <span
                            className="btn btn-sm btn-block btn-lightpink"
                            onClick={() => postdata(data?.member_id)}
                          >
                            {t("request_to_play")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p className="text-black text-center fw-bold">No Data Found</p>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  res: state.Friends_Reducer,
  response: state.Friendsrequest_Reducer,
});

export default connect(mapStateToProps)(Friends);
