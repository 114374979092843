import React, { useEffect, useState } from "react";
import { UpdateMyProfile } from "../../store/action/UserFetchData";
import { connect } from "react-redux";
import Loader from "../../common/Loader";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const EditProfile = ({
  dispatch,
  all_country_data,
  res,
  res_update_profile,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectImage, setSelectImage] = useState(null);
  const [state1, setState1] = useState({
    formErrors: {},
  });
  const [input, setInput] = useState({});
  const [loading, setLoading] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  useEffect(() => {
    res_update_profile.loading = true;
    res_update_profile.data = {};
  }, []);

  var data = res?.data;
  useEffect(() => {
    if (res.loading === false) {
      setInput({
        first_name: res?.data?.my_profile?.first_name,
        last_name: res?.data?.my_profile?.last_name,
        country_code: res?.data?.my_profile?.country_code,
        user_name: res?.data?.my_profile?.user_name,
        email_id: res?.data?.my_profile?.email_id,
        mobile_no: res?.data?.my_profile?.mobile_no,
        gender: res?.data?.my_profile?.gender,
        profile_image: res?.data?.my_profile?.profile_image,
        dob: res?.data?.my_profile?.dob,
      });
    }
  }, [res]);
  const handleInput = (event) => {
    let name = event.target.name;
    let value;
    if (name === "profile_image") {
      value = event.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        setSelectImage(reader.result);
      };
      reader.readAsDataURL(value);
    } else {
      value = event.target.value;
    }
    let formErrors = { ...state1.formErrors };
    formErrors[name] = "";
    setState1({ formErrors: formErrors });
    setInput((values) => ({ ...values, [name]: value }));
  };
  function handleFormValidation() {
    const { first_name, last_name, mobile_no, country_code, gender, email_id } =
      input;

    //email validation
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    const isValid = emailRegex.test(email_id);
    let formErrors = {};
    let formIsValid = true;
    if (!first_name) {
      formIsValid = false;
      formErrors["first_name"] = t("translation1:err_first_name");
    }
    if (!last_name) {
      formIsValid = false;
      formErrors["last_name"] = t("translation1:err_last_name");
    }
    if (!mobile_no) {
      formIsValid = false;
      formErrors["mobile_no"] = t("translation1:err_mobile_no");
    }
    if (mobile_no && mobile_no.length < 7) {
      formIsValid = false;
      formErrors["mobile_no"] = t("translation1:err_mobile_no_min_7");
    }
    if (mobile_no && mobile_no.length > 14) {
      formIsValid = false;
      formErrors["mobile_no"] = t("translation1:err_mobile_no_max_15");
    }
    if (!country_code) {
      formIsValid = false;
      formErrors["country_code"] = t("translation1:err_country_code");
    }
    if (!gender) {
      formIsValid = false;
      formErrors["gender"] = t("translation1:err_gender");
    }
    if (!email_id) {
      formIsValid = false;
      formErrors["email_id"] = t("translation1:err_email");
    }
    if (email_id && isValid === false) {
      formIsValid = false;
      formErrors["email_id"] = t("translation1:err_valid_email");
    }
    setState1({ formErrors: formErrors });
    return formIsValid;
  }
  const handleSubmit = (event) => {
    const formData = new FormData();
    formData.append("member_id", data?.my_profile?.member_id);
    formData.append("member_id", data?.my_profile?.member_id);
    formData.append("first_name", input?.first_name);
    formData.append("last_name", input?.last_name);
    formData.append("user_name", input?.user_name);
    formData.append("email_id", input?.email_id);
    formData.append("country_code", input?.country_code);
    formData.append("mobile_no", input?.mobile_no);
    formData.append("gender", input?.gender ? input?.gender : "");
    formData.append("dob", input?.dob);
    formData.append("profile_image", input?.profile_image);

    formData.append("submit", "save");
    const isFormValid = handleFormValidation();
    if (!isFormValid) {
      return;
    }
    setLoading(true);
    dispatch(UpdateMyProfile(formData));
  };

  useEffect(() => {
    if (res_update_profile?.data?.status === false) {
      const notify = () =>
        toast.error(res_update_profile?.data?.message, { autoClose: 1500 });
      notify();
      setLoading(false);

      setTimeout(() => {
        res_update_profile.loading = true;
        res_update_profile.data = {};
      }, 1500);
    } else if (res_update_profile?.data?.status === true) {
      const notify = () =>
        toast.success(res_update_profile?.data?.message, { autoClose: 1500 });
      notify();
      setLoading(false);
      setDisabledButton(true);
      setTimeout(() => {
        res_update_profile.loading = true;
        res_update_profile.data = {};
        res.data = {};
        res.loading = true;
        navigate("/bmuser/account");
      }, 1500);
    } else {
    }
  }, [res_update_profile, navigate]);

  return (
    <>
      <ToastContainer />
      {res.loading || all_country_data.loading ? (
        <Loader />
      ) : (
        <div className="bg-white rounded-bottom shadow-sm border border-light-10 rounded mb-2">
          <h6
            className="alert alert-secondary text-center fw-bold"
            style={{ padding: "13px 8px" }}
          >
            {t("edit_profile")}
          </h6>
          <form className="container profile-form mt-2">
            <div className="mb-3 row">
              <div className="col-6">
                <label>{t("f_name")}</label>
                <input
                  type="text"
                  id="first_name"
                  name="first_name"
                  className="form-control border-bottom rounded-0"
                  defaultValue={input?.first_name}
                  onChange={handleInput}
                />
                <label
                  htmlFor="first_name"
                  className="text-danger"
                  style={{
                    marginTop: "5px",
                    display: state1.formErrors.first_name ? "block" : "none",
                  }}
                >
                  {state1.formErrors.first_name}
                </label>
              </div>
              <div className="col-6">
                <label>{t("l_name")}</label>
                <input
                  type="text"
                  id="last_name"
                  name="last_name"
                  className="form-control border-bottom rounded-0"
                  defaultValue={input?.last_name}
                  onChange={handleInput}
                />
                <label
                  htmlFor="last_name"
                  className="text-danger"
                  style={{
                    marginTop: "5px",
                    display: state1.formErrors.last_name ? "block" : "none",
                  }}
                >
                  {state1.formErrors.last_name}
                </label>
              </div>
            </div>
            <div className="mb-3 row">
              <div className="col-12">
                <label>{t("user_name")}</label>
                <input
                  type="text"
                  id="user_name"
                  name="user_name"
                  className="form-control border-bottom rounded-0"
                  disabled
                  defaultValue={input?.user_name}
                  onChange={handleInput}
                />
              </div>
            </div>
            <div className="mb-3 row">
              <div className="col-12">
                <label>{t("email_address")}</label>
                <input
                  type="text"
                  id="email_id"
                  name="email_id"
                  className="form-control border-bottom rounded-0"
                  disabled
                  defaultValue={input?.email_id}
                  onChange={handleInput}
                />
                <label
                  htmlFor="email_id"
                  className="text-danger"
                  style={{
                    marginTop: "5px",
                    display: state1.formErrors.email_id ? "block" : "none",
                  }}
                >
                  {state1.formErrors.email_id}
                </label>
              </div>
            </div>
            <div className="mb-3 row">
              <div className="col-5 col-sm-4">
                <label>{t("country_code")}</label>
                <select
                  className="form-control border-bottom rounded-0"
                  name="country_code"
                  defaultValue={input?.country_code}
                  onChange={handleInput}
                >
                  <option value="" disabled>
                    {"Select.."}
                  </option>
                  {all_country_data?.data?.all_country?.map(
                    (country, index) => {
                      return (
                        <option key={index} value={country?.p_code}>
                          {country?.p_code || ""}
                        </option>
                      );
                    }
                  )}
                </select>
                <label
                  htmlFor="country_code"
                  className="text-danger"
                  style={{
                    marginTop: "5px",
                    display: state1.formErrors.country_code ? "block" : "none",
                  }}
                >
                  {state1.formErrors.country_code}
                </label>
              </div>
              <div className="col-7 col-sm-8">
                <label>{t("mobile_no")}.</label>
                <input
                  type="number"
                  id="mobile_no"
                  name="mobile_no"
                  className="form-control border-bottom rounded-0"
                  defaultValue={input?.mobile_no}
                  onChange={handleInput}
                  min={0}
                />
                <label
                  htmlFor="mobile_no"
                  className="text-danger"
                  style={{
                    marginTop: "5px",
                    display: state1.formErrors.mobile_no ? "block" : "none",
                  }}
                >
                  {state1.formErrors.mobile_no}
                </label>
              </div>
            </div>
            <div className="mb-3 row">
              <div className="col-12">
                <label>{t("dob")}</label>
                <input
                  type="date"
                  id="dob"
                  name="dob"
                  className="form-control border-bottom rounded-0"
                  defaultValue={input?.dob}
                  onChange={handleInput}
                />
              </div>
            </div>
            <div className=" row">
              <div className="col-4 col-sm-2 pt-1">
                <label>{t("gender")}</label>
              </div>
              <div className="col-8">
                <input
                  type="radio"
                  name="gender"
                  id="male"
                  value="0"
                  onChange={handleInput}
                  checked={input?.gender === "0"}
                />
                <label className="align-top pt-1" htmlFor="male">
                  &nbsp;&nbsp;{t("male")}&nbsp;&nbsp;
                </label>
                <input
                  type="radio"
                  name="gender"
                  id="female"
                  value="1"
                  onChange={handleInput}
                  checked={input?.gender === "1"}
                />
                <label className="align-top pt-1" htmlFor="female">
                  &nbsp;&nbsp;{t("female")}&nbsp;&nbsp;
                </label>
              </div>
              <label
                htmlFor="gender"
                className="text-danger"
                style={{
                  marginTop: "5px",
                  display: state1.formErrors.gender ? "block" : "none",
                }}
              >
                {state1.formErrors.gender}
              </label>
            </div>
            <div className="mb-3 row">
              <div className="col-12">
                <label>{"Profile Picture"}</label>
                <input
                  type="file"
                  accept="image/*"
                  name="profile_image"
                  className="d-block"
                  onChange={handleInput}
                />

                {
                  input?.profile_image !== "" 
                  && (
                  <img
                    className="mt-3 rounded"
                    width={"83px"}
                    src={
                      selectImage !== null ? selectImage : input?.profile_image
                    }
                    alt="img"
                  />
                )}
                {/* {(!input?.profile_image ||
                  input?.profile_image !== "" ||
                  selectImage !== null) && (
                  <img
                    className="mt-3 rounded"
                    width={"83px"}
                    src={
                      selectImage !== null ? selectImage : input?.profile_image
                    }
                    alt="img"
                  />
                )} */}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-12">
                {loading ? (
                  <button
                    type="button"
                    disabled
                    className="btn btn-block btn-green w-100"
                  >
                    {t("loading")}
                  </button>
                ) : (
                  <button
                    type="button"
                    disabled={disabledButton}
                    className="btn btn-block btn-green w-100"
                    onClick={handleSubmit}
                  >
                    {t("update_button")}
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  res_update_profile: state?.UpdateMyProfileDataReducer,
});

export default connect(mapStateToProps)(EditProfile);
