import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";
import MainSidebar from "../../common/MainSidebar";
import { connect } from "react-redux";
import {Youtube_data } from "../../store/action/UserFetchData";
import Title from "../../common/Title";
import { useTranslation } from "react-i18next";
import { HiOutlineArrowSmLeft } from "react-icons/hi";
const AppTutorial = ({ dispatch, res }) => {
  const [display, setDisplay] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(Youtube_data());
  }, [dispatch]);

  var data = res?.data?.youtube_links;

  return (
    <>
    <Title props={t("app_tutorial")}/>
      <div
        className="sidebar-overlay"
        style={{ display: display ? "block" : "none" }}
      ></div>
      <div className="bm-mdl-header">
        <div id="content">
          <span id="sidebarCollapse" style={{ right: "20px" }}>
            <FaBars className="fs-3" onClick={() => setDisplay(true)} />
          </span>
        </div>
        <Link to="/bmuser/account" className="text-white">
          <HiOutlineArrowSmLeft className="fa fa-2x fa-long-arrow-left mb-2"/>
        </Link>
        <h4 className="m-0 d-inline mx-3 fw-bold">{t("app_tutorial")}</h4>
      </div>
      <div className="bm-mdl-center bm-full-height">
        <div
          className="bm-content-listing text-black"
          style={{ height: "170%" }}
          >
          <h6>Demo</h6>
          
          {data?.length
            ? data?.map((e) => {
                return (
                  <div>
                    {" "}
                    <iframe
                      src={e?.youtube_link}
                      frameborder="0"
                      allow="autoplay; encrypted-media"
                      allowfullscreen
                      title="video"
                      width="100%"
                      height="250px"
                    />
                    <a
                      target="_blank"
                      href={e?.youtube_link}
                      className="btn btn-block btn-green mt-2 w-100"
                    >
                      {t("play_in_youtube")}
                    </a>
                  </div>
                );
              })
            : ""}
        </div>
      </div>
      <MainSidebar display={display} setDisplay={setDisplay} />
    </>
  );
};

const mapStateToProps = (state) => ({
  res: state.YoutubeLink_Reducer,
});

export default connect(mapStateToProps)(AppTutorial);