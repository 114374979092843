import React, { useEffect, useRef } from "react";
import { IoClose } from "react-icons/io5";
import { Link, useLocation } from "react-router-dom";
import hello from "../assets/image/hello.jpg";
import { connect } from "react-redux";
import { GetConfigData } from "../store/action/UserFetchData";
import { useTranslation } from "react-i18next";
import { IoGameController } from "react-icons/io5";
import { IoPersonCircle } from "react-icons/io5";
import { IoHeadset } from "react-icons/io5";
import { FaFileAlt } from "react-icons/fa";
import { IoWalletSharp } from "react-icons/io5";
import { IoGiftSharp } from "react-icons/io5";
const MainSidebar = ({ dispatch, display, setDisplay, res_config }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const user_name = localStorage.getItem("user_name");
  const sidebarRef = useRef(null);  
  var configData = res_config;
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setDisplay(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setDisplay]);


  

  return (
    <>
      <nav
        id="sidebar_menu"
        className={display ? "active" : ""}
        ref={sidebarRef}
      >
        <div className="sidebar_menu_header">
          <div className="sidebar_menu_header_top">
            <h5 className="mb-0 text-white fw-bold">
              {configData?.data?.company_name}
            </h5>
            <div id="dismiss">
              <IoClose
                className="fs-3 text-light fw-bolder me-2"
                onClick={() => setDisplay(false)}
              />
            </div>
          </div>
          <div className="pb-6">
            <div className="sidebar_menu_header_hey p-3 d-flex">
              <h4 className="mb-0 lh-2 pt-1 mt-1 pt-sm-0">{t("hey")}</h4>
              <p className="text-truncate m-0" style={{ maxWidth: "125px" }}>
                &nbsp;&nbsp;
              </p>
              {user_name ? (
                <b>
                  <h5 className="mb-0 lh-2 fw-bold">{user_name}</h5>
                </b>
              ) : (
                <img
                  src={hello}
                  alt="logo"
                  className="mt-1"
                  style={{ width: "22px", height: "24px" }}
                ></img>
              )}
            </div>

            <ul className="navbar-nav">
              <li
                className={`nav-item text-center mb-1 ${
                  location?.pathname === "/bmuser/play" && "active"
                }`}
              >
                <Link
                  to="/bmuser/play"
                  className="nav-link f-18 d-flex p-2 ps-3 align-items-center"
                  onClick={() => setDisplay(false)}
                >
                  <div className="icon">
                    <IoGameController className=" d-block me-2 fa-lg" />
                  </div>
                  <p>{t("play")}</p>
                </Link>
              </li>
              {user_name && (
                <>
                  <li
                    className={`nav-item text-center mb-1 ${
                      location?.pathname === "/bmuser/wallet" && "active"
                    }`}
                  >
                    <Link
                      to="/bmuser/wallet"
                      className="nav-link f-18 d-flex p-2 ps-3 align-items-center"
                      onClick={() => setDisplay(false)}
                    >
                      <div className="icon">
                        <IoWalletSharp className="d-block me-2 fa-lg" />
                      </div>
                      <p>{t("wallet")}</p>
                    </Link>
                  </li>
                  <li
                    className={`nav-item text-center mb-1 ${
                      location?.pathname === "/bmuser/account" && "active"
                    }`}
                  >
                    <Link
                      to="/bmuser/account"
                      className="nav-link f-18 d-flex p-2 ps-3 align-items-center"
                      onClick={() => setDisplay(false)}
                    >
                      <div className="icon">
                        <IoPersonCircle className=" d-block me-2 fa-lg" />
                      </div>
                      <p>{t("account")}</p>
                    </Link>
                  </li>
                  <li
                    className={`nav-item text-center mb-1 ${
                      location?.pathname === "/bmuser/referrals" && "active"
                    }`}
                  >
                    <Link
                      to="/bmuser/referrals"
                      className="nav-link f-18 d-flex p-2 ps-3 align-items-center"
                      onClick={() => setDisplay(false)}
                    >
                      <div className="icon">
                        <IoGiftSharp className=" d-block me-2 fa-lg" />
                      </div>
                      <p>{t("refer_earn")}</p>
                    </Link>
                  </li>
                </>
              )}
              {!user_name && (
                <li
                  className={`nav-item text-center mb-1 ${
                    location?.pathname === "/register" && "active"
                  }`}
                >
                  <Link
                    to="/register"
                    className="nav-link f-18 d-flex p-2 ps-3 align-items-center"
                    onClick={() => setDisplay(false)}
                  >
                    <div className="icon">
                      <IoPersonCircle
                        className=" me-2 fs-lg fs-4"
                        aria-hidden="true"
                      />
                    </div>
                    <p>{t("register")}</p>
                  </Link>
                </li>
              )}
              <li
                className={`nav-item text-center mb-1 ${
                  location?.pathname === "/bmuser/support" && "active"
                }`}
              >
                <Link
                  to="/bmuser/support"
                  className="nav-link f-18 d-flex p-2 ps-3 align-items-center"
                  onClick={() => setDisplay(false)}
                >
                  <div className="icon">
                    <IoHeadset className=" d-block me-2 fa-lg" />
                  </div>
                  <p>{t("support")}</p>
                </Link>
              </li>
              <li
                className={`nav-item text-center mb-1 ${
                  location?.pathname === "/terms" && "active"
                }`}
              >
                <Link
                  to="/terms"
                  className="nav-link f-18 d-flex p-2 ps-3 align-items-center"
                  onClick={() => setDisplay(false)}
                >
                  <div className="icon">
                    <FaFileAlt className=" d-block me-2 fa-lg" />
                  </div>
                  <p>{t("legal_terms")}</p>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

const mapStateToProps = (state) => ({
  res_config: state.ConfigReducer,
});

export default connect(mapStateToProps)(MainSidebar);