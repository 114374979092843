import React,{useState} from "react";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import MainSidebar from "../../common/MainSidebar";
import { useTranslation } from "react-i18next";
import Title from "../../common/Title";

const TermsMainPage = () => {
  const [display, setDisplay] = useState(false);
  const { t } = useTranslation();
  const Acc = [
    {
      link: "/bmuser/aboutus",
      icon: "fa fa-info-circle",
      text: "about_us",
    },
    {
      link: "/bmuser/terms",
      icon: "fa fa-file-text-o",
      text: "terms_condition",
    },
  ];

  return (
    <>
    <Title props={t("terms")}/>
      <div
        className="sidebar-overlay"
        style={{ display: display ? "block" : "none" }}
      ></div>
      <div className="bm-mdl-header">
        <div id="content">
          <span id="sidebarCollapse" style={{ right: "20px" }}>
            <FaBars className="fs-3" onClick={() => setDisplay(true)} />
          </span>
        </div>
        <h4 className="m-0 d-inline">{t("me")}</h4>
      </div>
      <div className="bm-content-listing">
        <div className="bm-account-listing mb-5">
          <ul>
            {Acc?.map((data, index) => (
              <li key={index}>
                <Link to={data?.link}>
                  <span className="icon">
                    <i className={data?.icon}></i>
                  </span>
                  <span className="text f-20">
                    {t(data?.text)}
                    <i className="fa fa-angle-right"></i>
                  </span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <MainSidebar display={display} setDisplay={setDisplay} />
    </>
  );
};

export default TermsMainPage;