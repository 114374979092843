import React, { useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { LudoLeaderBoarddata } from "../../store/action/UserFetchData";
import { connect } from "react-redux";
import Loader from "../../common/Loader";
import { useTranslation } from "react-i18next";
import Title from "../../common/Title";
import { HiOutlineArrowSmLeft } from "react-icons/hi";
import coin from "../../assets/image/coin.png";
const LudoLeaderBoard = ({ dispatch, res }) => {
  const id = useParams();
  const location = useLocation();
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(LudoLeaderBoarddata(id?.id));
  }, [dispatch, id?.id]);

  var data = res?.data;

  return (
    <>
      <Title props={t("leader_board")} />
      <div className="bm-mdl-header pb-2">
        <Link
          to={`/bmuser/play/challenges/${id.id}`}
          state={{
            game_image: location?.state?.game_image,
            game_name: location?.state?.game_name,
            game_package: location?.state?.package_name,
            game_rules: location?.state?.game_rules,
          }}
          className="text-white"
        >
          <HiOutlineArrowSmLeft className="fa fa-2x fa-long-arrow-left mb-2" />
        </Link>
        <h4 className="m-0 d-inline mx-3 fw-bold">{t("leader_board")}</h4>
      </div>
      {res?.loading ? (
        <Loader />
      ) : (
        <div className="bm-mdl-center bm-full-height">
          <div className="bm-content-listing">
            {data?.list?.length ? (
              data?.list?.map((data, index) => {
                return (
                  <div key={index} className="card br-5 hide-over mb-3 ">
                    <div className="card-body text-dark">
                      <div className="row">
                        <div className="col-sm-2 col-3">
                          <img
                            src={
                              data?.profile_image
                                ? data?.profile_image
                                : location?.state?.game_image
                            }
                            alt="---"
                            className="rounded-circle img-fluid mCS_img_loaded"
                          />
                        </div>
                        <div className="col-sm-10 col-9">
                          <p>
                            {t("name")}: {data?.first_name} {data?.last_name}{" "}
                          </p>
                          <p>
                            {t("won")}:{" "}
                            <img src={coin} alt="img-coin" className="coin" />
                            {data?.total_amount}
                          </p>
                          <p>
                            {t("won")}:{data?.total_challenge} {t("challenges")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p className="text-black text-center fw-bold">No Data Found</p>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  res: state.Ludoleaderboard_Reducer,
});

export default connect(mapStateToProps)(LudoLeaderBoard);
