import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import parse from "html-react-parser";
import WinModal from "./model/WinModal";
import { All_contest_match } from "../../store/action/UserFetchData";
import { connect } from "react-redux";
import Loader from "../../common/Loader";
import ErrorModel from "./model/ErrorModel";
import LostModel from "./model/LostModel";
import UpdateRoomCode from "./model/UpdateRoomCode";
import Pusher from "pusher-js";
import { HiOutlineArrowSmLeft } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import Title from "../../common/Title";
const ChallengeDetails = ({ dispatch, res }) => {
  const id = useParams();
  const { t } = useTranslation();
  const location = useLocation();
  const [userData, setUserData] = useState([]);
  const [winUser, setWinUser] = useState(false);
  const [ErrorUser, setErrorUser] = useState(false);
  const [LostUser, setLostUser] = useState(false);
  const [roomCode, setRoomCode] = useState(false);
  const [change, setChange] = useState();
  const [socket, setSocket] = useState();
  useEffect(() => {
    dispatch(All_contest_match(location?.state?.game_id));
  }, [dispatch, change]);
  var data = res?.data?.challenge_list;
  if (res.loading === false) {
    var data1 = data?.filter((data) => {
      return data?.ludo_challenge_id === parseInt(id?.id);
    });
  }
  useEffect(() => {
    if (res?.loading === false) {
      setUserData(data1[0]);
    }
  }, [res, data1]);

  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    });
    const channel = pusher.subscribe(process.env.REACT_APP_PUSHER_SUBSRCIBE);
    setSocket(channel);
  }, []);

  const member_id = localStorage.getItem("member_id");

  useEffect(() => {
    if (socket) {
      socket.bind("update-roomcode", async function (data) {
        const jsonData = await JSON.parse(data);
        const newObject = jsonData?.challenge_list[0];
        const updateData1 = newObject || data1;
        setUserData(updateData1);
      });
    }
  }, [userData]);
  return (
    <>
      <Title props={`${userData?.auto_id}`} />
      <div className="bm-mdl-header ">
        <Link
          to={`/bmuser/play/challenges/${location?.state?.game_id}`}
          state={{
            game_image: location?.state?.game_image,
            game_name: location?.state?.game_name,
            game_package: location?.state?.game_package,
            game_rules: location?.state?.game_rules,
          }}
          className="text-white"
        >
          <HiOutlineArrowSmLeft className="fa fa-2x fa-long-arrow-left mb-2" />
        </Link>
        <h4 className="m-0 d-inline ms-3 fw-bold">
          {location?.state?.game_name}
        </h4>
      </div>
      {res?.loading ? (
        <Loader />
      ) : (
        <>
          <div className="bm-mdl-center bm-full-height">
            <div className="bm-content-listing">
              <div className="card br-5 hide-over mb-3 text-center">
                <div className="card-body text-dark">
                  <div className="row">
                    <div className="col-sm-3">
                      <img
                        src={
                          userData?.profile_image
                            ? userData?.profile_image
                            : location?.state?.game_image
                        }
                        alt="---"
                        className="rounded-circle img-fluid mCS_img_loaded challenge-img"
                      />

                      <br />
                      <span>{userData?.ludo_king_username}</span>
                    </div>
                    <div className="col-sm-6 mt-3">
                      <h6>{userData?.auto_id}</h6>
                      <span>VS</span>
                    </div>
                    <div className="col-sm-3">
                      <img
                        src={
                          userData?.accepted_profile_image
                            ? userData?.accepted_profile_image
                            : location?.state?.game_image
                        }
                        alt="---"
                        className="rounded-circle img-fluid mCS_img_loaded challenge-img"
                      />
                      <br />
                      <span>{userData?.accepted_ludo_king_username}</span>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-sm-4 mb-1 mb-sm-auto">
                      <span className="outline-lightpink">
                        {t("challenge_coin")} :{userData?.coin}
                      </span>
                    </div>
                    <div className="col-sm-4 mb-1 mb-sm-auto">
                      <span className="outline-lightpink">
                        {t("winning_coin")} : {userData?.winning_price}
                      </span>
                    </div>
                    <div className="col-sm-4 mb-1 mb-sm-auto">
                      {location?.state?.member_id ===
                      Number(location?.state?.id) ? (
                        userData?.room_code === "" ? (
                          <span className="outline-lightpink">
                            {t("upload_room_code")}
                          </span>
                        ) : (
                          <span className="outline-lightpink">
                            {t("room_code")} : {userData?.room_code}
                          </span>
                        )
                      ) : userData?.room_code === "" ? (
                        <span className="outline-lightpink">
                          {t("wait_for_roomcode")}
                        </span>
                      ) : (
                        <span className="outline-lightpink">
                          {t("room_code")} : {userData?.room_code}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {location?.state?.member_id === Number(location?.state?.id) ? (
                <div className="row mb-3">
                  <div className="col-6 text-center">
                    <span
                      className="btn btn-block btn-green updateRoomCodeModel w-100"
                      style={{ cursor: "pointer" }}
                      onClick={() => setRoomCode(true)}
                    >
                      {t("update_roomcode")}
                    </span>
                  </div>
                  <div className="col-6 text-center">
                    <Link
                      to={`/bmuser/play/challengeChat/${id.id}`}
                      state={{
                        value: location?.state,
                        data: userData,
                      }}
                      className="btn btn-block btn-green w-100 update-chat"
                    >
                      {t("chat")}
                    </Link>
                  </div>
                </div>
              ) : location?.state?.member_id !== location?.state?.id &&
                userData?.room_code !== "" &&
                location?.state?.game_package === "com.ludo.king" ? (
                <div className="row mb-3">
                  <div className="col-6 text-center">
                    <a
                      href={`https://lk.gggred.com/?rmc=${userData?.room_code}.${location?.state?.game_package}`}
                      className="btn btn-block btn-green updateRoomCodeModel w-100"
                      style={{ cursor: "pointer" }}
                      target="_blank"
                    >
                      {t("join_game")}
                    </a>
                  </div>
                  <div className="col-6 text-center">
                    <Link
                      to={`/bmuser/play/challengeChat/${id.id}`}
                      state={{
                        value: location?.state,
                        data: userData,
                      }}
                      className="btn btn-block btn-green w-100"
                    >
                      {t("chat")}
                    </Link>
                  </div>
                </div>
              ) : (
                <div className="row mb-3">
                  <div className="col-12 text-center">
                    <Link
                      to={`/bmuser/play/challengeChat/${id.id}`}
                      state={{
                        value: location?.state,
                        data: userData,
                      }}
                      className="btn btn-block btn-green w-100"
                    >
                      {t("chat")}
                    </Link>
                  </div>
                </div>
              )}
              <div className="card br-5 hide-over mb-3 b-dark">
                <div className="card-body text-dark">
                  <div className="row">
                    <div className="col-12">
                      {parse(t("challenge_description"))}{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {winUser && (
            <WinModal
              winUser={winUser}
              setWinUser={setWinUser}
              member_id={userData?.member_id}
              ludo_challenge_id={userData?.ludo_challenge_id}
              accepted_member_id={userData?.accepted_member_id}
              state={{
                game_image: location?.state?.game_image,
                game_name: location?.state?.game_name,
                game_id: location?.state?.game_id,
              }}
              setChange={setChange}
              change={change}
            />
          )}
          {LostUser && (
            <LostModel
              setLostUser={setLostUser}
              LostUser={LostUser}
              member_id={userData?.member_id}
              ludo_challenge_id={userData?.ludo_challenge_id}
              accepted_member_id={userData?.accepted_member_id}
              state={{
                game_image: location?.state?.game_image,
                game_name: location?.state?.game_name,
                game_id: location?.state?.game_id,
              }}
              setChange={setChange}
              change={change}
            />
          )}
          {ErrorUser && (
            <ErrorModel
              setErrorUser={setErrorUser}
              ErrorUser={ErrorUser}
              member_id={userData?.member_id}
              ludo_challenge_id={userData?.ludo_challenge_id}
              accepted_member_id={userData?.accepted_member_id}
              state={{
                game_image: location?.state?.game_image,
                game_name: location?.state?.game_name,
                game_id: location?.state?.game_id,
              }}
              setChange={setChange}
              change={change}
            />
          )}
          {roomCode && (
            <UpdateRoomCode
              setRoomCode={setRoomCode}
              roomCode={roomCode}
              setChange={setChange}
              change={change}
              ludo_challenge_id={userData?.ludo_challenge_id}
              token_id={
                userData?.member_id === member_id
                  ? userData?.accepted_player_id
                  : userData?.player_id
              }
            />
          )}

          {userData?.room_code === "" ? (
            ""
          ) : (userData?.added_result === "0" &&
              userData?.accepted_result === "0") ||
            (userData?.added_result === "0" &&
              userData?.accepted_result === "2") ||
            (userData?.added_result === "2" &&
              userData?.accepted_result === "0") ||
            (userData?.added_result === "2" &&
              userData?.accepted_result === "2") ? (
            <div className="bm-mdl-footer text-white">
              <nav className="navbar navbar-expand">
                <ul className="navbar-nav">
                  <li className="nav-item text-center">
                    <span className="nav-link fw-bold text-white">
                      {t("review_by_team")}
                    </span>
                  </li>
                </ul>
              </nav>
            </div>
          ) : (userData?.member_id === Number(location?.state?.id) &&
              userData?.added_result !== null) ||
            (userData?.accepted_member_id === Number(location?.state?.id) &&
              userData?.accepted_result !== null) ? (
            <div className="bm-mdl-footer text-white">
              <nav className="navbar navbar-expand">
                <ul className="navbar-nav">
                  <li className="nav-item text-center">
                    <span className="nav-link fw-bold text-white">
                      {t("wait_for_result")}
                    </span>
                  </li>
                </ul>
              </nav>
            </div>
          ) : (
            <div className="bm-mdl-footer text-white">
              <nav className="navbar navbar-expand">
                <ul className="navbar-nav">
                  <li className="nav-item text-center">
                    <a
                      className="nav-link addWinModel"
                      
                      style={{ cursor: "pointer" }}
                      onClick={() => setWinUser(true)}
                    >
                      <i className="fa fa-trophy d-block"></i>
                      {t("won")}
                    </a>
                  </li>
                  <li className="nav-item text-center">
                    <a
                      className="nav-link addLostModel"
                      
                      style={{ cursor: "pointer" }}
                      onClick={() => setLostUser(true)}
                    >
                      <i className="fa fa-frown-o d-block"></i>
                      {t("lost")}
                    </a>
                  </li>
                  <li className="nav-item text-center">
                    <a
                      role="button"
                      className="nav-link addErrorModel"
                      style={{ cursor: "pointer" }}
                      onClick={() => setErrorUser(true)}
                    >
                      <i className="fa fa-flag d-block"></i>
                      {t("error")}
                    </a>
                  </li>
                  <li className="nav-item text-center">
                    <a
                      
                      className="nav-link"
                      onClick={(e) => {
                        e.preventDefault();
                        window.location.reload();
                      }}
                    >
                      <i className="fa fa-refresh d-block"></i>
                      {t("refresh")}
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  res: state.AllcontestmatchReducer,
});

export default connect(mapStateToProps)(ChallengeDetails);
