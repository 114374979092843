import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ChangePasswordHangle } from "../../store/action/UserFetchData";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ChangePassword = ({ dispatch, res_change_password }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [input, setInput] = useState({});
  const [loading, setLoading] = useState(false);
  const id = localStorage.getItem("member_id");
  const [state1, setState1] = useState({
    formErrors: {},
  });
  const [disabledButton, setDisabledButton] = useState(false);
  useEffect(() => {
    res_change_password.loading = true;
    res_change_password.data = {};
  }, []);
  const handleInput = (event) => {
    var name = event.target.name;
    var value = event.target.value;
    let formErrors = { ...state1.formErrors };
    formErrors[name] = "";
    setState1({ formErrors: formErrors });
    setInput((values) => ({ ...values, [name]: value }));
  };
  function handleFormValidation() {
    const { oldpass, newpass, confpass } = input;
    let formErrors = {};
    let formIsValid = true;
    if (!oldpass) {
      formIsValid = false;
      formErrors["oldpass"] = t("translation1:err_old_password");
    }
    if (!newpass) {
      formIsValid = false;
      formErrors["newpass"] = t("translation1:err_new_password");
    }
    if (!confpass) {
      formIsValid = false;
      formErrors["confpass"] = t("translation1:err_confirm_password");
    }
    if (newpass && newpass.length < 6) {
      formIsValid = false;
      formErrors["newpass"] = t("translation1:err_password_length");
    }
    if (confpass && confpass.length < 6) {
      formIsValid = false;
      formErrors["confpass"] = t("translation1:err_password_length");
    }
    if (
      newpass &&
      confpass &&
      confpass.length >= 6 &&
      newpass.length >= 6 &&
      newpass !== confpass
    ) {
      formIsValid = false;
      formErrors["confpass"] = t("translation1:err_password_match");
    }
    setState1({ formErrors: formErrors });
    return formIsValid;
  }
  const handleChangePassword = (event) => {
    event.preventDefault();
    const ChangePassFormData = new FormData();
    ChangePassFormData.append("member_id", id);
    ChangePassFormData.append("oldpass", input?.oldpass);
    ChangePassFormData.append("newpass", input?.newpass);
    ChangePassFormData.append("confpass", input?.confpass);
    ChangePassFormData.append("submit", "reset");
    const isFormValid = handleFormValidation();
    if (!isFormValid) {
      return;
    }
    setLoading(true);
    dispatch(ChangePasswordHangle(ChangePassFormData));
  };

  useEffect(() => {
    if (res_change_password?.data?.status === false) {
      const notify = () =>
        toast.error(res_change_password?.data?.message, { autoClose: 1500 });
      notify();
      setLoading(false);
      setTimeout(() => {
        res_change_password.loading = true;
        res_change_password.data = {};
      }, 1500);
    } else if (res_change_password?.data?.status === true) {
      const notify = () =>
        toast.success(res_change_password?.data?.message, { autoClose: 1500 });
      setLoading(false);
      setDisabledButton(true)
      notify();
      setTimeout(() => {
        navigate("/bmuser/account");
        res_change_password.loading = true;
        res_change_password.data = {};
      }, 1500);
    } else {
    }
  }, [res_change_password, navigate]);

  return (
    <>
      <ToastContainer />
      <div className="bg-white rounded-bottom shadow-sm border border-light-10 rounded mt-3">
        <h6
          className="alert alert-secondary text-center fw-bold"
          style={{ padding: "13px 8px" }}
        >
          {t("change_password")}
        </h6>
        <form className="container profile-form mt-2" >
          <div className="mb-2 row">
            <div className="col-12">
              <label>{t("old_password")}</label>
              <input
                type="password"
                id="oldpass"
                name="oldpass"
                className="form-control border-bottom rounded-0"
                onChange={handleInput}
              />
              <label
                htmlFor="oldpass"
                className="text-danger"
                style={{
                  marginTop: "5px",
                  display: state1.formErrors.oldpass ? "block" : "none",
                }}
              >
                {state1.formErrors.oldpass}
              </label>
            </div>
          </div>
          <div className="mb-2 row" style={{ position: "relative" }}>
            <div className="col-12">
              <label>{t("new_password")}</label>
              <input
                type="password"
                id="newpass"
                name="newpass"
                className="form-control border-bottom rounded-0"
                onChange={handleInput}
              />
              <label
                htmlFor="newpass"
                className="text-danger"
                style={{
                  marginTop: "5px",
                  display: state1.formErrors.newpass ? "block" : "none",
                }}
              >
                {state1.formErrors.newpass}
              </label>
            </div>
          </div>
          <div className="mb-2 row">
            <div className="col-12">
              <label>{t("c_password")}</label>
              <input
                type="password"
                id="confpass"
                name="confpass"
                className="form-control border-bottom rounded-0"
                onChange={handleInput}
              />
              <label
                htmlFor="confpass"
                className="text-danger"
                style={{
                  marginTop: "5px",
                  display: state1.formErrors.confpass ? "block" : "none",
                }}
              >
                {state1.formErrors.confpass}
              </label>
            </div>
          </div>
          <p className="error text-center"></p>
          <div className="form-group row">
            <div className="col-12">
              {loading ? (
                <button
                  type="button"
                  disabled
                  className="btn btn-block btn-green w-100"
                >
                  {t("loading")}
                </button>
              ) : (
                <button
                  type="button"
                  name="kyc_submit"
                  disabled={disabledButton}
                  className="btn btn-block btn-green w-100"
                  onClick={handleChangePassword}
                >
                  {t("reset_now")}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  res_change_password: state?.ChangePasswordReducer,
});

export default connect(mapStateToProps)(ChangePassword);
