import Home from "../Main/Home";
import AboutUs from "../Main/other/AboutUs";
import CustomerSupport from "../Main/other/CustomerSupport";
import Terms from "../Main/other/Terms";
import TermsMainPage from "../Main/other/TermsMainPage";
import Play from "../Main/play/Play";

const withoutAuth = [
    { path: "/bmuser/aboutus", component: AboutUs },
    { path: "/bmuser/support", component: CustomerSupport },
    { path: "/bmuser/terms", component: Terms },
    { path: "/terms", component: TermsMainPage },
    { path: "/", component: Home },
    { path: "/bmuser/play", component: Play },
]
export default withoutAuth;