import React from "react";
import { Link } from "react-router-dom";

const AllGames = ({ res }) => {
  const member_id = localStorage.getItem("member_id");
  var data1 = res?.data?.all_game?.filter((e) => e?.game_type === "1");

  return (
    <div className="pb-6 overflow-x-hidden">
      <div className="row px-2">
        {" "}
        {data1?.length &&
          data1?.map((img) => {
            return (
              <div key={img?.game_id} className="col-6">
                <Link
                  to={
                    member_id
                      ? `/bmuser/play/challenges/${img?.game_id}`
                      : "/login"
                  }
                  state={{
                    game_image: img?.game_image,
                    game_name: img?.game_name,
                    game_package: img?.package_name,
                    game_rules: img?.game_rules
                  }}
                  className="bm-single-href"
                >
                  <div className="bm-single-game game box-shadow  game-image">
                    <img
                      src={img?.game_image}
                      alt=""
                      width="100%"
                      className="mCS_img_loaded"
                    />
                    {img?.coming_soon === 1 && (
                      <div className="background-overlay"></div>
                    )}
                  </div>
                </Link>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default AllGames;
