import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Cancel_challenge_data,
} from "../../../../store/action/UserFetchData";
import { useTranslation } from "react-i18next";

const CancelChallenge = ({
  dispatch,
  setCancelDataErr,
  cancelDataErr,
  cancelData,
  changeState,
  setChangeState, 
}) => {
  const { t } = useTranslation();
  const postdata = () => {
    const data = {
      member_id: cancelData?.member_id,
      ludo_challenge_id: cancelData?.ludo_challenge_id,
      canceled_by_flag: cancelData?.canceled_by_flag,
      submit: "cancelChallenge",
    };
    setCancelDataErr(false);
    dispatch(Cancel_challenge_data(data));
    setChangeState(!changeState);
  };

  return (
    <div
      className={
        cancelDataErr
          ? "swal2-container swal2-center swal2-backdrop-show"
          : "d-none"
      }
      style={{ overflowY: "auto" }}
    >
      <div
        aria-labelledby="swal2-title"
        aria-describedby="swal2-content"
        className="swal2-popup swal2-modal swal2-icon-warning swal2-show d-flex"
        tabIndex="-1"
        role="dialog"
        aria-live="assertive"
        aria-modal="true"
      >
        <div className="swal2-header">
          <div className="swal2-icon swal2-warning swal2-icon-show d-flex">
            <div className="swal2-icon-content">!</div>
          </div>

          <div className="swal2-content">
            <div id="swal2-content" className="swal2-html-container d-block">
              {t("cancel_challenge_permission")}
            </div>
          </div>
        </div>
        <div className="swal2-actions">
          <button
            type="button"
            className="swal2-confirm swal2-styled"
            aria-label=""
            style={{
              display: "inline-block",
              backgroundColor: "rgb(48, 133, 214)",
            }}
            onClick={postdata}
          >
            {t("yes")}
          </button>
          <button
            type="button"
            className="swal2-cancel swal2-styled"
            aria-label=""
            style={{
              display: "inline-block",
              backgroundColor: "rgb(221, 51, 51)",
            }}
            onClick={() => setCancelDataErr(false)}
          >
            {t("cancel")}
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  res: state.Cencelchallenge_Reducer,
});

export default connect(mapStateToProps)(CancelChallenge);
