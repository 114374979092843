import React from "react";
import image from "../assets/image/maintanance.png";
const Maintanance = () => {
  return (
    <>
      <div className="position-absolute top-50 start-50 translate-middle">
        <img src={image} className="maintanance-width" alt="---"/>
      </div>
    </>
  );
};

export default Maintanance;
