import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import translationEn from "./en/translationEn.json";
import translationvalidate from "./en/translationvalidate.json";

const resources = {
  "en-GB": {
    translation: translationEn,
    translation1: translationvalidate,
  },
  en: {
    translation: translationEn,
    translation1: translationvalidate,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(Backend)
  .init({
    lng: localStorage.getItem("i18nextLng"),
    resources,
    debug: true,
    fallbackLng: "es",
    returnObjects: true,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
