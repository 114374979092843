import React, { useState } from "react";
import { connect } from "react-redux";
import { Update_roomcode_data } from "../../../store/action/UserFetchData";
import { useTranslation } from "react-i18next";

const UpdateRoomCode = ({
  dispatch,
  roomCode,
  setRoomCode,
  ludo_challenge_id,
  change,
  setChange,
}) => {
  const [uproomCode, setUpRoomCode] = useState("");
  const [roomCodeErr, setRoomCodeErr] = useState(false);
  const { t } = useTranslation();

  const handlechange = (e) => {
    let value = e.target.value;
    setRoomCodeErr(false);
    setUpRoomCode(value);
  };
  const postData = () => {
    if (uproomCode === "") {
      setRoomCodeErr(true);
    } else {
      const data = {
        ludo_challenge_id: ludo_challenge_id,
        submit: "updateRoom",
        room_code: uproomCode,
      };
      dispatch(Update_roomcode_data(data));
      setRoomCode(false);
      setChange(!change);
      setUpRoomCode("");
    }
  };

  const onKeyPressHandler = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      postData();
    } else {
    }
  };
  return (
    <>
      <div
        className={`modal ${roomCode ? "show d-block" : "d-none"}`}
        id="addContestModel"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addContestModel"
        aria-modal="true"
        style={{
          background: roomCode ? "rgba(0, 0, 0, 0.6)" : "",
          position: roomCode ? "absolute" : "",
        }}
      >
        <div className="modal-dialog" onKeyDown={onKeyPressHandler}>
          <div className="modal-content">
            <div className="modal-header btn-green">
              <h5 className="modal-title text-uppercase">
                {t("update_roomcode")}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => setRoomCode(false)}
              >
                ×
              </button>
            </div>
            <div className="modal-body text-black p-2">
              <form className="container profile-form my-2">
                <div className="col-12 mb-1">
                  <label>{t("room_code")} :</label>
                </div>
                <div className="col-12 mb-3">
                  <input
                    type="number"
                    id="roomcode"
                    name="roomcode"
                    className="form-control border-bottom rounded-0"
                    value={uproomCode}
                    onChange={handlechange}
                  />
                  <div
                    className="text-danger"
                    style={{
                      marginTop: "5px",
                      display: roomCodeErr ? "block" : "none",
                    }}
                  >
                    {t("translation1:err_roomcode")}
                  </div>
                </div>
                <div className="col-12 ">
                  <button
                    type="button"
                    name="submit"
                    className="btn btn-submit btn-lightpink"
                    onClick={postData}
                  >
                    {t("submit")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  res: state.Updateroomcode_Reducer,
});

export default connect(mapStateToProps)(UpdateRoomCode);
