import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  WithdrawWalletData,
  withdraw_method_data,
} from "../../store/action/UserFetchData";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Title from "../../common/Title";
import { HiOutlineArrowSmLeft } from "react-icons/hi";

const WithdrawWallet = ({ dispatch, res_withdraw, res_withdrawmethod }) => {
  const { t } = useTranslation();
  const user = localStorage.getItem("member_id");
  const navigate = useNavigate();
  const [state1, setState1] = useState({
    formErrors: {},
  });
  const [data1, getData1] = useState({
    member_id: user,
    pyatmnumber: "",
    amount: "",
    withdraw_method: "",
    submit: "withdraw",
  });
  const [loading, setLoading] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  useEffect(() => {
    dispatch(withdraw_method_data());
  }, [dispatch]);

  useEffect(() => {
    if (res_withdrawmethod?.data?.withdraw_method?.length === 1) {
      const method = res_withdrawmethod.data.withdraw_method[0].withdraw_method;
      getData1((prevState) => ({
        ...prevState,
        withdraw_method: method,
      }));
    }
  }, [res_withdrawmethod]);

  const change_withdraw = (e) => {
    const { name, value } = e.target;
    let formErrors = { ...state1.formErrors };
    formErrors[name] = "";
    setState1({ formErrors: formErrors });
    getData1((values) => ({ ...values, [name]: value }));
  };

  function handleFormValidation() {
    const { pyatmnumber, amount } = data1;
    const emailRegex = /[a-zA-Z0-9_]{3,}@[a-zA-Z]{3,}/;
    const isValid = emailRegex.test(pyatmnumber);
    let formErrors = {};
    let formIsValid = true;

    if (!amount) {
      formIsValid = false;
      formErrors["amount"] = t("translation1:err_enter_amount");
    }
    if (!pyatmnumber) {
      formIsValid = false;
      formErrors["pyatmnumber"] = t("translation1:err_upi_id");
    }
    if (pyatmnumber && isValid === false) {
      formIsValid = false;
      formErrors["pyatmnumber"] = t("translation1:err_valid_upi_id");
    }
    setState1({ formErrors: formErrors });
    return formIsValid;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isFormValid = handleFormValidation();
    if (!isFormValid) {
      return;
    }
    setLoading(true);
    dispatch(WithdrawWalletData(data1));
  };

  useEffect(() => {
    if (res_withdraw?.data?.status === false) {
      const notify = () =>
        toast.error(res_withdraw?.data?.message, { autoClose: 1500 });
      notify();
      setLoading(false);

      setTimeout(() => {
        res_withdraw.data = {};
        res_withdraw.loading = true;
      }, 1500);
    } else if (res_withdraw?.data?.status === true) {
      const notify = () =>
        toast.success(res_withdraw?.data?.message, { autoClose: 1500 });
      notify();
      setLoading(false);
      setDisabledButton(true);
      setTimeout(() => {
        res_withdraw.data = {};
        res_withdraw.loading = true;
        navigate("/bmuser/wallet");
      }, 1500);
    }
  }, [res_withdraw, navigate]);

  const bakcData = () => {
    navigate("/bmuser/wallet");
    res_withdraw.loading = true;
    res_withdraw.data = {};
  };

  return (
    <>
      <Title props={t("withdraw_money")} />
      <ToastContainer />
      <div className="bm-mdl-header">
        <span className="text-white" onClick={bakcData}>
          <HiOutlineArrowSmLeft className="fa fa-2x fa-long-arrow-left mb-2" />
        </span>
        <h4 className="m-0 d-inline mx-3 fw-bold">{t("withdraw_money")}</h4>
      </div>
      <div className="bm-mdl-center bm-full-height pb-6">
        <div className="bm-content-listing py-4 px-2">
          <div className="profile-content text-black ">
            <form className="container profile-form">
              <div className="form-group row">
                <div className="col-12 mt-0 mobile_no-div">
                  <label htmlFor="pyatmnumber">
                    {t("upi_id")}
                    <span className="required text-danger" aria-required="true">
                      &nbsp; *&nbsp;
                    </span>
                  </label>
                  <input
                    type="text"
                    id="pyatmnumber"
                    name="pyatmnumber"
                    className="form-control border-bottom rounded-0 profile"
                    value={data1.pyatmnumber}
                    onChange={change_withdraw}
                  />
                  <label
                    htmlFor="pyatmnumber"
                    className="text-danger"
                    style={{
                      marginTop: "5px",
                      display: state1.formErrors.pyatmnumber ? "block" : "none",
                    }}
                  >
                    {state1.formErrors.pyatmnumber}
                  </label>
                </div>
                <div className="col-12 mt-4">
                  <label htmlFor="amount">
                    {t("amount")}
                    <span className="required text-danger" aria-required="true">
                      &nbsp; *&nbsp;
                    </span>
                  </label>
                  <input
                    type="number"
                    id="amount"
                    name="amount"
                    className="form-control border-bottom rounded-0 profile"
                    value={data1.amount}
                    onChange={change_withdraw}
                  />
                  <label
                    htmlFor="amount"
                    className="text-danger"
                    style={{
                      marginTop: "5px",
                      display: state1.formErrors.amount ? "block" : "none",
                    }}
                  >
                    {state1.formErrors.amount}
                  </label>
                </div>
              </div>
              <div className="form-group row mt-3">
                <div className="col-6">
                  <label htmlFor="gender">{t("withdraw_to")} :&nbsp;</label>
                  <div className="custom-control custom-radio ml-5">
                    {res_withdrawmethod?.data?.withdraw_method?.map(
                      (data, index) => {
                        return (
                          <div className="mt-1 ms-5 d-flex" key={index}>
                            <input
                              type="radio"
                              id={`withdraw_method_${index}`}
                              name="withdraw_method"
                              value={data?.withdraw_method}
                              className="custom-control-label pt-1 ps-2"
                              checked={
                                data1.withdraw_method === data?.withdraw_method
                              }
                              onChange={change_withdraw}
                            />
                            <label
                              className="custom-control-label pt-1 ps-2"
                              htmlFor={`withdraw_method_${index}`}
                            >
                              {data?.withdraw_method}
                            </label>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-12 p-0">
                  {loading ? (
                    <button
                      type="button"
                      disabled
                      className="btn btn-block btn-green w-100"
                    >
                      {t("loading")}
                    </button>
                  ) : (
                    <button
                      type="button"
                      disabled={disabledButton}
                      name="withdraw_money"
                      onClick={handleSubmit}
                      className="btn btn-block btn-green w-100"
                    >
                      {t("withdraw_money")}
                    </button>
                  )}
                </div>
              </div>
            </form>
            <div className="text-danger my-2 text-center">
              <strong>{t("note")} : </strong>
              {t("withdraw_only_win_money")}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  res_withdrawmethod: state.Withdraw_method_Reducer,
  res_withdraw: state.WithdrawWallet_Reducer,
});

export default connect(mapStateToProps)(WithdrawWallet);
