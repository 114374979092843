import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { Add_money_data, Payment } from "../../store/action/UserFetchData";
import { toast, ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import Title from "../../common/Title";
import { HiOutlineArrowSmLeft } from "react-icons/hi";
import parse from "html-react-parser";
import Loader from "../../common/Loader";
const AddWallet = ({ dispatch, res, res_addmoney }) => {
  const id = localStorage.getItem("member_id");
  const navigate = useNavigate();
  const [payment, getPayment] = useState({
    payment_name: "Offline",
    TXN_AMOUNT: "",
    payment_image: "",
  });
  const [message, setMessage] = useState(false);
  const [payDetail, setPayDetail] = useState("");
  const [loading, setLoading] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const { t } = useTranslation();
  let name, value;
  const handleData = (e) => {
    name = e.target.name;
    if (name === "payment_image") {
      value = e.target.files[0];
      if (value.type.startsWith("image/")) {
        setMessage({ ...message, payment_image: false });
        getPayment({ ...payment, payment_image: value });
      } else {
        e.target.value = "";
        const notify = () =>
          toast.error(t("translation1:err_valid_upload_image"), {
            autoClose: 1500,
          });
        notify();
      }
    } else {
      value = e.target.value;
    }
    if (name === "TXN_AMOUNT") {
      setMessage({ ...message, TXN_AMOUNT: false });
      getPayment({ ...payment, TXN_AMOUNT: value });
    } else {
      getPayment({
        ...payment,
        [name]: value,
      });
      setMessage(false);
    }
  };
  useEffect(() => {
    res_addmoney.loading = true;
    res_addmoney.data = {};
    res.loading = true;
    res.data = {};
    dispatch(Payment());
  }, [dispatch]);
  const offline = async () => {
    const formdata = new FormData();
    formdata.append("CUST_ID", id);
    formdata.append("TXN_AMOUNT", payment.TXN_AMOUNT);
    formdata.append("payment_name", "Offline");
    formdata.append("result_image", payment?.payment_image);
    if (payment?.TXN_AMOUNT === "") {
      setMessage({ TXN_AMOUNT: true });
    } else if (payment?.payment_image === "") {
      setMessage({ payment_image: true });
    } else {
      setLoading(true);
      await dispatch(Add_money_data(formdata));
    }
  };
  useEffect(() => {
    if (res_addmoney?.data?.status === true) {
      const notify = () =>
        toast.success(res_addmoney?.data?.message, { autoClose: 1500 });
      notify();
      setLoading(false);
      setDisabledButton(true);
      setTimeout(() => {
        res_addmoney.loading = true;
        res_addmoney.data = {};
        navigate("/bmuser/wallet");
      }, 1500);
    } else if (res_addmoney?.data?.status === true) {
      const notify = () =>
        toast.error(res_addmoney?.data?.message, { autoClose: 1500 });
      notify();
      setLoading(false);
      setTimeout(() => {
        res_addmoney.loading = true;
        res_addmoney.data = {};
      }, 1500);
    }
  }, [res_addmoney]);
  useEffect(() => {
    if (!res.loading) {
      const paymentData = res?.data?.payment?.filter(
        (data) => data?.payment_name === "Offline"
      );
      setPayDetail(paymentData[0]?.payment_description);
    }
  }, [res]);

  return (
    <>
      <Title props={t("add_money")} />
      {res.loading ? (
        <Loader />
      ) : (
        <>
          <ToastContainer />
          <div className="bm-mdl-header">
            <Link to="/bmuser/wallet" className="text-white">
              <HiOutlineArrowSmLeft className="fa fa-2x fa-long-arrow-left mb-2" />
            </Link>
            <h4 className="m-0 d-inline mx-3 fw-bold">{t("add_money")}</h4>
          </div>
          <div className="bm-mdl-center bm-full-height pb-6">
            <div className="row d-flex">
              <div className="col-md-12">
                <div className="bm-content-listing">
                  <div className="profile-content text-dark">
                    <form className="container profile-form mt-2">
                      <div className="row form-group">
                        <div className="col-12 mt-4">
                          <label htmlFor="amount"> {"Amount "}</label>
                          <input
                            id="amount"
                            type="number"
                            className="form-control border-bottom rounded-0"
                            name="TXN_AMOUNT"
                            value={payment?.TXN_AMOUNT}
                            onChange={handleData}
                          />
                          <div
                            className="text-danger"
                            style={{
                              marginTop: "5px",
                              display:
                                message?.TXN_AMOUNT === true ? "block" : "none",
                            }}
                          >
                            {t("translation1:err_enter_amount")}
                          </div>
                        </div>
                      </div>
                      {payment?.TXN_AMOUNT && (
                        <div className="form-group  getcurrency_div">
                          {t("you_will_pay")}&nbsp;
                          <span className="getcurrency">
                            {payment?.TXN_AMOUNT}
                          </span>
                        </div>
                      )}
                      {/* <div className="form-group row">
                    <div className="col-6">
                      <label htmlFor="gender"> {"Add Money"} :&nbsp;</label>
                      <div className="">
                        <div className="mt-1 ms-5">
                          <input
                            id="PayTm"
                            name="payment_name"
                            type="radio"
                            className="custom-control-input mt-1"
                            checked
                            style={{ height: "auto", marginRight: "8px" }}
                            readOnly
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="PayTm"
                          >
                            {t("offline")}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div> */}

                      <div class="form-group " id="additional-fields">
                        <label class="form-group">
                          <h6 className="fw-bold">
                            Offline Payment Image Upload
                          </h6>
                        </label>
                        <input
                          class="form-group mb-0"
                          type="file"
                          id="offlineImage"
                          name="payment_image"
                          accept="image/*"
                          onChange={handleData}
                        />
                        <div
                          className="text-danger"
                          style={{
                            marginTop: "5px",
                            display:
                              message?.payment_image === true
                                ? "block"
                                : "none",
                          }}
                        >
                          {t("translation1:err_upload_image")}
                        </div>
                      </div>
                      <hr />
                      <div class="form-group payment_description">
                        <h6 className="fw-bold">Note:</h6>

                        {parse(`${payDetail}`)}
                      </div>

                      <div className="form-group row text-center">
                        {loading ? (
                          <button
                            type="button"
                            disabled
                            className="btn btn-block btn-green w-100"
                          >
                            {t("loading")}
                          </button>
                        ) : (
                          <button
                            type="button"
                            name="add_money"
                            disabled={disabledButton}
                            className="btn btn-block btn-green w-100"
                            onClick={offline}
                          >
                            {t("add_money")}
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  res: state.Payment_Reducer,
  res_addmoney: state.Add_money_Reducer,
});

export default connect(mapStateToProps)(AddWallet);
