import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import { FaHeadphones } from "react-icons/fa";
import ludoBanner from "../assets/image/homeLudo.jpg";
import logo from "../assets/image/battlemanialogo (1).png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MainSidebar from "../common/MainSidebar"
import Title from "../common/Title";
const Home = () => {
  const [display, setDisplay] = useState(false);
  const { t } = useTranslation();

  
  return (
    <>
    <Title props={t("home")}/>
      <div
        className="sidebar-overlay"
        style={{ display: display ? "block" : "none" }}
      ></div>
      <div className="bm-mdl-header text-center">
        <div
          id=""
          className="ml-0"
          style={{
            position: "absolute",
            top: "5%",
            right: "4%",
            cursor: "pointer",
          }}
        >
          <span id="sidebarCollapse" style={{ right: "20px" }}>
            <FaBars className="fs-3" onClick={() => setDisplay(true)} />
          </span>
        </div>
        <span className="navbar-brand mr-0">
          <img src={logo} alt="logo" width="17%" className="py-1" />
        </span>
      </div>
      <div className="bm-mdl-center bm-full-height pb-6">
        <div className="bm-content-listing text-black">
          <div className="row">
            <div className="col-md-12 text-center"></div>
            <div className="col-12">
              <img src={ludoBanner} alt="logo" className="mCS_img_loaded w-100" />
              <p
                style={{ padding: "11px 11px 84px", fontSize: "17px"}}
                className="text-center fst-italic fw-normal"
              >
                {t("home_text")}
              </p>
              <div style={{ position: "fixed", bottom: "26px" }}>
                <Link
                  className="btn btn-dark btn-lg play_button py-3"
                  to="/bmuser/play"
                >
                  {t("play_now")}
                </Link>
                <Link
                  className="btn btn-primary support_button fw-semibold py-3 fa-2x"
                  to="/bmuser/support"
                >
                  <FaHeadphones
                    className="fs-4"
                    style={{ margin: "9px 4px" }}
                  />{" "}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MainSidebar display={display} setDisplay={setDisplay} />
    </>
  );
};


export default Home
