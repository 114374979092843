import React, { useState, useEffect } from "react";
import OngoingChallange from "./ongoing/OngoingChallange";
import { Link, useLocation, useParams } from "react-router-dom";
import { connect } from "react-redux";
import {
  All_contest_match,
  All_ongoing_match,
  All_result_match,
  Dashboard,
  Get_follow_status,
  Post_follow_status_data,
} from "../../../store/action/UserFetchData";
import MyContestMatch from "./upcoming/MyContestMatch";
import AddChallenge from "./model/AddChallenge";
import MyResult from "./myresult/MyResult";
import Title from "../../../common/Title";
import { useTranslation } from "react-i18next";
import AcceptChallenge from "./model/AcceptChallenge";
import CancelChallenge from "./model/CancelChallenge";
import AcceptChallengeDetail from "./model/AcceptChallengeDetail";
import { HiOutlineArrowSmLeft } from "react-icons/hi";
import Pusher from "pusher-js";
import ShowAddmodel from "./model/ShowAddmodel";
const Challenges = ({
  dispatch,
  res,
  res_dashboard,
  res_result,
  res_ongoing,
  res_mycontest,
}) => {
  const id = useParams();
  const location = useLocation();
  const { t } = useTranslation();
  const member_id = Number(localStorage.getItem("member_id"));
  const [resOngoing, setResOngoing] = useState([]);
  const [resMyContest, setResMyContest] = useState([]);
  const [resResult, setResResult] = useState([]);
  const [changeState, setChangeState] = useState();
  const [token_id, setToken_id] = useState("");
  const [submitData, setSubmitData] = useState({});
  const [state, setState] = useState(false);
  const [userData, setUserData] = useState(false);
  const [state1, setState1] = useState(false);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [tab1, setTab1] = useState(false);
  const [tab2, setTab2] = useState(true);
  const [tab3, setTab3] = useState(false);
  const [filteredOngoing, setFilteredOngoing] = useState([]);
  const [filteredMyContest, setFilteredMyContest] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [accpetContest, setAcceptContest] = useState({
    ludo_king_username: localStorage.getItem("user_name"),
    with_password: "",
  });
  const [cancelData, setCancelData] = useState();
  const [cancelDataErr, setCancelDataErr] = useState(false);
  const [socket, setSocket] = useState();
  const [showmodel, setshowmodel] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [followStatus, setFollowStatus] = useState(false);
  useEffect(() => {
    res_result.data = {};
    res_result.loading = true;
    res_ongoing.data = {};
    res_ongoing.loading = true;
    res_mycontest.data = {};
    res_mycontest.loading = true;
  }, []);
  useEffect(() => {
    dispatch(Get_follow_status(id));
    dispatch(Dashboard());
    dispatch(All_result_match(id?.id));
    dispatch(All_contest_match(id?.id));
    dispatch(All_ongoing_match(id?.id));
  }, [dispatch]);
  useEffect(() => {
    if (res_mycontest?.loading === false) {
      setResMyContest(res_mycontest?.data?.challenge_list);
      setFilteredMyContest(res_mycontest?.data?.challenge_list);
    }
    if (res_result?.loading === false) {
      setResResult(res_result?.data?.challenge_list);
      setFilteredResults(res_result?.data?.challenge_list);
    }
    if (res_ongoing?.loading === false) {
      setResOngoing(res_ongoing?.data?.challenge_list);
      setFilteredOngoing(res_ongoing?.data?.challenge_list);
    }
  }, [res_result, res_ongoing, res_mycontest]);
  useEffect(() => {
    if (!res.loading) {
      setFollowStatus(res?.data?.is_follower);
    }
  }, [res]);
  useEffect(() => {
    const filterData = (data, query) => {
      if (!data || !Array.isArray(data)) return data;
      const lowercasedQuery = query?.toLowerCase()?.trim();
      if (!lowercasedQuery) return data;
      return data.filter((item) => {
        const autoId = item?.auto_id?.toString()?.toLowerCase();
        return autoId?.includes(lowercasedQuery);
      });
    };

    setFilteredOngoing(filterData(resOngoing, searchTerm));
    setFilteredMyContest(filterData(resMyContest, searchTerm));
    setFilteredResults(filterData(resResult, searchTerm));
  }, [resOngoing, resMyContest, resResult, searchTerm]);

  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    });
    const channel = pusher.subscribe(process.env.REACT_APP_PUSHER_SUBSRCIBE);
    setSocket(channel);
  }, []);
  useEffect(() => {
    if (res_mycontest?.data?.challenge_list?.length <= 0) {
      setTab1(true);
      setTab2(false);
      setTab3(false);
    }
  }, [res_mycontest]);

  const Postdata = async (num) => {
    setFollowStatus(num === "0" ? false : true);
    const data = {
      member_id: member_id,
      status: num,
      game_id: id?.id,
    };
    if (data) {
      setOpen1(!open1);
      dispatch(Post_follow_status_data(data));
    }
  };

  var dashboard_data = res_dashboard?.data?.member;
  var total_balance =
    dashboard_data?.join_money + dashboard_data?.wallet_balance;

  // ----------Lost Challenge-------------
  useEffect(() => {
    if (socket) {
      const handleLostChallenge = async (data) => {
        try {
          const newObject = data;

          if (newObject) {
            if (newObject.accepted_member_id === member_id) {
              setResResult((prevArray) => {
                const updatedArray = [newObject, ...prevArray];
                return updatedArray;
              });
              setResMyContest((prevArray) => {
                const updatedArray = prevArray.filter(
                  (item) =>
                    item.ludo_challenge_id !== newObject.ludo_challenge_id
                );
                return updatedArray;
              });
            } else if (newObject.member_id === member_id) {
              setResResult((prevArray) => {
                const updatedArray = [newObject, ...prevArray];
                return updatedArray;
              });
              setResMyContest((prevArray) => {
                const updatedArray = prevArray.filter(
                  (item) =>
                    item.ludo_challenge_id !== newObject.ludo_challenge_id
                );
                return updatedArray;
              });
            }
          }
        } catch (error) {
          console.error("Error processing data:", error);
        }
      };
      socket.bind("lost-challenge", handleLostChallenge);

      return () => {
        socket.unbind("lost-challenge", handleLostChallenge);
      };
    }
  }, [socket]);

  // -----------cancel challnege--------------
  useEffect(() => {
    if (socket) {
      const handleCancelChallenge = async function (data) {
        try {
          const jsonData = JSON.parse(data);
          const newObject = jsonData?.challenge_list
            ? jsonData.challenge_list[0]
            : jsonData;

          if (newObject) {
            if (
              newObject.member_id === member_id &&
              newObject.accepted_member_id === 0
            ) {
              setResMyContest((prevArray) => {
                const updatedArray = prevArray.filter(
                  (item) =>
                    item.ludo_challenge_id !== newObject.ludo_challenge_id
                );
                return updatedArray;
              });
              setResResult((prevArray) => {
                const updatedArray = [newObject, ...prevArray];
                return updatedArray;
              });
            } else if (
              newObject.member_id !== member_id &&
              newObject.accepted_member_id === 0
            ) {
              setResOngoing((prevArray) => {
                const updatedArray = prevArray.filter(
                  (item) =>
                    item.ludo_challenge_id !== newObject.ludo_challenge_id
                );
                return updatedArray;
              });
            } else if (
              newObject.member_id === member_id ||
              newObject.accepted_member_id === member_id
            ) {
              setResMyContest((prevArray) => {
                const updatedArray = prevArray.filter(
                  (item) =>
                    item.ludo_challenge_id !== newObject.ludo_challenge_id
                );
                return updatedArray;
              });
              setResResult((prevArray) => {
                const updatedArray = [newObject, ...prevArray];
                return updatedArray;
              });
            }
          }
        } catch (error) {
          console.error("Error parsing data:", error);
        }
      };
      socket.bind("cancel-challenge", handleCancelChallenge);
      return () => {
        socket.unbind("cancel-challenge", handleCancelChallenge);
      };
    }
  }, [socket]);

  // -------accept challnege----------
  useEffect(() => {
    if (socket) {
      const handleAddChallenge = async function (data) {
        try {
          const jsonData = JSON.parse(data);
          const newObject = jsonData?.challenge_list[0];
          if (newObject) {
            if (
              newObject.accepted_member_id !== member_id &&
              newObject.member_id !== member_id
            ) {
              setResOngoing((prevArray) => {
                const updatedArray = prevArray.filter(
                  (item) =>
                    item.ludo_challenge_id !== newObject.ludo_challenge_id
                );
                return updatedArray;
              });
            } else if (newObject.accepted_member_id === member_id) {
              setResOngoing((prevArray) => {
                const updatedArray = prevArray.filter(
                  (item) =>
                    item.ludo_challenge_id !== newObject.ludo_challenge_id
                );
                return updatedArray;
              });
              setResMyContest((prevArray) => [newObject, ...prevArray]);
            } else if (newObject.member_id === member_id) {
              setResMyContest((prevArray) => {
                const updatedArray = prevArray.map((item) =>
                  item.ludo_challenge_id === newObject.ludo_challenge_id
                    ? newObject
                    : item
                );
                return updatedArray;
              });
            } else {
              setResOngoing((prevArray) => [newObject, ...prevArray]);
            }
          }
        } catch (error) {
          console.error("Error parsing data:", error);
        }
      };
      socket.bind("accept-challenge", handleAddChallenge);
      return () => {
        socket.unbind("accept-challenge", handleAddChallenge);
      };
    }
  }, [socket]);

  // ---------update-roomcode------------
  useEffect(() => {
    if (socket) {
      const handleroomChallenge = async function (data) {
        try {
          const jsonData = JSON.parse(data);
          const newObject = jsonData?.challenge_list[0];
          if (newObject) {
            if (
              newObject.accepted_member_id === member_id ||
              newObject.member_id === member_id
            ) {
              setResMyContest((prevArray) => {
                const updatedArray = prevArray.map((item) =>
                  item.ludo_challenge_id === newObject.ludo_challenge_id
                    ? newObject
                    : item
                );
                return updatedArray;
              });
            }
          }
        } catch (error) {
          console.error("Error parsing data:", error);
        }
      };
      socket.bind("update-roomcode", handleroomChallenge);
      return () => {
        socket.unbind("update-roomcode", handleroomChallenge);
      };
    }
  }, [socket]);

  // ------add-challnege--------
  useEffect(() => {
    if (socket) {
      const handleAddChallenge = async function (data) {
        try {
          const jsonData = JSON.parse(data);
          const newObject = jsonData?.challenge_list[0];
          if (newObject) {
            if (newObject.member_id === member_id) {
              setResMyContest((prevArray) => [newObject, ...prevArray]);
            } else {
              setResOngoing((prevArray) => [newObject, ...prevArray]);
            }
          }
        } catch (error) {
          console.error("Error parsing data:", error);
        }
      };

      socket.bind("add-challenge", handleAddChallenge);

      return () => {
        socket.unbind("add-challenge", handleAddChallenge);
      };
    }
  }, [socket]);

  return (
    <>
      <Title props={`${location?.state?.game_name}`} />

      {showmodel && (
        <ShowAddmodel
          showmodel={showmodel}
          setshowmodel={setshowmodel}
          game_rules={location?.state?.game_rules}
        />
      )}
      <div className="bm-mdl-header">
        <Link to="/bmuser/play" className="text-white">
          <HiOutlineArrowSmLeft className="fa fa-2x fa-long-arrow-left" />
        </Link>
        <Link
          to={`/bmuser/play/notificationList/${id.id}`}
          state={{
            game_image: location?.state?.game_image,
            game_name: location?.state?.game_name,
            game_package: location?.state?.package_name,
            game_rules: location?.state?.game_rules,
          }}
          className="float-right d-inline ml-2"
        >
          <i className="fa fa-2x fa fa-bell text-white f-21"></i>
        </Link>
        <span
          id="searchIcon"
          className="float-right d-inline ml-2 "
          onClick={() => setOpen(true)}
          style={{ cursor: "pointer" }}
        >
          <i className="fa fa-2x fa fa-search text-white f-21"></i>
        </span>
        {followStatus ? (
          <span
            onClick={() => Postdata("0")}
            className="badge btn-lightpink float-right   text-light d-inline text-decoration-none"
            style={{ cursor: "pointer" }}
          >
            {t("unfollow")}
          </span>
        ) : (
          <span
            onClick={() => Postdata("1")}
            className="badge btn-lightpink float-right text-light d-inline text-decoration-none"
            style={{ cursor: "pointer" }}
          >
            {t("follow")}
          </span>
        )}
        <h4 className="m-0 d-inline align-bottom  fw-bold">
          &nbsp;&nbsp;{location?.state?.game_name}
        </h4>
        <div
          className="input-group rounded col-12 mt-2"
          id="searchBarContainer"
          style={{ display: open ? "" : "none" }}
        >
          <input
            type="text"
            className="form-control rounded rounded-end-0 border-0 focus-out"
            id="search-bar"
            placeholder="Search"
            aria-label="Search"
            aria-describedby="search-addon"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <span
            className="input-group-text border-0"
            id="search-addon"
            style={{ cursor: "pointer", backgroundColor: "#fff" }}
            onClick={() => {
              setOpen(false);
              setSearchTerm("");
              document.getElementById("search-bar").value = "";
            }}
          >
            <i className="fa fa-close"></i>
          </span>
        </div>
      </div>
      <div className="bm-mdl-center bm-full-height ">
        <ul className="nav nav-tabs">
          <li className="nav-item text-center " style={{ width: "31%" }}>
            <span
              className={`nav-link text-uppercase rounded-0 fw-bold ${
                tab1 ? "active" : ""
              }`}
              style={{ cursor: "pointer" }}
              data-toggle="tab"
              onClick={() => {
                setTab1(true);
                setTab2(false);
                setTab3(false);
              }}
            >
              {t("ongoing")}
            </span>
          </li>
          <li className="nav-item text-center" style={{ width: "38%" }}>
            <span
              className={`nav-link text-uppercase rounded-0 fw-bold ${
                tab2 ? "active" : ""
              }`}
              style={{ cursor: "pointer" }}
              data-toggle="tab"
              onClick={() => {
                setTab1(false);
                setTab2(true);
                setTab3(false);
              }}
            >
              {t("my_contest")}
            </span>
          </li>
          <li className="nav-item text-center" style={{ width: "31%" }}>
            <span
              className={`nav-link text-uppercase rounded-0 fw-bold ${
                tab3 ? "active" : ""
              }`}
              style={{ cursor: "pointer" }}
              data-toggle="tab"
              onClick={() => {
                setTab1(false);
                setTab2(false);
                setTab3(true);
              }}
            >
              {t("results")}
            </span>
          </li>
        </ul>
        {res_ongoing?.data && tab1 && (
          <OngoingChallange
            res={filteredOngoing}
            setState={setState}
            game_image={location?.state?.game_image}
            game_id={id?.id}
            setSubmitData={setSubmitData}
          />
        )}
        {res_mycontest?.data && tab2 && (
          <MyContestMatch
            res={filteredMyContest}
            game_image={location?.state?.game_image}
            game_name={location?.state?.game_name}
            game_id={id?.id}
            game_package={location?.state?.game_package}
            game_rules={location?.state?.game_rules}
            setCancelDataErr={setCancelDataErr}
            setCancelData={setCancelData}
            setToken_id={setToken_id}
          />
        )}
        {res_result?.data && tab3 && (
          <MyResult
            game_image={location?.state?.game_image}
            res={filteredResults}
          />
        )}
        {state1 && (
          <AddChallenge
            state1={state1}
            setState1={setState1}
            setChangeState={setChangeState}
            changeState={changeState}
            total_balance={total_balance}
            setshowmodel={setshowmodel}
            game_rules={location?.state?.game_rules}
            game_name={location?.state?.game_name}
          />
        )}
        {userData && (
          <AcceptChallengeDetail
            setUserData={setUserData}
            userData={userData}
            accpetContest={accpetContest}
            setAcceptContest={setAcceptContest}
            submitData={submitData}
            total_balance={total_balance}
            game_name={location?.state?.game_name}
            setChangeState={setChangeState}
            changeState={changeState}
            setTab1={setTab1}
            setTab2={setTab2}
          />
        )}
        {state && (
          <AcceptChallenge
            setState={setState}
            state={state}
            setUserData={setUserData}
          />
        )}
        {cancelDataErr && (
          <CancelChallenge
            setCancelDataErr={setCancelDataErr}
            cancelDataErr={cancelDataErr}
            setCancelData={setCancelData}
            cancelData={cancelData}
            changeState={changeState}
            setChangeState={setChangeState}
            token_id={token_id}
          />
        )}
        <div className="bm-mdl-footer text-white">
          <nav className="navbar navbar-expand">
            <ul className="navbar-nav">
              <li className="nav-item text-center">
                <Link
                  to={`/bmuser/play/challengeLeaderboard/${id?.id}`}
                  state={{
                    game_image: location?.state?.game_image,
                    game_name: location?.state?.game_name,
                    game_package: location?.state?.package_name,
                    game_rules: location?.state?.game_rules,
                  }}
                  className="nav-link addWinModel"
                  style={{ cursor: "pointer" }}
                >
                  <i className="fa fa-area-chart d-block"></i>
                  {t("leader_board")}
                </Link>
              </li>
              {/* <li className="nav-item text-center">
                <a
                  role="button"
                  className="nav-link addErrorModel"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.reload();
                  }}
                >
                  <i className="fa fa-refresh d-block"></i>
                  {t("refresh")}
                </a>
              </li> */}
              <li className="nav-item text-center">
                <a
                  role="button"
                  className="nav-link addErrorModel"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setState1(true);
                  }}
                >
                  <i className="fa fa-gamepad d-block"></i>
                  {t("add_contest")}
                </a>
              </li>
              <li className="nav-item text-center">
                <Link
                  to={`/bmuser/play/budyList/${id?.id}`}
                  state={{
                    game_image: location?.state?.game_image,
                    game_name: location?.state?.game_name,
                    game_package: location?.state?.package_name,
                    game_rules: location?.state?.game_rules,
                  }}
                  className="nav-link"
                >
                  <i className="fa fa-user d-block"></i>
                  {t("friends")}
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  res: state.GetFollowStatusReducer,
  res_dashboard: state.DashboardReducer,
  response: state.Addchallenge_Reducer,
  acceptresponse: state.Acceptchallenge_Reducer,
  res_result: state.AllresultmatchReducer,
  res_ongoing: state.AllongoingmatchReducer,
  res_mycontest: state.AllcontestmatchReducer,
});

export default connect(mapStateToProps)(Challenges);
