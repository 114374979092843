import React from "react";
const Loader = () => {
  return (
    <>
      <div
        className=" d-flex justify-content-center"
        style={{ alignItems: "center" }}
      >
        <span className="loader"></span>
      </div>
    </>
  );
};

export default Loader;
