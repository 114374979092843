import React, { useState, useEffect } from "react";
import { Challenge_result_upload_data } from "../../../store/action/UserFetchData";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const WinModal = ({
  dispatch,
  res,
  winUser,
  setWinUser,
  member_id,
  ludo_challenge_id,
  accepted_member_id,
  state,
  setChange,
  change,
}) => {
  const [winUserError, setWinUserError] = useState({ Winname: false });
  const [loading, setLoading] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const { t } = useTranslation();
  const id = localStorage.getItem("member_id");
  const navigate = useNavigate();
  const handleWinChange = async (e) => {
    var name = e.target.name;
    var value = e.target.files[0];
    const base64 = await convertToBase64(value);
    if (name === "result_image") {
      setWinUserError({ ...winUserError, Winname: false });
    }
    setWinUser({ ...winUser, [name]: base64 });
  };
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        const base64String = fileReader.result;
        const cleanedBase64 = base64String.replace(/^data:image\/(png|jpeg|jpg);base64,/, '');
        resolve(cleanedBase64);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };


  const postWinData = async () => {
    if (winUser?.result_image === undefined) {
      setWinUserError({ ...winUserError, Winname: true });
    } else {
      const sendData = {
        member_id: member_id === Number(id) ? member_id : accepted_member_id,
        result_uploded_by_flag: member_id === Number(id) ? "0" : "1",
        submit: "uploadResult",
        result_status: "0",
        ludo_challenge_id: ludo_challenge_id,
        result_image: winUser?.result_image,
      };
      setLoading(true);
      console.log(sendData,"sendData");
      
      dispatch(Challenge_result_upload_data(sendData));
    }
  };
  useEffect(() => {
    if (res?.data?.status === true) {
      setLoading(false);
      setDisabledButton(true);

      setTimeout(() => {
        navigate(`/bmuser/play/challenges/${state?.game_id}`, {
          state: {
            game_image: state?.game_image,
            game_name: state?.game_name,
            game_id: state?.game_id,
          },
        });
      });
      setWinUser(false);
      setChange(change);
      setTimeout(() => {
        res.loading = true;
        res.data = {};
      }, 1000);
    }
  }, [res]);
  return (
    <>
      <ToastContainer />
      <div
        className={`modal ${winUser ? "show d-block" : "d-none"}`}
        id="addContestModel"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addContestModel"
        aria-modal="true"
        style={{
          background: winUser ? "rgba(0, 0, 0, 0.6)" : "",
          position: winUser ? "absolute" : "",
          overflowY: winUser ? "auto" : "",
        }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header btn-green">
              <h5 className="modal-title text-uppercase">
                {t("upload_result")}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => {
                  setWinUser(false);
                }}
              >
                ×
              </button>
            </div>
            <div className="modal-body text-black p-2">
              <form className="container profile-form mt-2">
                <div className="form-group row">
                  <div className="col-12 mb-3">
                    <label>{t("select_proof")}</label>
                    <input
                      type="file"
                      id="result_image"
                      name="result_image"
                     accept="image/*"
                      className="d-block"
                      onChange={handleWinChange}
                    />
                    <div
                      className="text-danger"
                      style={{
                        marginTop: "5px",
                        display:
                          winUserError.Winname === true ? "block" : "none",
                      }}
                    >
                      {t("translation1:err_select_image")}
                    </div>
                  </div>
                  <div className="col-12">
                    {loading ? (
                      <button
                        type="button"
                        disabled
                        className="btn btn-submit btn-lightpink"
                      >
                        {t("loading")}
                      </button>
                    ) : (
                      <button
                        type="button"
                        disabled={disabledButton}
                        name="submit"
                        className="btn btn-submit btn-lightpink"
                        onClick={postWinData}
                      >
                        {t("submit")}
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  res: state.Challengeresultupload_Reducer,
});

export default connect(mapStateToProps)(WinModal);
