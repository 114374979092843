import React, { useEffect, useState } from "react";
import { Accept_challenge_data } from "../../../../store/action/UserFetchData";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
const AcceptChallengeDetail = ({
  setUserData,
  dispatch,
  userData,
  submitData,
  total_balance,
  game_name,
  setAcceptContest,
  accpetContest,
  setChangeState,
  changeState,
  acceptresponse,
  setTab1,
  setTab2,
  setEmpty,
}) => {
  const { t } = useTranslation();
  const member_id = localStorage.getItem("member_id");
  const [state1, setState1] = useState({
    formErrors: {},
  });
  const [loading, setLoading] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let formErrors = { ...state1.formErrors };
    formErrors[name] = "";
    setState1({ formErrors: formErrors });
    setAcceptContest({ ...accpetContest, [name]: value });
  };
  function handleFormValidation() {
    const { ludo_king_username, with_password } = accpetContest;
    let formErrors = {};
    let formIsValid = true;
    if (!ludo_king_username) {
      formIsValid = false;
      formErrors["ludo_king_username"] = t("translation1:err_user_name");
    }
    if (ludo_king_username && submitData?.coin > total_balance) {
      formIsValid = false;
      formErrors["ludo_king_username"] = t("translation1:err_balance_low");
    }
    if (submitData?.with_password_id === "1" && with_password === "") {
      formIsValid = false;
      formErrors["with_password"] = t("translation1:err_password");
    }
    if (with_password !== submitData?.challenge_password) {
      formIsValid = false;
      formErrors["with_password"] = t("translation1:err_password_incorrect");
    }

    setState1({ formErrors: formErrors });
    return formIsValid;
  }
  const handleSubmit = async () => {
    const data = {
      challenge_password:
        submitData?.with_password_id === "1"
          ? accpetContest?.with_password
          : "",
      submit: "acceptChallenge",
      ludo_challenge_id: submitData?.ludo_challenge_id,
      ludo_king_username: accpetContest?.ludo_king_username,
      accepted_member_id: member_id,
      coin: submitData?.coin,
    };
    const isFormValid = handleFormValidation();
    if (!isFormValid) {
      return;
    }
    setLoading(true);
    await dispatch(Accept_challenge_data(data));
  };

  useEffect(() => {
    if (acceptresponse?.data?.status === true) {
      setLoading(false);
      setDisabledButton(true);
      setUserData(false);
      setChangeState(!changeState);
      setTab2(true);
      setTab1(false);
      setTimeout(() => {
        acceptresponse.data = {};
        acceptresponse.loading = true;
      }, 1500);
    }
  }, [acceptresponse]);
  return (
    <>
      <div
        className={`modal ${userData ? "show d-block" : "d-none"}`}
        id="addContestModel"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addContestModel"
        aria-modal="true"
        style={{
          background: userData ? "rgba(0, 0, 0, 0.6)" : "",
          position: userData ? "absolute" : "",
          overflowY: userData ? "auto" : "",
        }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header btn-green">
              <h5 className="modal-title text-uppercase">
                {t("accept_contest")}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => {
                  setUserData(false);
                }}
              >
                ×
              </button>
            </div>
            <div className="modal-body text-black p-2">
              <form className="container profile-form mt-2">
                <div className="form-group row">
                  <input
                    type="hidden"
                    id="game_id"
                    name="game_id"
                    className="form-control border-bottom rounded-0"
                    value="18"
                  />
                  <div className="col-12 mb-3">
                    <label>
                      {game_name} {t("user_name")}
                    </label>
                    <input
                      type="text"
                      id="ludo_king_username"
                      name="ludo_king_username"
                      className="form-control border-bottom rounded-0"
                      value={accpetContest.ludo_king_username}
                      onChange={handleChange}
                    />
                    <label
                      htmlFor="ludo_king_username"
                      className="text-danger"
                      style={{
                        marginTop: "5px",
                        display: state1.formErrors.ludo_king_username
                          ? "block"
                          : "none",
                      }}
                    >
                      {state1.formErrors.ludo_king_username}
                    </label>
                  </div>
                  {submitData?.with_password_id === "1" && (
                    <div className="col-12 mb-3">
                      <label>{t("password")}</label>
                      <input
                        type="password"
                        id="with_password"
                        name="with_password"
                        className="form-control border-bottom rounded-0"
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="with_password"
                        className="text-danger"
                        style={{
                          marginTop: "5px",
                          display: state1.formErrors.with_password
                            ? "block"
                            : "none",
                        }}
                      >
                        {state1.formErrors.with_password}
                      </label>
                    </div>
                  )}
                  <div className="col-12">
                    {loading ? (
                      <button
                        type="button"
                        name="submit"
                        className="btn btn-lightpink"
                        disabled
                      >
                        {t("submit")}
                      </button>
                    ) : (
                      <button
                        type="button"
                        name="submit"
                        className="btn btn-lightpink"
                        disabled={disabledButton}
                        onClick={handleSubmit}
                      >
                        {t("submit")}
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  acceptresponse: state.Acceptchallenge_Reducer,
});

export default connect(mapStateToProps)(AcceptChallengeDetail);
