import React, { useState, useEffect } from "react";
import { HiOutlineArrowSmLeft } from "react-icons/hi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { connect } from "react-redux";
import {
  ResetPassword,
  VerifyOtpData,
} from "../../../store/action/UserFetchData";
import { useTranslation } from "react-i18next";
import Title from "../../../common/Title";
import OtpInput from "react-otp-input";
const SentOtp = ({ dispatch, res_verify_data }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [resent, setResent] = useState(false);
  const [resent1, setResent1] = useState("");
  const [err, setErr] = useState(false);
  const { t } = useTranslation();
  const initialCountdown = localStorage.getItem("countdown");
  const [countdown, setCountdown] = useState(initialCountdown);

  useEffect(() => {
    const interval = setInterval(() => {
      if (countdown > 0) {
        setCountdown(countdown - 1);
      } else {
        clearInterval(interval);
        setResent(true);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [countdown]);

  useEffect(() => {
    localStorage.setItem("countdown", countdown);
  }, [countdown]);

  const handlechange = (otp) => {
    setErr(false);
    setResent1(otp);
  };

  const postData = async () => {
    dispatch(ResetPassword(location?.state?.email));
    setResent(false);
    localStorage.setItem("countdown", 60);
    setCountdown(60);
  };

  const otpData = async () => {
    if (resent1 === "") {
      setErr(true);
    } else {
      const setData = {
        submit: "verifyotp",
        sotp: resent1,
      };
      dispatch(VerifyOtpData(setData));
    }
  };
  useEffect(() => {
    if (res_verify_data?.data?.status === true) {
      navigate("/newpassword", {
        state: {
          member_id: location?.state?.member_id,
        },
      });
    } else if (res_verify_data?.data?.status === false) {
      const notify = () => toast.error(res_verify_data?.data?.message, { autoClose: 1500 });
      notify();

      setTimeout(() => {
        res_verify_data.data = {};
        res_verify_data.loading = true;
      }, 1500);
    }
  }, [res_verify_data]);

  return (
    <>
      <Title props={t("verify_otp")} />
      <ToastContainer />
      <div className="bm-mdl-header">
        <Link to="/login" className="text-white">
          <HiOutlineArrowSmLeft className="fs-2 me-2" />
        </Link>
        <h4 className="m-0 d-inline align-bottom fw-bold">{t("otp")}</h4>
      </div>
      <div className="bm-content-listing text-black">
        <div className="card">
          <div className="card-body">
            <label
              className="text-secondary form-label"
              style={{ fontSize: "12px" }}
            >
              {t("send_email_otp")}
            </label>
            <div className="otp-field">
              <OtpInput
                value={resent1}
                onChange={handlechange}
                numInputs={6}
                renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
              />
            </div>
            <div
              className="text-danger"
              style={{
                marginTop: "5px",
                display: err === true ? "block" : "none",
              }}
            >
              {t("translation1:err_otp")}
            </div>

            <div className="m-1 mt-2 ms-0">
              <span>
                {Math.floor(countdown / 60)}:
                {countdown % 60 < 10 ? `0${countdown % 60}` : countdown % 60}
              </span>
            </div>
            {resent ? (
              <button
                type="button"
                className="btn btn-submit btn-block btn-lg btn-lightpink w-100"
                name="login"
                onClick={postData}
              >
                {t("reset")}
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-submit btn-block btn-lg btn-lightpink w-100"
                name="login"
                onClick={otpData}
              >
                {t("verify")}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  res: state.ResetPassword_Reducer,
  res_verify_data: state.Verify_otp_data_reducer,
});

export default connect(mapStateToProps)(SentOtp);
