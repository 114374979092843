import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import Pusher from "pusher-js";
import Logo from "./components/assets/image/battlemanialogo.png";
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import homeRoute from "./components/routes/homeRoute";
import authRoute from "./components/routes/authRoute";
import { GetConfigData } from "./components/store/action/UserFetchData";
import withoutAuth from "./components/routes/withoutauthRoute";
import { getFirebaseToken } from "./components/Main/firebase/firebase";
import Maintanance from "./components/common/Maintanance";
const App = ({ dispatch, res }) => {
  const { t } = useTranslation();
  const [socket, setSocket] = useState();
  const id = localStorage.getItem("member_id");


  useEffect(() => {

    var return_response = {
      type: "CONFIG_DATA",
    };

    dispatch(return_response)
    
   
    
  }, [dispatch]);
  getFirebaseToken()
    .then((firebaseToken) => {
      if (firebaseToken) {
        console.log(firebaseToken,"firebaseToken")
        localStorage.setItem("token", firebaseToken);
      }
    })
    .catch((err) =>
     console.log ("An error occured while retrieving firebase token.", err)
    );
  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    });
    const channel = pusher.subscribe(
      process.env.REACT_APP_PUSHER_MAINTANENCE_CHANNEL
    );
    setSocket(channel);
  }, []);
  useEffect(() => {
    if (socket) {
      const handleLostChallenge = async (data) => {
        if (data) {
          window.location.reload();
        }
      };
      socket.bind("U-Maintenance", handleLostChallenge);

      return () => {
        socket.unbind("U-Maintenance", handleLostChallenge);
      };
    }
  }, [socket]);

  const Home_Route = homeRoute.map(({ path, component: Component }, key) => (
    <Route
      exact
      path={path}
      element={id ? <Component /> : <Navigate to="/login" />}
      key={key}
    />
  ));

  const Auth_Route = authRoute.map(({ path, component: Component }, key) => (
    <Route
      exact
      path={path}
      element={!id ? <Component /> : <Navigate to="/bmuser/play" />}
      key={key}
    />
  ));
  const Without_Auth_Route = withoutAuth.map(
    ({ path, component: Component }, key) => (
      <Route exact path={path} element={<Component />} key={key} />
    )
  );
  const siteurl = res?.data?.company_name;
  const link = (
    <a
      href="https://ludotournament.thebattlemania.com/"
      target="_blank"
      className="bm_text_lightgreen"
      rel="noopener noreferrer"
    >
      {siteurl}
    </a>
  );

  return (
    <>
      {!res.loading && res?.data?.under_maintenance !== "1" ? (
        <main className="bm-full-height bm-full-width">
          <div className="container-fluid">
            <div className="row d-flex">
              <div className="col-xl-4 col-left">
                <div className="bm-modal">
                  <Router basename="/ludoapp">
                    <Routes>
                      {Home_Route}
                      {Without_Auth_Route}
                      {Auth_Route}
                    </Routes>
                  </Router>
                </div>
              </div>
              <div className="col-md-6 col-right m-auto">
                <div className="bm-right-content">
                  <div className="rcBanner flex-center">
                    <picture
                      className="rcBanner-img-container"
                      style={{ height: "69px" }}
                    >
                      <img
                        src={res?.data?.logo ? res?.data?.logo : Logo}
                        alt=""
                        className="text-dark logo-image"
                      />
                    </picture>
                    <div className="rcBanner-text pt-2">
                      {t("play_ludo")}
                    </div>
                    <div className="rcBanner-footer">
                      <Trans
                        i18nKey="website_link_words"
                        values={{ siteurl }}
                        components={{ 1: link }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      ) : (
        !res.loading && <Maintanance />
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  res: state.ConfigReducer,
});

export default connect(mapStateToProps)(App);
