import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Add_challenge_data } from "../../../../store/action/UserFetchData";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
const AddChallenge = ({
  state1,
  setState1,
  dispatch,
  total_balance,
  response,
  setChangeState,
  changeState,
  game_name,
  setshowmodel,
}) => {
  const { t } = useTranslation();
  const id = useParams();
  const member_id = localStorage.getItem("member_id");

  const [loading, setLoading] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [addContest, setAddContest] = useState({
    ludo_king_username: localStorage.getItem("user_name"),
    coin: "",
    with_password: "0",
    challenge_password: "",
  });
  const [state, setState] = useState({
    formErrors: {},
  });
  const [showPassword, setShowPassword] = useState(false);
  const handleChangeAddContest = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "with_password") {
      if (value === "0") {
        setShowPassword(false);
      } else {
        setShowPassword(true);
      }
    }
    let formErrors = { ...state1.formErrors };
    formErrors[name] = "";
    setState({ formErrors: formErrors });
    setAddContest({ ...addContest, [name]: value });
  };
  function handleFormValidation() {
    const { ludo_king_username, coin, challenge_password, with_password } =
      addContest;

    let formErrors = {};
    let formIsValid = true;
    if (!ludo_king_username) {
      formIsValid = false;
      formErrors["ludo_king_username"] = t("translation1:err_user_name");
    }
    if (!coin) {
      formIsValid = false;
      formErrors["coin"] = t("translation1:err_enter_amount");
    }
    if (coin && coin > total_balance) {
      formIsValid = false;
      formErrors["coin"] = t("translation1:err_balance_low");
    }
    if (!challenge_password && with_password === "1") {
      formIsValid = false;
      formErrors["challenge_password"] = t("translation1:err_password");
    }
    if (coin && (coin < 50 || coin > 500)) {
      formIsValid = false;
      formErrors["coin"] = t("translation1:err_amount_between_50_and_500");
    }

    setState({ formErrors: formErrors });
    return formIsValid;
  }
  const handleAddContest = async () => {
    const addData = {
      member_id: member_id,
      ludo_king_username: addContest?.ludo_king_username,
      coin: addContest?.coin,
      submit: "addChallenge",
      game_id: id?.id,
      with_password: addContest?.with_password,
      challenge_password: addContest?.challenge_password,
    };
    const isFormValid = handleFormValidation();
    if (!isFormValid) {
      return;
    }
    setLoading(true);
    dispatch(Add_challenge_data(addData));
  };

  useEffect(() => {
    if (response?.data?.status === true) {
      const notify = () =>
        toast.success(response?.data?.message, { autoClose: 1500 });
      notify();
      setLoading(false);
      setDisabledButton(true);
      setState1(false);
      setChangeState(!changeState);
      setshowmodel(true);
      setTimeout(() => {
        response.data = {};
        response.loading = true;
      }, 1500);
    } else if (response?.data?.status === false) {
      const notify = () =>
        toast.error(response?.data?.message, { autoClose: 1500 });
      notify();
      setLoading(false);
      setTimeout(() => {
        response.data = {};
        response.loading = true;
      }, 1500);
    }
  }, [response]);

  return (
    <>
      <ToastContainer />
      <div
        className={`modal ${state1 ? "show d-block" : "d-none"}`}
        id="addContestModel"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addContestModel"
        aria-modal="true"
        style={{
          background: state1 ? "rgba(0, 0, 0, 0.6)" : "",
          position: state1 ? "absolute" : "",
          overflowY: state1 ? "auto" : "",
        }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header btn-green">
              <h5 className="modal-title text-uppercase">{t("add_contest")}</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => {
                  setState1(false);
                }}
              >
                ×
              </button>
            </div>
            <div className="modal-body text-black p-2">
              <form className="container profile-form mt-2">
                <div className="form-group row">
                  <input
                    type="hidden"
                    id="game_id"
                    name="game_id"
                    className="form-control border-bottom rounded-0"
                    value="18"
                  />
                  <div className="col-12 mb-3">
                    <label>
                      {game_name} {t("user_name")}
                    </label>
                    <input
                      type="text"
                      id="ludo_king_username"
                      name="ludo_king_username"
                      className="form-control border-bottom rounded-0"
                      value={addContest?.ludo_king_username}
                      onChange={handleChangeAddContest}
                    />
                    <label
                      htmlFor="ludo_king_username"
                      className="text-danger"
                      style={{
                        marginTop: "5px",
                        display: state.formErrors.ludo_king_username
                          ? "block"
                          : "none",
                      }}
                    >
                      {state.formErrors.ludo_king_username}
                    </label>
                  </div>
                  <div className="col-12 mb-3">
                    <label>{t("amount")}</label>
                    <input
                      type="number"
                      id="coin"
                      name="coin"
                      className="form-control border-bottom rounded-0"
                      min="0"
                      onChange={handleChangeAddContest}
                    />
                    <label
                      htmlFor="coin"
                      className="text-danger"
                      style={{
                        marginTop: "5px",
                        display: state.formErrors.coin ? "block" : "none",
                      }}
                    >
                      {state.formErrors.coin}
                    </label>
                  </div>
                  <div className="col-12 mb-3">
                    <label>{t("password")}</label>
                    <label className="d-block">
                      <input
                        type="radio"
                        name="with_password"
                        value="0"
                        checked={showPassword === false}
                        onChange={handleChangeAddContest}
                      />{" "}
                      <span style={{ verticalAlign: "super" }}>
                        {t("without_password")}
                      </span>
                    </label>
                    <label className="d-block">
                      <input
                        type="radio"
                        name="with_password"
                        value="1"
                        checked={showPassword === true}
                        onChange={handleChangeAddContest}
                      />{" "}
                      <span style={{ verticalAlign: "super" }}>
                        {t("with_password")}
                      </span>
                    </label>
                  </div>
                  <div
                    className="col-12 mb-3 password_field"
                    style={{
                      display: showPassword ? "block" : "none",
                    }}
                  >
                    <input
                      type="text"
                      id="challenge_password"
                      name="challenge_password"
                      className="form-control border-bottom rounded-0"
                      onChange={handleChangeAddContest}
                    />
                    <label
                      htmlFor="challenge_password"
                      className="text-danger"
                      style={{
                        marginTop: "5px",
                        display: state.formErrors.challenge_password
                          ? "block"
                          : "none",
                      }}
                    >
                      {state.formErrors.challenge_password}
                    </label>
                  </div>
                  <div className="col-12 mb-3">{t("add_contest_note")}</div>
                  <div className="col-12">
                    {loading ? (
                      <button
                        type="button"
                        name="button"
                        className="btn btn-lightpink"
                        disabled
                      >
                        {t("loading")}
                      </button>
                    ) : (
                      <button
                        type="button"
                        name="submit"
                        className="btn btn-lightpink"
                        disabled={disabledButton}
                        onClick={handleAddContest}
                      >
                        {t("submit")}
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  response: state.Addchallenge_Reducer,
});

export default connect(mapStateToProps)(AddChallenge);
