import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MainSidebar from "../../common/MainSidebar";
import { Announcement_data } from "../../store/action/UserFetchData";
import { connect } from "react-redux";
import Loader from "../../common/Loader";
import Title from "../../common/Title";
import { useTranslation } from "react-i18next";
import { HiOutlineArrowSmLeft } from "react-icons/hi";
import coin from '../../assets/image/coin.png'
const Announcement = ({ dispatch, res, DashboardAmount }) => {
  const { t } = useTranslation();
  const [display, setDisplay] = useState(false);
  useEffect(() => {
    dispatch(Announcement_data());
  }, [dispatch]);

  var data = res?.data?.announcement;
  var amount =
    DashboardAmount?.data?.member?.wallet_balance +
    DashboardAmount?.data?.member?.join_money;

  
  return (
    <>
    <Title props={t("announcement")}/>
      <div
        className="sidebar-overlay"
        style={{ display: display ? "block" : "none" }}
      ></div>
      <div className="bm-mdl-header pb-3">
        <div id="content">
          <span id="sidebarCollapse" style={{ right: "20px" }}>
            <i
              className="fa fa-bars fa-2x"
              onClick={() => setDisplay(true)}
            ></i>
          </span>
        </div>
        <Link to="/bmuser/play" className="text-white">
          <HiOutlineArrowSmLeft className="fa fa-2x fa-long-arrow-left mb-2"/>
        </Link>
        <p
          className="badge bg-light text-dark float-right f-18 d-inline"
        >
          <span>
          <img src={coin} alt="img-coin" className="coin" />
          </span>
          {amount === "" || amount === null
            ? "0.00"
            : Number(amount).toFixed(2)}
        </p>
        <h4 className="m-0 d-inline mx-2">{t("announcement")}</h4>
      </div>
      {res?.loading ? (
        <Loader />
      ) : (
        <div>
          {data?.map((data, index) => {
            return (
              <div
                className="btn btn-sm btn-white shadow m-2 d-block test_anno"
                style={{ textAlign: "left" }}
                key={index}
              >
                <div>{data?.announcement_desc}</div>
              </div>
            );
          })}
        </div>
      )}
      <MainSidebar display={display} setDisplay={setDisplay} />
    </>
  );
};

const mapStateToProps = (state) => ({
  res: state.AnnouncementReducer,
  DashboardAmount: state.DashboardReducer,
});

export default connect(mapStateToProps)(Announcement);
