import { combineReducers } from "redux";
import {
  ConfigReducer,
  AboutUsReducer,
  TermsReducer,
  CustomerSupportReducer,
  MyLeaderBoardReducer,
  AnnouncementReducer,
  ReferralReducer,
  DashboardReducer,
  TransactionReducer,
  Payment_Reducer,
  Add_money_Reducer,
  GetCountryDataReducer,
  MyProfileDataReducer,
  UpdateMyProfileDataReducer,
  ChangePasswordReducer,
  AllgameReducer,
  AllongoingmatchReducer,
  GetFollowStatusReducer,
  PostFollowStatusReducer,
  Withdraw_method_Reducer,
  AllcontestmatchReducer,
  AllresultmatchReducer,
  Notificaton_Reducer,
  Friends_Reducer,
  Friendsrequest_Reducer,
  Ludoleaderboard_Reducer,
  Challengeresultupload_Reducer,
  Addchallenge_Reducer,
  Acceptchallenge_Reducer,
  Updateroomcode_Reducer,
  Cencelchallenge_Reducer,
  YoutubeLink_Reducer,
  ResetPassword_Reducer,
  Otp_reducer,
  WithdrawWallet_Reducer,
  Slider_Reducer,
  AndroidVersion_Reducer,
  Send_Notification_Reducer,
  RegisterationReducer,
  Check_member_data_Reducer,
  LoginDataReducer,
  Verify_otp_data_reducer,
  Forgot_password_data_reducer,
  Update_mobile_number_data_reducer,
  Check_mobile_number_data_reducer,
  register_google_Reducer,
  Send_chat_Notification_data_Reducer,
  Get_payment_details_data_Reducer
} from "./reducer/FetchReducer";
const rootReducer = combineReducers({
  ConfigReducer,
  AboutUsReducer,
  TermsReducer,
  CustomerSupportReducer,
  MyLeaderBoardReducer,
  AnnouncementReducer,
  ReferralReducer,
  DashboardReducer,
  TransactionReducer,
  Payment_Reducer,
  Add_money_Reducer,
  GetCountryDataReducer,
  MyProfileDataReducer,
  UpdateMyProfileDataReducer,
  ChangePasswordReducer,
  AllgameReducer,
  AllongoingmatchReducer,
  GetFollowStatusReducer,
  PostFollowStatusReducer,
  Withdraw_method_Reducer,
  AllcontestmatchReducer,
  AllresultmatchReducer,
  Notificaton_Reducer,
  Friends_Reducer,
  Friendsrequest_Reducer,
  Ludoleaderboard_Reducer,
  Challengeresultupload_Reducer,
  Addchallenge_Reducer,
  Acceptchallenge_Reducer,
  Updateroomcode_Reducer,
  Cencelchallenge_Reducer,
  YoutubeLink_Reducer,
  ResetPassword_Reducer,
  Otp_reducer,
  WithdrawWallet_Reducer,
  Slider_Reducer,
  AndroidVersion_Reducer,
  Send_Notification_Reducer,
  RegisterationReducer,
  Check_member_data_Reducer,
  LoginDataReducer,
  Verify_otp_data_reducer,
  Forgot_password_data_reducer,
  Update_mobile_number_data_reducer,
  Check_mobile_number_data_reducer,
  register_google_Reducer,
  Send_chat_Notification_data_Reducer,
  Get_payment_details_data_Reducer
});
export default rootReducer;
