import React from 'react'
import { useTranslation } from 'react-i18next';

const ShowAddmodel = ({ setshowmodel, showmodel, game_rules }) => {
    const { t } = useTranslation();
  return (
      <div
          className={`modal ${showmodel ? "show d-block" : "d-none"}`}
          id="addContestModel"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="addContestModel"
          aria-modal="true"
          style={{
              background: showmodel ? "rgba(0, 0, 0, 0.6)" : "",
              position: showmodel ? "absolute" : "",
              overflowY: showmodel ? "auto" : "",
          }}
      >
          <div className="modal-dialog ">
              <div className="modal-content">
                  <div className="modal-header btn-green">
                      <h5 className="modal-title text-uppercase">{t("game_role")}</h5>
                      <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          onClick={() => {
                              setshowmodel(false);
                          }}
                      >
                          ×
                      </button>
                  </div>
                  <p className='text-black m-3 text-center fw-bold' dangerouslySetInnerHTML={{ __html: game_rules }}></p>
                 
              </div>
          </div>
      </div>
  )
}

export default ShowAddmodel