import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import logo from "../../assets/image/battlemanialogo.png";
import MainSidebar from "../../common/MainSidebar";
import { Link, useNavigate } from "react-router-dom";
import { Dashboard } from "../../store/action/UserFetchData";
import { connect } from "react-redux";
import Loader from "../../common/Loader";
import Matrix from "./Matrix";
import { useTranslation } from "react-i18next";
import Title from "../../common/Title";
import coin from '../../assets/image/coin.png'
const Account = ({ dispatch, res }) => {
  const [display, setDisplay] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const Acc = [
    {
      link: "/bmuser/profile",
      icon: "fa fa-user-circle",
      text: "my_profile",
    },
    {
      link: "/bmuser/announcement",
      icon: "fa fa-bullhorn",
      text: "announcement",
    },
    {
      link: "/bmuser/leaderbord",
      icon: "fa fa-area-chart",
      text: "leader_board",
    },
    {
      link: "/bmuser/apptutorial",
      icon: "fa fa-question-circle",
      text: "app_tutorial",
    },
  ];

  useEffect(() => {
    dispatch(Dashboard());
  }, [dispatch]);
  const handleLogout = () => {
    localStorage.removeItem("member_id");
    localStorage.removeItem("api_token");
    localStorage.removeItem("user_name");
    localStorage.removeItem("token");
    navigate("/login");
    window.location.reload();
  };
  var data = res.data
  var amount =
    res?.data?.member?.wallet_balance +
    res?.data?.member?.join_money;
  
  return (
    <>
    <Title props={t("account")}/>
      <div
        className="sidebar-overlay"
        style={{ display: display ? "block" : "none" }}
      ></div>
      <div className="bm-mdl-header">
        <div id="content">
          <span id="sidebarCollapse" style={{ right: "20px" }}>
            <FaBars className="fs-3" onClick={() => setDisplay(true)} />
          </span>
        </div>
        <h4 className="m-0 d-inline fw-bold">{t("me")}</h4>
      </div>
      {res?.loading ? (
        <Loader />
      ) : (
      <div className="bm-mdl-center bm-full-height">
          <div className="bm-user-info">
            <div className="row">
              <div className="col-3">
                <img
                  src={data?.member?.profile_image ? data?.member?.profile_image : logo}
                  alt="profile"
                  className="profile-img float-right img-fluid img-responsive"
                />
              </div>
              <div className="col-9 text-light my-3">
                <p className="f-16 mb-1">
                  <span className="fw-bold">{t("user_name")}</span> : {data?.member?.user_name}
                </p>
                
                <p className="f-16">
                <span className="fw-bold">{t("balance")}</span> : &nbsp;
                <img src={coin} alt="img-coin" className="coin" />
                  {Number(amount).toFixed(2)}
                </p>
              </div>
            </div>
          </div>
          <Matrix data={data}/>
          <div className="bm-content-listing">
            <div className="bm-account-listing mb-5">
              <ul>
                {Acc?.map((data, index) => (
                  <li key={index}>
                    <Link to={data?.link}>
                      <span className="icon">
                        <i className={data?.icon}></i>
                      </span>
                      <span className="text f-20">
                        {t(data?.text)}
                        <i className="fa fa-angle-right"></i>
                      </span>
                    </Link>
                  </li>
                ))}
                <li>
                <a href="#" onClick={handleLogout}>
                    <span className="icon">
                      <i className="fa fa-power-off"></i>
                    </span>
                    <span className="text f-20 border-0">
                      {t("logout")}
                      <i className="fa fa-angle-right"></i>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
      </div>
      )}
      <MainSidebar display={display} setDisplay={setDisplay} />
    </>
  );
};

const mapStateToProps = (state) => ({
  res: state.DashboardReducer,
});

export default connect(mapStateToProps)(Account);
