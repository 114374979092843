import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Challenge_result_upload_data } from "../../../store/action/UserFetchData";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const ErrorModel = ({
  dispatch,
  res,
  setErrorUser,
  ErrorUser,
  member_id,
  ludo_challenge_id,
  accepted_member_id,
  state,
  setChange,
  change,
}) => {
  const { t } = useTranslation();
  const id = localStorage.getItem("member_id");
  const navigate = useNavigate();
  const [errResult, setErrResult] = useState({
    reason: "",
    result_image: "",
  });

  const [errUser, setErrUser] = useState({
    reason_err: false,
    img_err: false,
    select: false,
  });
  const [loading, setLoading] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  useEffect(() => {
    setErrResult({ ...errResult, reason: "Teammate upload wrong result" });
  }, []);

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        const base64String = fileReader.result;
       
        const cleanedBase64 = base64String.replace(/^data:image\/(png|jpeg|jpg);base64,/, '');
        resolve(cleanedBase64);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleErrChange = async (e) => {
    let name = e.target.name;
    if (name === "reason") {
      let value = e.target.value;
      setErrUser({ ...errUser, reason_err: false });
      setErrResult({ ...errResult, reason: value });
    } else if (name === "result_image") {
      let value = e.target.files[0];
      const base64 = await convertToBase64(value);
      setErrUser({ ...errUser, img_err: false });
      setErrResult({ ...errResult, result_image: base64 });
    } else {
    }
  };

  const postErrData = async () => {
    if (
      (errResult.reason === "Other" && errUser.reason_err === false) ||
      errResult.reason === ""
    ) {
      setErrUser({ ...errUser, reason_err: true });
    } else if (
      errResult.result_image === "" ||
      errResult.result_image === undefined
    ) {
      setErrUser({ ...errUser, img_err: true });
    } else {
      const data = {
        member_id: member_id == id ? member_id : accepted_member_id,
        result_uploded_by_flag: member_id == id ? "0" : "1",
        submit: "uploadResult",
        result_status: "2",
        ludo_challenge_id: ludo_challenge_id,
        result_image: errResult?.result_image,
        reason: errResult?.reason,
      };
      setLoading(true);
      dispatch(Challenge_result_upload_data(data));
    }
  };

  useEffect(() => {
    if (res?.data?.status === true) {
      setLoading(false);
      setDisabledButton(true);

      navigate(`/bmuser/play/challenges/${state?.game_id}`, {
        state: {
          game_image: state?.game_image,
          game_name: state?.game_name,
          game_id: state?.game_id,
        },
      });
      setErrorUser(false);
      setChange(change);
      setTimeout(() => {
        res.loading = true;
        res.data = {};
      }, 1000);
    }
  }, [res]);
  return (
    <>
      <div
        className={`modal ${ErrorUser ? "show d-block" : "d-none"}`}
        id="addContestModel"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addContestModel"
        aria-modal="true"
        style={{
          background: ErrorUser ? "rgba(0, 0, 0, 0.6)" : "",
          position: ErrorUser ? "absolute" : "",
          overflowY: ErrorUser ? "auto" : "",
        }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header btn-green">
              <h5 className="modal-title text-uppercase">
                {t("upload_result")}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => {
                  setErrorUser(false);
                }}
              >
                ×
              </button>
            </div>
            <div className="modal-body text-black p-2">
              <form className="container profile-form mt-2">
                <div className="col-12 mb-3">
                  <label>{t("reason")}</label>
                  <label className="d-block">
                    <input
                      type="radio"
                      name="reason"
                      value="Teammate upload wrong result"
                      checked={
                        errResult.reason === "Teammate upload wrong result"
                      }
                      onChange={handleErrChange}
                    />{" "}
                    <span style={{ verticalAlign: "super" }}>
                      {t("upload_wrong")}
                    </span>
                  </label>
                  <label className="d-block">
                    <input
                      type="radio"
                      name="reason"
                      value="Game not start by teammate"
                      checked={
                        errResult.reason === "Game not start by teammate"
                      }
                      onChange={handleErrChange}
                    />{" "}
                    <span style={{ verticalAlign: "super" }}>
                      {t("game_not_start")}
                    </span>
                  </label>
                  <label className="d-block">
                    <input
                      type="radio"
                      name="reason"
                      value="Other"
                      checked={
                        (errResult.reason === "Other" ||
                          errResult.reason !== "Other") &&
                        errResult.reason !== "Game not start by teammate" &&
                        errResult.reason !== "Teammate upload wrong result"
                      }
                      onChange={(e) => {
                        handleErrChange(e);
                        document.getElementById("reason-feild").value = "";
                      }}
                    />{" "}
                    <span style={{ verticalAlign: "super" }}>{t("other")}</span>
                  </label>
                </div>
                <div
                  className="col-12 mb-3"
                  style={{
                    display:
                      (errResult.reason === "Other" ||
                        errResult.reason !== "Other") &&
                      errResult.reason !== "Game not start by teammate" &&
                      errResult.reason !== "Teammate upload wrong result"
                        ? "block"
                        : "none",
                  }}
                >
                  <input
                    type="text"
                    id="reason-feild"
                    name="reason"
                    className="form-control border-bottom rounded-0"
                    onChange={handleErrChange}
                  />
                  <div
                    className="text-danger"
                    style={{
                      marginTop: "5px",
                      display: errUser.reason_err === true ? "block" : "none",
                    }}
                  >
                    {t("translation1:err_proper_reason")}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-12 mb-3">
                    <label>{t("select_proof")}</label>
                    <input
                      type="file"
                      accept="image/*"
                      id="result_image"
                      name="result_image"
                      className="d-block"
                      onChange={handleErrChange}
                    />
                    <div
                      className="text-danger"
                      style={{
                        marginTop: "5px",
                        display: errUser.img_err === true ? "block" : "none",
                      }}
                    >
                      {t("translation1:err_select_image")}
                    </div>
                  </div>
                  <div className="col-12">
                    {loading ? (
                      <button
                        type="button"
                        className="btn btn-submit btn-lightpink"
                        disabled
                      >
                        {t("loading")}
                      </button>
                    ) : (
                      <button
                        type="button"
                        name="submit"
                        className="btn btn-submit btn-lightpink"
                        disabled={disabledButton}
                        onClick={postErrData}
                      >
                        {t("submit")}
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  res: state.Challengeresultupload_Reducer,
});

export default connect(mapStateToProps)(ErrorModel);
