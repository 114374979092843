import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { connect } from "react-redux";
import { GetConfigData } from "../store/action/UserFetchData";
import logo from "../assets/image/favicon.ico";
const Title = ({ props, dispatch, res }) => {
  
  useEffect(()=>{
    if(!res?.data){
      dispatch(GetConfigData());  

    }
  },[res])
  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = res?.data?.favicon ? res?.data?.favicon : logo;
  }, []);
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>
            {props} | {res?.data?.company_name ? res?.data?.company_name:"Ludo Tournaments"}
          </title>
        </Helmet>
      </HelmetProvider>
    </>
  );
};
const mapStateToProps = (state) => ({
  res: state.ConfigReducer,
});

export default connect(mapStateToProps)(Title);