import React, { useState, useEffect } from "react";
import copy from "copy-to-clipboard";
import codeImg from "../../assets/image/Refer a friend-amico.png";
import { connect } from "react-redux";
import { Dashboard } from "../../store/action/UserFetchData";
import { useTranslation } from "react-i18next";

const ReferralCode = ({ dispatch, res }) => {
  const { t } = useTranslation();
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    dispatch(Dashboard());
  }, [dispatch]);

  const data = res?.data;

  const clipBoard =async () => {
    copy(data?.member?.user_name);
    setShowTooltip(true);
    setTimeout(() => {
      setShowTooltip(false);
    }, 2000); 
  };

  return (
    <>
      <div className="sumery bg-white rounded-bottam  shadow-sm mt-2">
        <span
          className="btn-green rounded-top p-10 text-center f-18 text-uppercase"
          style={{
            cursor: "auto",
            display: "block",
            width: "inherit",
          }}
        >
          {t("your_referral_code")}
        </span>
        <div className="container row  text-black p-3  text-center m-auto position-relative">
          <img src={codeImg} alt="---" width="100%" />
          <h5 id="refer-code" onClick={clipBoard} className="p-2 copy-text">
            {data?.member?.user_name} &nbsp;
            <i className="fa fa-copy ml-3"></i>
          </h5>
          <div className={`tooltip ${showTooltip ? " show" : ""}`}>
          <span className={`tooltiptext`}>
            Copied to clipboard!
          </span>

          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  res: state.DashboardReducer,
});

export default connect(mapStateToProps)(ReferralCode);
