import React, { useEffect, useState } from "react";
import Loader from "../../../../common/Loader";
import { useTranslation } from "react-i18next";
const MyResult = ({ res,  game_image }) => {
  const { t } = useTranslation();


  return (
    <>
      {res?.loading ? (
        <Loader />
      ) : (
        <div>
          { Array.isArray(res) && res.length > 0 ? (
            res?.map((data, index) => {
              const { line1, line2 } = t("challenge_result", {
                coin: data?.coin,
                winning_price: data?.winning_price,
              });
              return (
                <div
                  key={data?.ludo_challenge_id}
                  className="bm-content-listing challenge_lists"
                >
                  <div id="ongoinglist"></div>
                  <div
                    className="card text-center br-5 hide-over mb-3 ludo_challenge_id"
                    value="13"
                  >
                    <div className="card-body">
                      <div className="row text-center text-dark">
                        <div className="col-3">
                          <img
                            src={
                              data?.profile_image === null ||
                              data?.profile_image === ""
                                ? game_image
                                : data?.profile_image
                            }
                            alt="---"
                            className="rounded-circle img-fluid mCS_img_loaded"
                          />
                          <br />
                          <span className="bm_text_blue ">
                            {data?.ludo_king_username}
                          </span>
                          <br />
                          {data?.winner_id === data?.member_id &&
                            data?.winner_id !== 0 && (
                              <span className="bm_text_lightgreen mt-1">
                                {t("winner")}
                              </span>
                            )}
                        </div>
                        <div className="col-6 mt-3">
                          <h6 className="bm_text_blue fw-bold">
                            {data?.auto_id}
                          </h6>
                          <span className="bm_text_blue">{line1}</span>
                          <br />
                          <span className="bm_text_lightpink">{line2}</span>
                        </div>
                        <div className="col-3">
                          <img
                            src={
                              data?.accepted_profile_image === null ||
                              data?.accepted_profile_image === ""
                                ? game_image
                                : data?.accepted_profile_image
                            }
                            alt="---"
                            className="rounded-circle img-fluid mCS_img_loaded"
                          />
                          <br />
                          <span className="bm_text_blue">
                            {data?.accepted_ludo_king_username
                              ? data?.accepted_ludo_king_username
                              : t("waiting")}
                          </span>
                          <br />
                          {data?.winner_id === data?.accepted_member_id &&
                            data?.winner_id !== 0 && (
                              <span className="bm_text_lightgreen mt-1 fw-bold">
                                {t("winner")}
                              </span>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="challenge_detail_footer card-footer px-0 py-0">
                      <div className="row">
                        {data?.winner_id === 0 ? (
                          <div className="col-sm-12 py-1 btn-lightpink ">
                            <a
                              className="acceptContestModel text-white text-decoration-none"
                            >
                              {t("cancelled")}
                            </a>
                          </div>
                        ) : (
                          <div className="col-sm-12 py-1 btn-lightpink ">
                            <a
                              className="acceptContestModel text-white text-decoration-none"
                            >
                              {t("completed")}
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="bm-content-listing challenge_lists">
              <div className="col-md-12 text-center text-dark">
                <strong>{t("no_complete_challenge")}</strong>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default MyResult;
