import React, { useEffect, useRef } from "react";

const ChatList = ({ chats, username }) => {
  const scrollableContainerRef = useRef(null);
  useEffect(() => {
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollTop = scrollableContainerRef.current.scrollHeight;
    }
  }, [chats]);
  
  return (
    <>
      <div ref={scrollableContainerRef} className="bm-mdl-center bm-full-height">
        {chats?.map((chat, index) => {
          return (
            <div key={index}
              className={`divRight row ${
                chat?.user === username
                  ? "justify-content-end"
                  : "justify-content-start"
              } px-4`}
            >
              <div
                className={
                  chat?.user === username ? " myChatContainer" : "chatContainer"
                }
              >
                <div className="msgAuthor text-light">{chat.text}</div>
              </div>
              <div
                className={
                  chat?.user === username
                    ? "arrowRight arrow"
                    : "arrowLeft arrow"
                }
              ></div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ChatList;
