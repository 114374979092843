import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import { HiOutlineArrowSmLeft } from "react-icons/hi";
import { Link } from "react-router-dom";
import MainSidebar from "../../common/MainSidebar";
import parse from "html-react-parser";
import { connect } from "react-redux";
import { AboutUsData } from "../../store/action/UserFetchData";
import Loader from "../../common/Loader";
import Title from "../../common/Title";
import { useTranslation } from "react-i18next";

const AboutUs = ({ dispatch, res }) => {
  const { t } = useTranslation();
  const [display, setDisplay] = useState(false);
  useEffect(() => {
    dispatch(AboutUsData());
  }, [dispatch]);

  var data = res?.data?.about_us?.aboutus;


 

  return (
    <>
      <Title props={t("about_us")} />
      <div
        className="sidebar-overlay"
        style={{ display: display ? "block" : "none" }}
      ></div>
      <div className="bm-mdl-header">
        <div id="content">
          <span id="sidebarCollapse" style={{ right: "20px" }}>
            <FaBars className="fs-3" onClick={() => setDisplay(true)} />
          </span>
        </div>
        <Link to="/terms" className="text-white">
          <HiOutlineArrowSmLeft className="fa fa-2x fa-long-arrow-left mb-2" />
        </Link>
        <h4 className="m-0 d-inline mx-3 fw-bold">{t("about_us")}</h4>
      </div>
      {res?.loading ? (
        <Loader />
      ) : (
        <div className="bm-mdl-center bm-full-height pb-6">
          <div className="text-dark p-4">{parse(`${data}`)}</div>
        </div>
      )}
      <MainSidebar display={display} setDisplay={setDisplay} />
    </>
  );
};
const mapStateToProps = (state) => ({
  res: state.AboutUsReducer,
});

export default connect(mapStateToProps)(AboutUs);
