import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlineArrowSmLeft } from "react-icons/hi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { connect } from "react-redux";
import { ForgotPasswordData } from "../../store/action/UserFetchData";
import Title from "../../common/Title";
const NewGeneratePassword = ({ dispatch, res }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [newData, setNewData] = useState({
    password: "",
    cpassword: "",
    member_id: location.state.member_id,
    submit: "forgotpass",
  });
  const [state1, setState1] = useState({
    formErrors: {},
  });
  const [loading, setLoading] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const handleInput = (event) => {
    var name = event.target.name;
    var value = event.target.value;
    let formErrors = { ...state1.formErrors };
    formErrors[name] = "";
    setState1({ formErrors: formErrors });
    setNewData((values) => ({ ...values, [name]: value }));
  };
  function handleFormValidation() {
    const { password, cpassword } = newData;

    let formErrors = {};
    let formIsValid = true;

    if (!password) {
      formIsValid = false;
      formErrors["password"] = t("translation1:err_password");
    }
    if (!cpassword) {
      formIsValid = false;
      formErrors["cpassword"] = t("translation1:err_confirm_password");
    }
    if (password && password.length < 6) {
      formIsValid = false;
      formErrors["password"] = t("translation1:err_password_length");
    }
    if (cpassword && cpassword.length < 6) {
      formIsValid = false;
      formErrors["cpassword"] = t("translation1:err_password_length");
    }
    if (
      password &&
      cpassword &&
      cpassword.length >= 6 &&
      password.length >= 6 &&
      password !== cpassword
    ) {
      formIsValid = false;
      formErrors["cpassword"] = t("translation1:err_password_match");
    }
    setState1({ formErrors: formErrors });
    return formIsValid;
  }
  const postData = async () => {
    const isFormValid = handleFormValidation();
    if (!isFormValid) {
      return; 
    }
    setLoading(true);
    dispatch(ForgotPasswordData(newData));
  };
  useEffect(() => {
    if (res?.data?.status === false) {
      const notify = () => toast.error(res?.data?.message, { autoClose: 1500 });
      notify();
      setLoading(false);
      setTimeout(() => {
        res.data = {};
        res.loading = true;
      }, 1500);
    } else if (res?.data?.status === true) {
      const notify = () =>
        toast.success(res?.data?.message, { autoClose: 1500 });
      notify();
      setLoading(false);
      setDisabledButton(true);
      setTimeout(() => {
        res.data = {};
        res.loading = true;
        navigate("/register");
      }, 1500);
    }
  }, [res]);
  return (
    <>
      <Title props={t("new_password")} />
      <ToastContainer />
      <div className="bm-mdl-header">
        <Link to="/login" className="text-white">
          <HiOutlineArrowSmLeft className="fs-2 me-2" />
        </Link>
        <h4 className="m-0 d-inline align-bottom  fw-bold">
          {t("new_password")}
        </h4>
      </div>
      <div className="bm-content-listing text-black">
        <div className="card">
          <div className="card-body">
            <form>
              <div className="form-group">
                <label for="user_name" className="form-label">
                  {t("new_password")} <span className="text-danger">*</span>
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  name="password"
                  value={newData?.password}
                  onChange={handleInput}
                />
                <label
                  htmlFor="password"
                  className="text-danger"
                  style={{
                    marginTop: "5px",
                    display: state1.formErrors.password ? "block" : "none",
                  }}
                >
                  {state1.formErrors.password}
                </label>
              </div>
              <div className="form-group">
                <label for="user_name" className="form-label">
                  {t("c_password")} <span className="text-danger">*</span>
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  name="cpassword"
                  value={newData?.cpassword}
                  onChange={handleInput}
                />
                <label
                  htmlFor="cpassword"
                  className="text-danger"
                  style={{
                    marginTop: "5px",
                    display: state1.formErrors.cpassword ? "block" : "none",
                  }}
                >
                  {state1.formErrors.cpassword}
                </label>
              </div>
              <div></div>
              {loading ? (
                <button
                  type="button"
                  className="btn btn-submit btn-block btn-lg btn-lightpink mb-3 w-100"
                  disabled
                  value={t("loading")}
                ></button>
              ) : (
                <button
                  type="button"
                  className="btn btn-submit btn-block btn-lg btn-lightpink mb-3 w-100"
                  disabled={disabledButton}
                  onClick={postData}
                  value={t("set_password")}
                ></button>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  res: state.Forgot_password_data_reducer,
});

export default connect(mapStateToProps)(NewGeneratePassword);
