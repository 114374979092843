import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { connect } from "react-redux";
import {
  CheckMobileNumberData,
  GetCountry,
  UpdateMobileNumberData,
} from "../../../store/action/UserFetchData";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../firebase/firebase";
import { useTranslation } from "react-i18next";
import RegisterOtp from "./RegisterOtp";

const SetMobileNumberModel = ({
  setGetModel,
  getModel,
  getData,
  all_country_data,
  dispatch,
  setVerifyData,
  update_mobile_data,
  check_mobile_data,
}) => {
  const [mobileData, setMobileData] = useState("");
  const [openModel, setOpenModel] = useState(false);
  const [otpErr, setOtpErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [otpErrMess, setOtpErrMess] = useState();
  const [countryData, setCountryData] = useState();
  const [state1, setState1] = useState({
    formErrors: {},
  });
  useEffect(() => {
    dispatch(GetCountry());
  }, [dispatch]);
  const { t } = useTranslation();
  useEffect(() => {
    if (all_country_data?.loading === false) {
      setCountryData(all_country_data?.data?.all_country[0]);
    }
  }, [all_country_data]);

  const handlechange = (e) => {
    let name = e.target.name;
    if (name === "country_code") {
      var data1 = all_country_data?.data?.all_country?.filter((data) => {
        return data?.country_id === Number(e.target.value);
      });
      setCountryData(data1[0]);
    } else if (name === "mobile") {
      setMobileData(e.target.value);
      let formErrors = { ...state1.formErrors };
      formErrors["mobile_no"] = "";
      setState1({ formErrors: formErrors });
    }
  };
  function handleFormValidation() {
    let formErrors = {};
    let formIsValid = true;

    if (!mobileData) {
      formIsValid = false;
      formErrors["mobile_no"] = t("translation1:err_mobile_no");
    }
    if (mobileData && mobileData.length < 7) {
      formIsValid = false;
      formErrors["mobile_no"] = t("translation1:err_mobile_no_min_7");
    }
    if (mobileData && mobileData.length > 14) {
      formIsValid = false;
      formErrors["mobile_no"] = t("translation1:err_mobile_no_max_15");
    }

    setState1({ formErrors: formErrors });
    return formIsValid;
  }
  const [sentOtp, setSentOtp] = useState("");
  const onCaptchaVerify = () => {
    if (!window.recaptchaVerifier) {
      const recaptchaContainer = document.getElementById("recaptcha-container");
      recaptchaContainer.innerHTML = "";
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            SubmitOtp();
          },
          "expired-callback": () => {},
        },
        auth
      );
    }
  };

  const SubmitOtp = () => {
    setOtpErrMess("");
    setSentOtp("");
    setOpenModel(true);
    onCaptchaVerify();
    const phoneNumber = countryData?.p_code + mobileData;
    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
      })
      .catch((error) => {
        if (error.code === "auth/too-many-requests") {
          setTimeout(SubmitOtp, 60000);
        } else {
        }
      });
  };

  const onOTPVerify = () => {
    if (sentOtp === "") {
      setOtpErr(true);
      setOtpErrMess(t("translation1:err_otp"));
    } else {
      window.confirmationResult
        .confirm(sentOtp)
        .then(async (res) => {
          setOpenModel(false);
          const resData = {
            member_id: getData?.message?.member_id,
            mobile_no: mobileData,
            country_code: countryData?.p_code,
            country_id: countryData?.country_id,
          };
          setLoading(true);
          dispatch(UpdateMobileNumberData(resData));
        })
        .catch((err) => {
          setOtpErr(true);
          setOtpErrMess(t("translation1:err_valid_otp"));
        });
    }
  };
  const handleOtpInputChange = (otp) => {
    setSentOtp(otp);
    setOtpErr(false);
    setOtpErrMess("");
  };
  const postData = async () => {
    const isFormValid = handleFormValidation();
    if (!isFormValid) {
      return;
    }
    const resData = {
      member_id: getData?.message?.member_id,
      mobile_no: mobileData,
      country_code: countryData?.p_code,
      country_id: countryData?.country_id,
    };
    if (setVerifyData?.firebase_otp === "no") {
      setLoading(true);
      dispatch(UpdateMobileNumberData(resData));
    } else {
      setLoading(true);
      dispatch(CheckMobileNumberData(mobileData));
    }
  };
  useEffect(() => {
    if (check_mobile_data?.data?.status === true) {
      setOpenModel(true);
      SubmitOtp();
      setLoading(false);
      setDisabledButton(true);
      setGetModel(false);
      setTimeout(() => {
        check_mobile_data.data = {};
        check_mobile_data.loading = true;
      }, 1500);
    } else if (check_mobile_data?.data?.status === false) {
      const notify = () =>
        toast.error(check_mobile_data?.data?.message, { autoClose: 1500 });
      notify();
      setLoading(false);
      setTimeout(() => {
        check_mobile_data.data = {};
        check_mobile_data.loading = true;
      }, 1500);
    }
  }, [check_mobile_data]);
  useEffect(() => {
    if (update_mobile_data?.data?.status === true) {
      const notify = () =>
        toast.success("Verification Successfully", { autoClose: 1500 });
      notify();
      setLoading(false);
      setDisabledButton(true);
      setGetModel(false);
      localStorage.setItem("member_id", getData?.message?.member_id);
      localStorage.setItem("api_token", getData?.message?.api_token);
      localStorage.setItem("user_name", getData?.message?.user_name);
      setTimeout(() => {
        update_mobile_data.data = {};
        update_mobile_data.loading = true;
        window.location.reload();
      }, 1500);
    } else if (update_mobile_data?.data?.status === false) {
      const notify = () =>
        toast.error(update_mobile_data?.data?.message, { autoClose: 1500 });
      notify();
      setTimeout(() => {
        update_mobile_data.data = {};
        update_mobile_data.loading = true;
      }, 1500);
    }
  }, [update_mobile_data]);
  return (
    <>
      <ToastContainer />
      <div
        className={`modal ${getModel ? "show d-block" : "d-none"}`}
        id="addContestModel"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addContestModel"
        aria-modal="true"
        style={{
          background: getModel ? "rgba(0, 0, 0, 0.6)" : "",
          position: getModel ? "absolute" : "",
          overflowY: getModel ? "auto" : "",
        }}
      >
        <div className="modal-dialog">
          <form>
            <div className="modal-content">
              <div className="modal-header btn-green ">
                <h5 className="modal-title">{t("mobile_no")}</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setGetModel(false);
                  }}
                >
                  x
                </button>
              </div>
              <div className="modal-body">
                <div className="input-group row me-0 w-auto">
                  <div className="col-5 col-sm-5 col-lg-4 pe-1">
                    <select
                      className="form-select col-3"
                      aria-label="Default select example"
                      name="country_code"
                      onChange={handlechange}
                      defaultValue={96}
                    >
                      <option value="" disabled className="text-secondary">
                        {t("select")}
                      </option>
                      {all_country_data?.data?.all_country?.map(
                        (country, index) => {
                          return (
                            <option key={index} value={country?.country_id}>
                              {country?.p_code || ""} ({country?.country_name})
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>
                  <div className="col-7 col-sm-7 col-lg-8 px-0">
                    <input
                      type="number"
                      className="form-control col-9"
                      aria-label="Text input with dropdown button"
                      name="mobile"
                      onChange={handlechange}
                    />
                  </div>
                </div>
                <label
                  htmlFor="mobile_no"
                  className="text-danger"
                  style={{
                    marginTop: "5px",
                    display: state1.formErrors.mobile_no ? "block" : "none",
                  }}
                >
                  {state1.formErrors.mobile_no}
                </label>
              </div>
              <div className="modal-footer modal-end">
                <div id="recaptcha-container"></div>
                {loading ? (
                  <button
                    type="button"
                    disabled
                    className="btn btn-submit btn-lightpink"
                  >
                    {t("loading")}
                  </button>
                ) : (
                  <button
                    type="button"
                    name="forgot"
                    disabled={disabledButton}
                    className="btn btn-submit btn-lightpink"
                    onClick={postData}
                  >
                    {t("submit")}
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
      {openModel && (
        <RegisterOtp
          openModel={openModel}
          setOpenModel={setOpenModel}
          sentOtp={sentOtp}
          handleOtpInputChange={handleOtpInputChange}
          otpErr={otpErr}
          otpErrMess={otpErrMess}
          loading={loading}
          onOTPVerify={onOTPVerify}
          SubmitOtp={SubmitOtp}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  all_country_data: state?.GetCountryDataReducer,
  update_mobile_data: state?.Update_mobile_number_data_reducer,
  check_mobile_data: state?.Check_mobile_number_data_reducer,
});

export default connect(mapStateToProps)(SetMobileNumberModel);
