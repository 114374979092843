import React, { useEffect, useState } from "react";
import { HiOutlineArrowSmLeft } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { auth } from "../firebase/firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import {
  CheckMemberData,
  GetCountry,
  RegisterationAccount,
  VersionEAndroid,
} from "../../store/action/UserFetchData";
import { connect } from "react-redux";

import { useTranslation } from "react-i18next";
import Title from "../../common/Title";
import RegisterOtp from "./model/RegisterOtp";
const Register = ({
  dispatch,
  res,
  register_data,
  country_data,
  check_member_data,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [countryData, setCountryData] = useState([]);
  const [openModel, setOpenModel] = useState(false);
  const [otpErr, setOtpErr] = useState(false);
  const [otpErrMess, setOtpErrMess] = useState();
  const [register, setRegister] = useState({
    email_id: "",
    country_code: "",
    password: "",
    player_id: "",
    submit: "register",
    user_name: "",
    mobile_no: "",
    last_name: "",
    promo_code: "",
    first_name: "",
    cpassword: "",
  });
  const [state1, setState1] = useState({
    formErrors: {},
  });

  const token = localStorage.getItem("token");
  useEffect(() => {
    if (country_data?.data?.all_country?.length) {
      setCountryData(country_data?.data?.all_country);
    } else {
      setCountryData([]);
    }
  }, [country_data]);
  useEffect(() => {
    check_member_data.data = {};
    check_member_data.loading = true;
    register_data.data = {};
    register_data.loading = true;
  }, []);
  useEffect(() => {
    dispatch(VersionEAndroid());
    dispatch(GetCountry());
  }, [dispatch]);

  var androidData = res?.data;

  let name, value;
  const handlechange = (e) => {
    name = e.target.name;
    value = e.target.value;
    let formErrors = { ...state1.formErrors };
    formErrors[name] = "";
    setState1({ formErrors: formErrors });
    setRegister({ ...register, [name]: value });
  };
  function handleFormValidation() {
    const {
      user_name,
      email_id,
      mobile_no,
      country_code,
      password,
      cpassword,
    } = register;
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    const isValid = emailRegex.test(email_id);
    let formErrors = {};
    let formIsValid = true;
    if (!user_name) {
      formIsValid = false;
      formErrors["user_name"] = t("translation1:err_user_name");
    }
    if (!country_code) {
      formIsValid = false;
      formErrors["country_code"] = t("translation1:err_country_code");
    }
    if (!email_id) {
      formIsValid = false;
      formErrors["email_id"] = t("translation1:err_email");
    }
    if (email_id && isValid === false) {
      formIsValid = false;
      formErrors["email_id"] = t("translation1:err_valid_email");
    }
    if (!mobile_no) {
      formIsValid = false;
      formErrors["mobile_no"] = t("translation1:err_mobile_no");
    }
    if (mobile_no && mobile_no.length < 7) {
      formIsValid = false;
      formErrors["mobile_no"] = t("translation1:err_mobile_no_min_7");
    }
    if (mobile_no && mobile_no.length > 14) {
      formIsValid = false;
      formErrors["mobile_no"] = t("translation1:err_mobile_no_max_15");
    }

    if (!password) {
      formIsValid = false;
      formErrors["password"] = t("translation1:err_password");
    }
    if (!cpassword) {
      formIsValid = false;
      formErrors["cpassword"] = t("translation1:err_confirm_password");
    }
    if (password && password.length < 6) {
      formIsValid = false;
      formErrors["password"] = t("translation1:err_password_length");
    }
    if (cpassword && cpassword.length < 6) {
      formIsValid = false;
      formErrors["cpassword"] = t("translation1:err_password_length");
    }
    if (
      password &&
      cpassword &&
      cpassword.length >= 6 &&
      password.length >= 6 &&
      password !== cpassword
    ) {
      formIsValid = false;
      formErrors["cpassword"] = t("translation1:err_password_match");
    }
    setState1({ formErrors: formErrors });
    return formIsValid;
  }

  const postData = async () => {
    const data = {
      email_id: register?.email_id,
      country_code: register?.country_code,
      password: register?.password,
      player_id: token,
      submit: register?.submit,
      user_name: register?.user_name,
      mobile_no: register?.mobile_no,
      last_name: register?.last_name,
      promo_code: register?.promo_code,
      first_name: register?.first_name,
      cpassword: register?.cpassword,
    };
    const isFormValid = handleFormValidation();
    if (!isFormValid) {
      return;
    }
    if (androidData?.firebase_otp === "no") {
      dispatch(RegisterationAccount(data, setLoading));
    } else {
      dispatch(CheckMemberData(data, setLoading));
    }
  };
  useEffect(() => {
    if (check_member_data?.data?.status === true) {
      SubmitOtp();
      setDisabledButton(true);
      setTimeout(() => {
        check_member_data.data = {};
        check_member_data.loading = true;
      }, 1500);
    } else if (check_member_data?.data?.status === false) {
      const notify = () =>
        toast.error(check_member_data?.data?.message, { autoClose: 1500 });
      notify();
      setTimeout(() => {
        check_member_data.data = {};
        check_member_data.loading = true;
      }, 1500);
    }
  }, [check_member_data]);
  const [sentOtp, setSentOtp] = useState("");
  const onCaptchaVerify = () => {
    if (!window.recaptchaVerifier) {
      const recaptchaContainer = document.getElementById("recaptcha-container");
      recaptchaContainer.innerHTML = "";
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            SubmitOtp();
          },
          "expired-callback": () => {},
        },
        auth
      );
    }
    setOpenModel(true);
  };

  const SubmitOtp = () => {
    setOtpErrMess("");
    setSentOtp("");
    onCaptchaVerify();
    const phoneNumber = register?.country_code + register?.mobile_no;
    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
      })
      .catch((error) => {
        if (error.code === "auth/too-many-requests") {
          setTimeout(SubmitOtp, 60000);
        } else {
        }
      });
  };
  const handleOtpInputChange = (otp) => {
    setSentOtp(otp);
    setOtpErr(false);
    setOtpErrMess("");
  };
  const onOTPVerify = () => {
    if (sentOtp === "") {
      setOtpErr(true);
      setOtpErrMess(t("translation1:err_otp"));
    } else {
      window.confirmationResult
        .confirm(sentOtp)
        .then(async (res) => {
          setOpenModel(false);
          const data = {
            email_id: register?.email_id,
            country_code: register?.country_code,
            password: register?.password,
            player_id: token,
            submit: register?.submit,
            user_name: register?.user_name,
            mobile_no: register?.mobile_no,
            last_name: register?.last_name,
            promo_code: register?.promo_code,
            first_name: register?.first_name,
            cpassword: register?.cpassword,
          };
          dispatch(RegisterationAccount(data, setLoading));
        })
        .catch((err) => {
          setOtpErr(true);
          setOtpErrMess(t("translation1:err_valid_otp"));
        });
    }
  };
  useEffect(() => {
    if (register_data?.data?.status === true) {
      const notify = () =>
        toast.success("Verification Successfully", { autoClose: 1500 });
      notify();
      setDisabledButton(true);
      setTimeout(() => {
        register_data.data = {};
        register_data.loading = true;
        navigate("/login", {
          state: {
            user_name: register?.user_name,
            password: register?.password,
          },
        });
      }, 1500);
    }
    if (register_data?.data?.status === false) {
      const notify = () =>
        toast.error(register_data?.data?.message, { autoClose: 1500 });
      notify();
      setTimeout(() => {
        register_data.data = {};
        register_data.loading = true;
      }, 1500);
    }
  }, [register_data, navigate, register]);
  return (
    <>
      <Title props={t("register")} />
      <ToastContainer />
      <div className="bm-mdl-header">
        <Link to="/login" className="text-white">
          <HiOutlineArrowSmLeft className="fs-2 me-2" />
        </Link>
        <h4 className="m-0 d-inline align-bottom  fw-bold">{t("register")}</h4>
      </div>
      <div className="bm-mdl-center bm-full-height pb-6">
        <div>
          <div className="bm-content-listing text-black">
            <div className="card">
              <div className="card-body">
                <form>
                  <div className="form-group">
                    <label className="form-label">
                      {t("user_name")} <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      name="user_name"
                      onChange={handlechange}
                    />
                    <label
                      htmlFor="user_name"
                      className="text-danger"
                      style={{
                        marginTop: "5px",
                        display: state1.formErrors.user_name ? "block" : "none",
                      }}
                    >
                      {state1.formErrors.user_name}
                    </label>
                  </div>
                  <div className="form-group">
                    <label className="form-label">
                      {t("country_code")} <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      name="country_code"
                      onChange={handlechange}
                      defaultValue=""
                    >
                      <option value="" disabled className="text-secondary">
                        {t("select")}
                      </option>
                      {countryData.map((e, index) => {
                        return (
                          <option
                            key={index}
                            value={e.p_code}
                            className="text-secondary"
                          >{`${e.p_code} (${e.country_name})`}</option>
                        );
                      })}
                    </select>
                    <label
                      htmlFor="country_code"
                      className="text-danger"
                      style={{
                        marginTop: "5px",
                        display: state1.formErrors.country_code
                          ? "block"
                          : "none",
                      }}
                    >
                      {state1.formErrors.country_code}
                    </label>
                  </div>
                  <div className="form-group">
                    <label className="form-label">
                      {t("mobile_no")} <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      name="mobile_no"
                      onChange={handlechange}
                    />
                    <label
                      htmlFor="mobile_no"
                      className="text-danger"
                      style={{
                        marginTop: "5px",
                        display: state1.formErrors.mobile_no ? "block" : "none",
                      }}
                    >
                      {state1.formErrors.mobile_no}
                    </label>
                  </div>
                  <div className="form-group">
                    <label className="form-label">
                      {t("email")} <span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      name="email_id"
                      onChange={handlechange}
                    />
                    <label
                      htmlFor="email_id"
                      className="text-danger"
                      style={{
                        marginTop: "5px",
                        display: state1.formErrors.email_id ? "block" : "none",
                      }}
                    >
                      {state1.formErrors.email_id}
                    </label>
                  </div>
                  <div className="form-group">
                    <label className="form-label">{t("referral_code")}</label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      name="promo_code"
                      onChange={handlechange}
                    />
                  </div>
                  <div className="form-group">
                    <label className="form-label">
                      {t("password")} <span className="text-danger">*</span>
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="exampleInputPassword1"
                      name="password"
                      onChange={handlechange}
                    />
                    <label
                      htmlFor="password"
                      className="text-danger"
                      style={{
                        marginTop: "5px",
                        display: state1.formErrors.password ? "block" : "none",
                      }}
                    >
                      {state1.formErrors.password}
                    </label>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="exampleInputPassword1"
                      className="form-label"
                    >
                      {t("c_password")} <span className="text-danger">*</span>
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="exampleInputPassword1"
                      name="cpassword"
                      onChange={handlechange}
                    />
                    <label
                      htmlFor="cpassword"
                      className="text-danger"
                      style={{
                        marginTop: "5px",
                        display: state1.formErrors.cpassword ? "block" : "none",
                      }}
                    >
                      {state1.formErrors.cpassword}
                    </label>
                  </div>
                  <div id="recaptcha-container"></div>
                  {loading ? (
                    <button
                      type="button"
                      className="btn btn-submit btn-block btn-lg btn-lightpink mb-3 w-100"
                      disabled
                    >
                      {t("loading")}
                    </button>
                  ) : (
                    <button
                      type="button"
                      disabled={disabledButton}
                      className="btn btn-submit btn-block btn-lg btn-lightpink mb-3 w-100"
                      onClick={postData}
                    >
                      {t("submit")}
                    </button>
                  )}

                  <div className="form-group text-center">
                    {t("you_have_account")}{" "}
                    <Link
                      to="/login"
                      className="text-primary text-decoration-none"
                    >
                      {t("sign_in")}
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {openModel && (
            <RegisterOtp
              openModel={openModel}
              setOpenModel={setOpenModel}
              sentOtp={sentOtp}
              handleOtpInputChange={handleOtpInputChange}
              otpErr={otpErr}
              otpErrMess={otpErrMess}
              loading={loading}
              onOTPVerify={onOTPVerify}
              SubmitOtp={SubmitOtp}
            />
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  res: state.AndroidVersion_Reducer,
  register_data: state.RegisterationReducer,
  country_data: state.GetCountryDataReducer,
  check_member_data: state.Check_member_data_Reducer,
});

export default connect(mapStateToProps)(Register);
